.cmp-carousel {
  .card-teaser {
    @media #{$mq-123} {
      padding-bottom: spacing(22);
    }
  }
}

.cmp-carousel__content {
}

.cmp-carousel__item {
}

.cmp-carousel__actions {
  position: absolute;
  right: spacing(14);
  bottom: spacing(10);
  display: flex;
  gap: spacing(10);
  @media #{$mq-123} {
    right: 0;
    bottom: spacing(6);
  }

  &.grey-theme,
  &.white-theme {
    .cmp-carousel__action {
      background-color: var(--primary-frk-vibrant-red);
    }
  }

  &.vibrant-red-theme {
    .cmp-carousel__action {
      background-color: var(--primary-frk-white);
    }
  }
}

.cmp-carousel__action {
  width: spacing(14);
  height: spacing(14);
  padding: spacing(2);
  border: none;
  background-color: var(--primary-frk-black);
  @media #{$mq-123} {
    width: spacing(10);
    height: spacing(10);
    padding: spacing(1);
  }

  &:hover,
  &:focus {
    cursor: pointer;
  }
}

.cmp-carousel__action--disabled {
}

.cmp-carousel__action--previous {
  mask: url('/etc.clientlibs/franke-aem/clientlibs/clientlib-site/resources/images/arrow-left.svg') no-repeat center;
}

.cmp-carousel__action--next {
  mask: url('/etc.clientlibs/franke-aem/clientlibs/clientlib-site/resources/images/arrow-right.svg') no-repeat center;
}

.cmp-carousel__action--pause {
}

.cmp-carousel__action--play {
}

.cmp-carousel__action-icon {
}

.cmp-carousel__action-text {
  display: none;
}

.cmp-carousel__indicators {
  display: none;
}

.cmp-carousel__indicator {
}
