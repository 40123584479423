@mixin full-sizes {
  width: 100%;
  height: 100%;
}

.cmp-responsive-image {
  @include full-sizes;

  &__outer-wrapper,
  &__inner-wrapper {
    @include full-sizes;
  }

  &__image {
    height: 100%;
    object-fit: cover;
  }
}
