.productwishlisterrorbanner {
  @include with-max-width-center;
  @include with-left-and-right-spacing;
}

.cmp-product-wishlist-errorBanner {
  display: none;
  padding: spacing(3) spacing(4);
  background: var(--notification-warning);
  border-radius: spacing(2);
  gap: spacing(4);
  transition: all 1s ease-in;

  @media #{$mq-345} {
    margin-top: $header-height-desktop + $error-banner-height / 2;
    margin-bottom: -$error-banner-height / 2;
  }
  @media #{$mq-12} {
    margin-top: $header-height-mobile + $error-banner-height / 2;
  }

  &--visible {
    display: flex;
  }

  &__title {
    @include paragraph-base-bold;
  }

  &__description {
    @include paragraph-s;
  }

  &__icon {
    display: flex;
    align-self: center;
    font-size: spacing(8);
  }

  &__close {
    border: none;
    margin-left: auto;
    background: none;
    font-size: spacing(6);

    &:hover,
    &:focus {
      cursor: pointer;
    }
  }
}
