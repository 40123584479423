.cmp-text-emotional {
  @include with-max-width-center;
  background-color: var(--text-emotional-background-color);

  @media #{$mq-1} {
    margin: 0 calc(var(--page-container-side-margin-s) * -1);
  }
  @media #{$mq-2} {
    margin: 0 calc(var(--page-container-side-margin-m) * -1);
  }
  @media #{$mq-3} {
    margin: 0 calc(var(--page-container-side-margin-l) * -1);
  }
  @media #{$mq-4} {
    margin: 0 calc(var(--page-container-side-margin-xl) * -1);
  }
  @media #{$mq-5} {
    margin: 0 calc(var(--page-container-side-margin-x-large) * -1);
  }

  @media #{$mq-12} {
    padding: spacing(20) 0;
  }

  @media #{$mq-3} {
    padding: spacing(32) 0;
  }

  @media #{$mq-45} {
    padding: spacing(40) 0;
  }

  &__title {
    margin-bottom: spacing(4);
    color: var(--text-emotional-title-color);
    @include display-l-expanded-bold;

    @media #{$mq-1} {
      width: grid-spacing(1, 12, 11);
      margin-left: grid-spacing(1, 0, 0, 1);
    }
    @media #{$mq-2} {
      width: grid-spacing(2, 12, 11);
      margin-left: grid-spacing(2, 0, 0, 1);
    }

    @media #{$mq-3} {
      width: grid-spacing(3, 9, 8);
      margin-left: grid-spacing(3, 1, 1, 1);
    }

    @media #{$mq-4} {
      width: grid-spacing(4, 8, 7);
      margin-left: grid-spacing(4, 1, 1, 1);
    }

    @media #{$mq-5} {
      width: grid-spacing(5, 8, 7);
      margin-left: grid-spacing(5, 1, 1, 1);
    }
  }

  &__description {
    color: var(--text-emotional-description-color);

    @media #{$mq-123} {
      @include paragraph-lead-m;
    }

    @media #{$mq-45} {
      @include paragraph-lead-xl;
    }

    @media #{$mq-1} {
      width: grid-spacing(1, 10, 9);
      margin-left: grid-spacing(1, 2, 2, 1);
    }

    @media #{$mq-2} {
      width: grid-spacing(2, 10, 9);
      margin-left: grid-spacing(2, 2, 2, 1);
    }

    @media #{$mq-3} {
      width: grid-spacing(3, 6, 5);
      margin-left: grid-spacing(3, 4, 4, 1);
    }

    @media #{$mq-4} {
      width: grid-spacing(4, 6, 5);
      margin-left: grid-spacing(4, 3, 3, 1);
    }

    @media #{$mq-5} {
      width: grid-spacing(5, 6, 5);
      margin-left: grid-spacing(5, 3, 3, 1);
    }
  }
}
