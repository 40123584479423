.cmp-label {
  @include paragraph-s-bold;
  display: flex;
  align-items: center;
  padding: spacing(1) spacing(2);
  border: solid 1px var(--primary-frk-black);
  background: none;
  border-radius: 1rem;
  color: var(--primary-frk-black);
  cursor: pointer;
  white-space: nowrap;

  &--is-active {
    border-color: var(--partner-finder-premium-accent-color);
    background-color: var(--partner-finder-premium-accent-color);
    color: var(--partner-finder-premium-accent-text-color);
  }

  &:hover {
    @media #{$mq-345} {
      background-color: var(--partner-finder-category-hover-background-color);
      color: var(--primary-frk-black);
    }
  }

  &:focus {
    // keep it empty or lint will complain
    // no styles are needed here as there's an outline to show what's focused
    // when navigating via keyboard.
  }
}
