.cmp-warning-tooltip {
  display: flex;
  align-items: center;
  padding: spacing(3) spacing(4);
  background-color: var(--notification-warning);
  border-radius: spacing(2);
  font-size: spacing(8);
  @media #{$mq-12} {
    position: relative;
    top: inherit;
    right: inherit;
    margin: spacing(2) spacing(8);
    margin-top: spacing(11);
  }

  &__title {
    @include paragraph-base-bold;
    margin-left: spacing(4);
  }

  &__description {
    @include paragraph-s;
    margin-left: spacing(4);
  }
  @media #{$mq-345} {
    &::before {
      position: absolute;
      top: spacing(-4);
      right: 0;
      left: 0;
      width: fit-content;
      border-right: spacing(4) solid transparent;
      border-bottom: spacing(4) solid var(--notification-warning);
      border-left: spacing(4) solid transparent;
      margin: auto;
      content: '';
    }
  }
}
