.search-input-field {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: solid 2px var(--globar-search-input-border-color);
  margin-bottom: spacing(7);

  &:focus-within {
    border-bottom-color: var(--globar-search-input-focus-border-color);
  }

  @media #{$mq-123} {
    .cmp-button-link {
      padding: spacing(4) 0;
    }
  }

  @media #{$mq-345} {
    margin-bottom: spacing(24);
  }
}

.search-input-field__input {
  @include paragraph-base-bold;
  flex-grow: 1;
  border: none;

  @media #{$mq-12} {
    padding: spacing(3) 0;
    margin: 0 spacing(3);
  }
  @media #{$mq-345} {
    padding: spacing(4) 0;
    margin: 0 spacing(5);
  }

  &:focus {
    outline: none; /* stylelint-disable-line a11y/no-outline-none */
  }
}
