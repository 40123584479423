.image {
  .cmp-image {
    @include grid-layout;

    &__image {
      width: 100%;
      height: auto;
      grid-column: #{column-index(2)} / #{column-index(11, 1)};
      @media #{$mq-12} {
        grid-column: #{column-index(1)} / #{column-index(12, 1)};
      }
    }

    &__title {
      @include caption;
      display: inline-block;
      margin-top: spacing(3);
      color: var(--primary-frk-black);
      grid-column: #{column-index(2)} / #{column-index(11, 1)};
      text-align: left;
      @media #{$mq-12} {
        grid-column: #{column-index(1)} / #{column-index(12, 1)};
      }
    }
  }
}

.cmp-image--centered {
  .cmp-image {
    @include grid-layout;

    &__image {
      grid-column: #{column-index(4)} / #{column-index(10, 1)};
      @media #{$mq-12} {
        grid-column: #{column-index(1)} / #{column-index(12, 1)};
      }
    }

    &__title {
      grid-column: #{column-index(4)} / #{column-index(10, 1)};
      @media #{$mq-12} {
        grid-column: #{column-index(1)} / #{column-index(12, 1)};
      }
    }
  }
}
