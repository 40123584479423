.wheat-theme {
  // Article Single Page Hero
  --article-single-page-hero-background-color: var(--gradations-frk-wheat-50);
  --article-single-page-hero-text-color: var(--primary-frk-black);
  --article-single-page-hero-border-color: var(--primary-frk-black);
  --basic-hero-background-color: var(--gradations-frk-wheat-50);
  --basic-hero-text-color: var(--primary-frk-black);
  --basic-hero-title-color: var(--primary-frk-black);
  --breadcrumbs-background-color: var(--gradations-frk-wheat-50);
  --breadcrumbs-home-icon-color: var(--primary-frk-black);
  --breadcrumbs-icon-color: var(--transparency-frk-black-50-trans);
  --breadcrumbs-text-color: var(--primary-frk-black);
  --bullet-color: var(--primary-frk-vibrant-red);
  --bullet-color-in-basic-hero: var(--primary-frk-black);
  --button-background-color: var(--primary-frk-vibrant-red);
  --button-border-color: transparent;
  --button-font-color: var(--primary-frk-white);
  --button-on-hover-focus-background-color: var(--gradations-frk-vibrant-red-20);
  --button-on-hover-focus-border-color: transparent;
  --button-on-hover-focus-font-color: var(--primary-frk-white);
  --card-teaser-background-color: var(--gradations-frk-wheat-50);
  --card-teaser-button-background-color: var(--primary-frk-vibrant-red);
  --card-teaser-button-border-color: var(--primary-frk-vibrant-red);
  --card-teaser-button-font-color: var(--primary-frk-white);
  --card-teaser-button-on-hover-focus-background-color: var(--gradations-frk-vibrant-red-20);
  --card-teaser-button-on-hover-focus-border-color: var(--gradations-frk-vibrant-red-20);
  --card-teaser-button-on-hover-focus-font-color: var(--primary-frk-white);
  --card-teaser-description-font-color: var(--primary-frk-black);
  --card-teaser-pretitle-font-color: var(--primary-frk-black);
  --card-teaser-title-font-color: var(--primary-frk-black);
  --header-background-color: var(--gradations-frk-wheat-50);
  --header-font-color: var(--primary-frk-black);
  --header-divider-color: var(--transparency-frk-black-50-trans);

  // hotspots
  --hotspots-slider-button-background: var(--primary-frk-vibrant-red);
  --hotspots-slider-button-font-color: var(--primary-frk-white);
  --hotspots-slider-text-background: var(--gradations-frk-wheat-75);
  --hotspots-slider-text-color: var(--primary-frk-black);
  --hotspots-slider-thumbnail-selected-outline: var(--gradations-frk-wheat-75);
  --link-font-color: var(--primary-frk-black);

  // Hero
  --hero-gradient-desktop: linear-gradient(90deg, rgb(255 255 255 / 25%) 0%, rgb(255 255 255 / 0%) 100%);
  --hero-gradient-mobile: linear-gradient(0deg, rgb(255 255 255 / 25%) 0%, rgb(255 255 255 / 0%) 100%);

  // Overview page hero
  --overview-page-hero-background-color: var(--gradations-frk-wheat-50);
  --overview-page-hero-text-color: var(--primary-frk-black);
  --overview-page-hero-button-background-color: var(--primary-frk-white);
  --overview-page-hero-button-border-color: var(--primary-frk-white);
  --overview-page-hero-button-text-color: var(--primary-frk-black);
  --overview-page-hero-button-hover-background-color: var(--grayscale-frk-black-5);
  --overview-page-hero-button-hover-border-color: var(--grayscale-frk-black-5);
  --overview-page-hero-button-hover-text-color: var(--primary-frk-black);
  --overview-page-feature-articles-background-color: var(--grayscale-frk-black-5);
  --overview-page-feature-articles-link-color: var(--primary-frk-vibrant-red);
  --slider-hero-background-color: var(--gradations-frk-wheat-50);
  --slider-hero-button-background-color: var(--primary-frk-vibrant-red);
  --slider-hero-button-border-color: transparent;
  --slider-hero-button-font-color: var(--primary-frk-white);
  --slider-hero-button-hover-background-color: var(--gradations-frk-vibrant-red-20);
  --slider-hero-button-hover-border-color: transparent;
  --slider-hero-button-hover-font-color: var(--primary-frk-white);
  --slider-hero-text-color: var(--primary-frk-black);
  --slider-hero-timer-background-color: var(--primary-frk-white);
  --slider-hero-timer-progress-color: var(--primary-frk-vibrant-red);
  --storytelling-teaser-background: var(--gradations-frk-wheat-50);
  --storytelling-teaser-description-color: var(--primary-frk-black);
  --storytelling-teaser-pre-title-color: var(--primary-frk-black);
  --storytelling-teaser-subtitle-color: var(--primary-frk-black);
  --storytelling-teaser-title-color: var(--primary-frk-black);
  --storytelling-teaser-description-color-invert: var(--primary-frk-black);

  // Spin viewer
  --spin-viewer-spin-button-color: var(--gradations-frk-wheat-75);
  --spin-viewer-spin-description-text-color: var(--primary-frk-black);
  --spin-viewer-spin-description-text-color-active: var(--primary-frk-black);
  --spin-viewer-spin-description-bg-color: var(--gradations-frk-wheat-75);
  --teaser-background-color: var(--gradations-frk-wheat-50);
  --teaser-text-color: var(--primary-frk-black);

  // Teaser slider
  --teaser-slider-background-color: var(--gradations-frk-wheat-50);
  --teaser-slider-text-color: var(--primary-frk-black);
  --teaser-slider-link-color: var(--primary-frk-black);
  --teaser-slider-link-hover-color: var(--primary-frk-black);
  --teaser-slider-border-color: var(--primary-frk-black);
  --teaser-slider-featured-articles-link-color: var(--primary-frk-vibrant-red);

  // Anchor navigation
  --anchor-navigation-border-color: var(--gradations-frk-wheat-50);
  --anchor-navigation-active-color: var(--secondary-frk-wheat);

  // Entry Teasers
  --entry-teaser-background-color: var(--gradations-frk-wheat-50);
  --entry-teaser-text-color: var(--primary-frk-black);
  --entry-teaser-active-color: var(--primary-frk-vibrant-red);
  --entry-teaser-button-background-color: var(--primary-frk-vibrant-red);
  --entry-teaser-button-text-color: var(--primary-frk-white);
  --entry-teaser-button-hover-background-color: var(--gradations-frk-vibrant-red-20);
}
