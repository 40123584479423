.cmp-product-functionalities {
  margin-bottom: spacing(30);

  @media #{$mq-12} {
    margin-bottom: spacing(20);
  }

  &-title {
    margin-bottom: spacing(20);

    @include heading-xxl-extended-bold;

    @media #{$mq-5} {
      margin-left: grid-spacing(5, 1, 1);
    }

    @media #{$mq-4} {
      margin-left: grid-spacing(4, 1, 1);
    }

    @media #{$mq-3} {
      margin-left: grid-spacing(3, 1, 0);
    }

    @media #{$mq-2} {
      margin-left: grid-spacing(2, 0, 0);
    }

    @media #{$mq-1} {
      margin-left: grid-spacing(1, 0, 0);
    }

    @media #{$mq-12} {
      margin-bottom: spacing(10);
      @include display-l-expanded-bold;
    }
  }

  .cmp-product-functionalities__cta {
    display: block;
    margin-top: spacing(14);
    text-align: center;

    &-button {
      &--more,
      &--less {
        cursor: pointer;
        @include button(
          var(--product-functionalities-button-background-color),
          var(--product-functionalities-button-text-color),
          var(--product-functionalities-button-border-color),
          var(--product-functionalities-button-hover-background-color),
          var(--product-functionalities-button-hover-text-color),
          var(--product-functionalities-button-hover-border-color)
        );
      }

      &--more {
        display: none;
      }
    }
  }

  &--hidden {
    .cmp-icon-grid__item:nth-child(6) ~ .cmp-icon-grid__item {
      display: none;
    }

    @media #{$mq-12} {
      .cmp-icon-grid__item:nth-child(3) ~ .cmp-icon-grid__item {
        display: none;
      }
    }

    .cmp-product-functionalities__cta-button {
      &--more {
        display: inline-block;

        &.cmp-product-functionalities__cta-button--hide-on-mobile {
          display: none;
        }

        &.cmp-product-functionalities__cta-button--hide-on-desktop {
          @media #{$mq-345} {
            display: none;
          }
        }
      }

      &--less {
        display: none;
      }
    }
  }
}
