.cmp-error {
  display: flex;
  width: fit-content;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &__error-warning {
    color: var(--primary-frk-vibrant-red);
    font-size: 30px;
  }
}
