.cmp-image-tabs {
  display: block;
  margin: 0 auto;
  @media #{$mq-5} {
    width: grid-spacing(5, 12, 11);
  }
  @media #{$mq-4} {
    width: grid-spacing(4, 12, 11);
  }
  @media #{$mq-3} {
    width: grid-spacing(3, 12, 11);
  }
  @media #{$mq-2} {
    width: grid-spacing(2, 12, 11);
  }
  @media #{$mq-1} {
    width: grid-spacing(1, 12, 11);
  }

  &__intro {
    position: relative;
    overflow: hidden;
    margin-bottom: spacing(8);

    .cmp-image-tabs__title {
      position: absolute;
      z-index: 1;
      top: spacing(12);
      left: spacing(12);
      color: var(--primary-frk-white);
      @include image-tabs-title;
    }

    .cmp-image-tabs__image {
      aspect-ratio: 15 / 6;
    }
    @media #{$mq-5} {
      width: 100%;
    }
    @media #{$mq-123}, #{$mq-4} {
      @include remove-side-margins;
    }
  }

  &__tabs {
    display: flex;
    cursor: pointer;
    @media #{$mq-345} {
      flex-wrap: wrap;
    }
    @media #{$mq-12} {
      overflow-x: auto;
      scrollbar-width: none;
    }
    @media #{$mq-5} {
      gap: grid-spacing(5, 0, 1);
    }
    @media #{$mq-4} {
      gap: grid-spacing(4, 0, 1);
    }
    @media #{$mq-3} {
      gap: grid-spacing(3, 0, 1);
    }
    @media #{$mq-2} {
      margin-left: calc(var(--page-container-side-margin-m) * -1);
      gap: grid-spacing(2, 0, 1);
    }
    @media #{$mq-1} {
      margin-right: calc(var(--page-container-side-margin-s) * -1);
      gap: grid-spacing(1, 0, 1);
    }
  }

  &__tab {
    @media #{$mq-5} {
      width: grid-spacing(5, 2, 1);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 2, 1);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 2, 1);
    }
    @media #{$mq-2} {
      width: grid-spacing(2, 3, 2);
    }
    @media #{$mq-1} {
      width: 100%;
      min-width: grid-spacing(1, 4, 3);
    }
    position: relative;
    padding-bottom: spacing(4);
    outline: 2px solid transparent;
    transition: all 0.3s ease-in-out;

    &--active {
      border-bottom: 6px solid var(--primary-frk-vibrant-red);

      @media #{$mq-345} {
        &:hover,
        &:focus {
          border-color: var(--secondary-frk-champagne);
        }
      }
    }

    @media #{$mq-345} {
      &:hover,
      &:focus {
        outline-color: var(--secondary-frk-champagne);

        .cmp-image-tabs__tab-title-wrapper {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  &__tab-title-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: spacing(1) spacing(2);
    background: var(--secondary-frk-champagne);
    opacity: 0;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    @media #{$mq-12} {
      display: none;
    }
  }

  &__tab-title {
    @include caption;
    display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    text-overflow: ellipsis;
  }

  &__tab-thumbnail {
    width: 100%;
    padding: 0 spacing(1);
    aspect-ratio: 4/3;
    object-fit: cover;
  }

  &__tab-panels {
    margin-top: spacing(10);
  }

  &__tab-panel {
    display: none;

    &--active {
      display: flex;
      align-items: center;
      @media #{$mq-12} {
        flex-flow: column;
      }
    }
  }

  &__tab-panel-image {
    @media #{$mq-5} {
      width: grid-spacing(5, 6, 5);
      margin-right: grid-spacing(5, 1, 2);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 6, 5);
      margin-right: grid-spacing(4, 1, 2);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 5, 4);
      margin-right: grid-spacing(3, 1, 2);
    }
    @media #{$mq-12} {
      width: 100%;
    }

    .cmp-responsive-image__image {
      object-fit: contain;
    }
  }

  &__tab-panel-content {
    @media #{$mq-5} {
      width: grid-spacing(5, 4, 3);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 4, 3);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 5, 4);
    }
    @media #{$mq-12} {
      width: 100%;
    }
  }

  &__tab-panel-title {
    @include heading-l-extended-bold;
    margin-bottom: spacing(1);
    @media #{$mq-12} {
      margin-top: spacing(4);
    }
  }

  &__tab-panel-description {
    @include paragraph-lead-m;
    margin-bottom: spacing(4);
    @media #{$mq-123} {
      @include paragraph-base;
    }
  }

  &__tab-panel-link {
    @include button;
  }

  &__scroll-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    @media #{$mq-12} {
      overflow-x: scroll;
      scrollbar-width: none;
    }
  }

  &__image-wrapper {
    display: flex;
    white-space: nowrap;
    @media #{$mq-12} {
      width: 200vw;
    }

    .cmp-responsive-image__image {
      display: block;
      width: 100vw;
      height: auto;
      @media #{$mq-12} {
        width: 200vw;
      }
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(rgb(0 0 0 / 61%), rgb(0 0 0 / 0%));
      content: '';
      @media #{$mq-12} {
        width: 200vw;
      }
    }
  }

  &__slider-wrapper {
    display: none;
    @media #{$mq-12} {
      position: absolute;
      bottom: 0;
      display: block;
      width: 100%;
      height: spacing(12);
      background: linear-gradient(transparent, rgb(0 0 0 / 61%));
    }
  }

  &__slider {
    display: none;
    @media #{$mq-12} {
      position: absolute;
      bottom: spacing(3);
      left: 50%;
      display: block;
      width: 80%;
      height: spacing(2);
      background: rgb(255 255 255 / 30%);
      border-radius: spacing(1);
      cursor: pointer;
      touch-action: none;
      transform: translateX(-50%);
    }
  }

  &__slider-thumb {
    @media #{$mq-12} {
      position: absolute;
      top: 50%;
      left: 0;
      width: 20%;
      height: 100%;
      background: white;
      border-radius: spacing(1);
      transform: translateY(-50%);
    }
  }
}
