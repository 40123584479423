.cmp-product-explorer-layout {
  @media #{$mq-1} {
    padding-left: grid-spacing(1, 0, 0, 1);
  }

  @media #{$mq-2} {
    padding-left: grid-spacing(2, 0, 0, 1);
  }

  @media #{$mq-3} {
    padding-left: grid-spacing(3, 0, 0, 1);
  }

  @media #{$mq-4} {
    padding-left: grid-spacing(4, 0, 0, 1);
  }

  @media #{$mq-5} {
    padding-left: grid-spacing(5, 0, 0, 1);
  }
  @include remove-side-margins;
  @include with-max-width-center;
  background: linear-gradient(
    180deg,
    rgb(242 242 242 / 0%) 0%,
    #f2f2f2 11.46%,
    #f2f2f2 82.29%,
    rgb(242 242 242 / 0%) 100%
  );

  &__title {
    @include display-xl-expanded-bold;
    padding-top: spacing(40);
    padding-bottom: spacing(4);

    @media #{$mq-12} {
      padding-top: spacing(20);
      @include display-l-expanded-bold;
    }
  }

  &__lead-text {
    @include paragraph-lead-l;

    @media #{$mq-12} {
      @include paragraph-s;
    }
  }

  &__product-count {
    color: var(--primary-frk-vibrant-red);
  }

  &__header {
    @media #{$mq-345} {
      padding-top: spacing(20);
    }
    @media #{$mq-12} {
      padding-top: spacing(10);
    }
  }
}

.cmp-product-explorer-layout__title,
.cmp-product-explorer-layout__lead-text,
.cmp-product-explorer-layout__header {
  @media #{$mq-3} {
    padding-right: grid-spacing(3, 1, 1);
    padding-left: grid-spacing(3, 1, 1);
  }

  @media #{$mq-4} {
    padding-right: grid-spacing(4, 1, 1);
    padding-left: grid-spacing(4, 1, 1);
  }

  @media #{$mq-5} {
    padding-right: grid-spacing(5, 1, 1);
    padding-left: grid-spacing(5, 1, 1);
  }
}

.cmp-product-explorer-layout__header .cmp-product-explorer-layout__reset-all {
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  margin-top: spacing(2);
  margin-bottom: spacing(8);
  background: none;
  box-shadow: none;
  color: var(--product-explorer-layout-reset-all-text-color);
  cursor: pointer;
  @include paragraph-s-bold;

  [class^='ri-'] {
    margin-right: spacing(2);
  }
}

.cmp-product-explorer-layout__total-count {
  @include paragraph-s-bold;
  margin-bottom: spacing(10);
  color: var(--product-explorer-layout-total-count-text-color);

  &-label {
    margin-left: spacing(2);
    color: var(--product-explorer-layout-total-count-label-text-color);
  }
  @media #{$mq-3} {
    padding-left: grid-spacing(3, 1, 1);
  }

  @media #{$mq-4} {
    padding-left: grid-spacing(4, 1, 1);
  }

  @media #{$mq-5} {
    padding-left: grid-spacing(5, 1, 1);
  }
}
