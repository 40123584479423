.cmp-share-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: spacing(5) 0;
  border-top: solid 1px var(--gradations-frk-rosemary-50);
  border-bottom: solid 1px var(--gradations-frk-rosemary-50);
  @include paragraph-base-bold;

  @media #{$mq-1} {
    padding: spacing(4) 0;
  }
  @media #{$mq-3} {
    margin-right: grid-spacing(3, 2, 2);
    margin-left: grid-spacing(3, 3, 3);
  }
  @media #{$mq-4} {
    margin-right: grid-spacing(4, 2, 2);
    margin-left: grid-spacing(4, 3, 3);
  }
  @media #{$mq-5} {
    margin-right: grid-spacing(5, 2, 2);
    margin-left: grid-spacing(5, 3, 3);
  }

  &__open-icon-list-button {
    display: none;

    @media #{$mq-1} {
      display: flex;
    }
  }

  &__icons-section {
    display: flex;
    align-items: center;
    gap: spacing(2.5);

    [class^='ri-'] {
      @include paragraph-lead-m;
      color: var(--primary-frk-vibrant-red);
    }
  }

  &__open-icon-list-icon-button,
  &__open-icon-list-button {
    align-items: center;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
  }

  &__icon-list {
    display: flex;
    gap: spacing(6);

    @media #{$mq-1} {
      gap: spacing(5);
    }
  }

  &__icon-link {
    text-decoration: none;
  }

  &__lead-text {
    overflow: hidden;
    white-space: nowrap;
  }
}
