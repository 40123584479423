.cmp-loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;

  &__svg-wrapper {
    display: inline-block;
    width: spacing(14);
    height: spacing(14);
    animation: loading-indicator-keyframes-circular-rotate 1.5s linear infinite;
    color: var(--primary-frk-black);
  }

  &__svg {
    display: block;
    animation: loading-animation 1.5s infinite;
  }

  &__circle {
    animation: loading-indicator-keyframes-circular-dash 1.5s ease-in-out infinite;
    stroke: currentcolor;
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 0;
  }
}

@keyframes loading-indicator-keyframes-circular-rotate {
  0% {
    transform-origin: 50% 50%;
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading-indicator-keyframes-circular-dash {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
}

@keyframes loading-animation {
  50% {
    color: var(--primary-frk-black);
  }

  100% {
    color: var(--primary-frk-vibrant-red);
  }
}
