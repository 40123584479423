.cmp-text-container {
  ol {
    position: relative;
    margin-left: spacing(7);
    counter-reset: ordered-list;
    list-style-position: outside;

    li {
      @include paragraph-lead-m;

      @media #{$mq-1} {
        @include paragraph-base;
      }

      &::before {
        @include paragraph-lead-m-bold;
        position: absolute;
        right: 100%;
        width: spacing(4);
        margin-right: spacing(3);
        color: var(--ordered-list-color);
        content: counter(ordered-list) '.';
        counter-increment: ordered-list;

        @media #{$mq-1} {
          @include paragraph-base-bold;
        }
      }
    }
  }
}
