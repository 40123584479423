.cmp-emotional-teaser {
  @include grid-layout;

  @include remove-side-margins;

  &__first-image {
    aspect-ratio: 16/9;
    grid-column: #{column-index(0)} / #{column-index(13)};
    object-fit: cover;

    @media #{$mq-12} {
      grid-row: 2 / 4;
    }

    @media #{$mq-345} {
      grid-row: 1 / 3;
    }
  }

  &__title-box {
    grid-row: 1 / 2;

    @media #{$mq-123} {
      padding: spacing(8) 0;
      grid-column: #{column-index(1)} / #{column-index(12, 1)};
    }

    @media #{$mq-345} {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media #{$mq-45} {
      grid-column: #{column-index(2)} / #{column-index(11, 1)};
    }
  }

  &__pre-title {
    @include paragraph-base-bold;
    color: var(--emotional-teaser-pre-title-color);
    @media #{$mq-123} {
      margin-bottom: spacing(1);
    }
    @media #{$mq-45} {
      margin-bottom: spacing(4);
    }
  }

  &__title {
    @include display-l-expanded-bold;
    color: var(--emotional-teaser-title-color);
    @media #{$mq-123} {
      margin-bottom: spacing(3);
    }
    @media #{$mq-45} {
      margin-bottom: spacing(8);
    }
  }

  &__link {
    @include button(var(--emotional-teaser-cta-background), var(--emotional-teaser-cta-text));
    overflow: hidden;
    width: fit-content;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media #{$mq-1} {
      max-width: 100%;
    }

    @media #{$mq-2} {
      max-width: 100%;
    }
  }

  &__image-box-spacer {
    grid-column: #{column-index(0)} / #{column-index(13)};

    @media #{$mq-12} {
      grid-row: 4 / 5;
    }

    @media #{$mq-345} {
      aspect-ratio: 1440/625;
      grid-row: 1 / 2;
    }
  }

  &__image-box {
    display: flex;
    justify-content: flex-start;
    @media #{$mq-12} {
      grid-column: #{column-index(0)} / #{column-index(8, 1)};
      grid-row: 4 / 5;
    }

    @media #{$mq-345} {
      grid-column: #{column-index(0)} / #{column-index(6, 1, 1)};
      grid-row: 2 / 4;
    }
  }

  &__second-image {
    width: 50%;
    aspect-ratio: 1;
    object-fit: cover;
  }

  &__color-block-image {
    width: 50%;
    aspect-ratio: 1;
    background-color: var(--emotional-teaser-image-background);
    object-fit: cover;
  }

  &--mirrored {
    .cmp-emotional-teaser__image-box {
      flex-direction: row;
      justify-content: flex-end;
      @media #{$mq-12} {
        grid-column: #{column-index(5)} / #{column-index(13)};
      }

      @media #{$mq-345} {
        grid-column: #{column-index(6, 1, 1)} / #{column-index(13)};
      }
    }
  }

  &--white {
    @media #{$mq-345} {
      --emotional-teaser-title-color: var(--primary-frk-white);
      --emotional-teaser-pre-title-color: var(--primary-frk-white);
    }
  }
}
