.cmp-thank-you-page-cta-buttons {
  display: flex;
  flex-direction: column;

  @media #{$mq-12} {
    width: 100%;
    margin-bottom: spacing(26);
  }

  @media #{$mq-345} {
    width: fit-content;
    margin-bottom: spacing(77);
  }

  @media #{$mq-4} {
    margin-left: grid-spacing(4, 1, 1);
  }
  @media #{$mq-5} {
    margin-left: grid-spacing(5, 1, 1);
  }

  &__overlay {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    width: 100vw;
    height: 100vh;
    align-content: center;
    justify-content: center;
    background-color: var(--transparency-frk-black-50-trans);
  }

  &__download-button {
    @include heading-base-extended-bold;
    padding: spacing(4) spacing(7);
    margin-bottom: spacing(10);
    cursor: pointer;
    text-align: center;
    @include button(
      var(--form-button-background-color),
      var(--form-button-text-color),
      var(--form-button-border-color),
      var(--form-button-background-hover-color),
      var(--form-button-text-hover-color),
      var(--form-button-border-hover-color)
    );
  }

  &__send-to-email-button {
    @include button(
      var(--primary-frk-white),
      var(--primary-frk-black),
      var(--primary-frk-black),
      var(--primary-frk-black),
      var(--primary-frk-white),
      var(--primary-frk-white)
    );
    margin-bottom: spacing(10);
    margin-left: spacing(6);
    cursor: pointer;
    @media #{$mq-1} {
      margin-left: 0;
    }
  }

  &__back-button {
    display: flex;
    @include heading-base-extra-bold;
    @include link(var(--primary-frk-black), true, var(--transparency-frk-black-50-trans), false, true);
  }
}
