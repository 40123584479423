.card-teaser {
  padding: spacing(41) 0;
  background-color: var(--card-teaser-background-color);
  @include remove-side-margins;
  @media #{$mq-12} {
    padding: spacing(8) 0;
  }
}

.cmp-card-teaser {
  @include grid-layout;
  grid-template-rows: auto auto;

  &__media {
    grid-column: #{column-index(2)} / #{column-index(5, 1)};
    grid-row: 1 / 4;
  }

  &__video {
    position: relative;
    aspect-ratio: 1/1;
  }

  &__video-player {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__video-cover,
  &__video-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__video-cover {
    z-index: 1;
  }

  &__video-image,
  &__video-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__video-button {
    z-index: 2;
    width: spacing(15);
    height: spacing(15);
    border: none;
    background-color: var(--video-button-color);
    box-shadow: none;
    cursor: pointer;
  }

  &__content {
    grid-column: #{column-index(7)} / #{column-index(12, 1)};
    grid-row: 2 / 3;
    word-wrap: break-word;
  }

  &__pretitle {
    @include paragraph-base-bold;
    margin-bottom: spacing(3);
    color: var(--card-teaser-pretitle-font-color);
  }

  &__title {
    @include display-l-expanded-bold;
    margin-bottom: spacing(3);
    color: var(--card-teaser-title-font-color);
    @media #{$mq-12} {
      margin-bottom: spacing(2);
    }
  }

  &__subtitle {
    @include heading-xxl-extended-bold;
    color: var(--card-teaser-title-font-color);
    @media #{$mq-12} {
      @include heading-base-extended-bold;
    }
  }

  &__description {
    @include paragraph-lead-m;
    margin: spacing(5) 0;
    color: var(--card-teaser-description-font-color);
    @media #{$mq-12} {
      margin: spacing(3) 0;
      @include paragraph-base;
    }
  }

  .cmp-image {
    &__image {
      aspect-ratio: 1 / 1;
      object-fit: cover;
    }
  }

  @media #{$mq-12} {
    &__media {
      margin-bottom: spacing(4);
      grid-column: #{column-index(1)} / #{column-index(12, 1)};
      grid-row: 1 / 2;
    }

    &__content {
      grid-column: #{column-index(1)} / #{column-index(12, 1)};
      grid-row: 2 / 4;
    }
  }
}

.cmp-card-teaser--mirrored {
  .cmp-card-teaser__media {
    grid-column: #{column-index(8)} / #{column-index(11, 1)};
  }

  .cmp-card-teaser__content {
    grid-column: #{column-index(2)} / #{column-index(7, 1)};
  }

  @media #{$mq-12} {
    .cmp-card-teaser__media {
      grid-column: #{column-index(1)} / #{column-index(12, 1)};
    }

    .cmp-card-teaser__content {
      grid-column: #{column-index(1)} / #{column-index(12, 1)};
    }
  }
}

.cmp-card-teaser__action-link {
  @include button(
    var(--card-teaser-button-background-color),
    var(--card-teaser-button-font-color),
    var(--card-teaser-button-border-color),
    var(--card-teaser-button-on-hover-focus-background-color),
    var(--card-teaser-button-on-hover-focus-font-color),
    var(--card-teaser-button-on-hover-focus-border-color)
  );
  @include heading-m-extended-bold;
}
