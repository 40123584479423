.cmp-product-variant-selectors {
  display: flex;
  flex-wrap: wrap;

  @media #{$mq-1} {
    width: grid-spacing(1, 12, 11);
  }
  @media #{$mq-2} {
    width: grid-spacing(2, 12, 11);
  }
  @media #{$mq-12} {
    flex-direction: column;
  }

  &__dropdown {
    @media #{$mq-3} {
      width: grid-spacing(3, 4, 3);
      margin-right: grid-spacing(3, 0, 1);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 3, 2);
      margin-right: grid-spacing(4, 0, 1);
    }
    @media #{$mq-5} {
      width: grid-spacing(5, 3, 2);
      margin-right: grid-spacing(5, 0, 1);
    }

    &:nth-of-type(3n + 3) {
      @media #{$mq-3} {
        margin-right: -1px;
      }
    }

    &:nth-of-type(4n + 4) {
      @media #{$mq-5} {
        margin-right: -1px;
      }
      @media #{$mq-4} {
        margin-right: -1px;
      }
    }
  }

  &__title {
    @include paragraph-s-bold;
    margin-bottom: spacing(2);
  }

  .cmp-multi-select-filter__popover {
    @media #{$mq-1} {
      width: grid-spacing(1, 12, 11);
    }
    @media #{$mq-2} {
      width: grid-spacing(2, 12, 11);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 5, 4);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 3, 2);
    }
    @media #{$mq-5} {
      width: grid-spacing(5, 3, 2);
    }
  }
}
