.cmp-link-list {
  &-container {
    @include grid-layout;
    @include with-max-width-center;
    @include remove-side-margins;

    .cmp-list {
      padding: 0;
      margin: 0;

      &__item {
        border-bottom: 1px solid var(--link-list-item-border-color);

        &-link {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: spacing(4) spacing(3);
          color: var(--link-list-item-link-text-color);
          text-decoration: none;
          transition: all 200ms ease-in-out;

          &:hover,
          &:focus {
            background-color: var(--link-list-item-link-hover-background-color);
            color: var(--link-list-item-link-hover-text-color);
          }

          &::after {
            content: '\EA6C';
            font-family: var(--remixicon-font);
            font-size: spacing(4);
          }
          @media #{$mq-12} {
            padding: spacing(4) 0;
          }
        }

        &-title {
          @include heading-base-extra-bold;
        }

        &:last-of-type {
          border-bottom: transparent;
        }
      }
    }
  }

  &__left-side {
    grid-column: #{column-index(2)} / #{column-index(5, 1)};

    @media #{$mq-12} {
      grid-column: #{column-index(1)} / #{column-index(12, 1)};
    }

    .cmp-link-list-container--full-width & {
      grid-column: #{column-index(3)} / #{column-index(10, 1)};
      @media #{$mq-12} {
        grid-column: #{column-index(1)} / #{column-index(12, 1)};
      }
    }
  }

  &__right-side {
    grid-column: #{column-index(7)} / #{column-index(11, 1)};

    @media #{$mq-12} {
      grid-column: #{column-index(1)} / #{column-index(12, 1)};
    }

    .cmp-link-list-container--full-width & {
      grid-column: #{column-index(3)} / #{column-index(10, 1)};
      @media #{$mq-12} {
        grid-column: #{column-index(1)} / #{column-index(12, 1)};
      }
    }
  }

  &-title {
    @include heading-xxl-extended-bold;
    margin-bottom: spacing(4);
    color: var(--link-list-item-title-color);
    @media #{$mq-12} {
      @include heading-m-extended-bold;
    }
  }

  &-description {
    @include paragraph-base;
    color: var(--link-list-item-description-color);
    @media #{$mq-12} {
      margin-bottom: spacing(6);
    }

    .cmp-link-list-container--full-width & {
      margin-bottom: spacing(8);
    }
  }
}
