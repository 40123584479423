.cmp-navigation {
}

.cmp-navigation__group {
}

.cmp-navigation__item {
}

.cmp-navigation__item--active {
}

// .cmp-navigation__item--level-0 {}
.cmp-navigation__item--level-0:hover > .cmp-navigation__group,
.cmp-navigation__item--level-0:focus > .cmp-navigation__group {
  background: #ececec;
}

.cmp-navigation__item-link {
}
