.cmp-navigation {
  &__section {
    margin-bottom: spacing(8);
    @media #{$mq-12} {
      margin-bottom: spacing(6);
    }
  }

  &__section-title {
    @include pre-title-l;
    margin-bottom: spacing(4);
    color: var(--navigation-section-title-color);
    text-transform: uppercase;

    &:not(:first-of-type) {
      margin-top: spacing(20);

      @media #{$mq-12} {
        margin-top: spacing(14);
        @include pre-title-s;
      }
    }
  }

  &__division-title {
    @include heading-xl-extra-bold;
    display: flex;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    @media #{$mq-12} {
      @include heading-l-extra-bold;
    }

    &--open,
    &:hover,
    &:focus,
    &:active {
      color: var(--navigation-division-title-hover-color);
    }
  }

  &-accordion__icon {
    display: flex;
    align-items: center;
    margin-left: 16px;
    color: var(--navigation-accordion-item-icon-color);
    font-size: 32px;
  }

  &-accordion-list {
    &__item {
      &:not(:first-of-type) {
        margin-top: spacing(8);
      }
    }
  }
}

.cmp-navigation__title {
  @include link(var(--navigation-accordion-title-color), false, var(--navigation-accordion-title-hover-color));
  display: flex;
  align-items: center;

  @media #{$mq-12} {
    width: 100%;
    @include heading-l-extra-bold;
    justify-content: space-between;
  }

  &--is-opened {
    color: var(--navigation-accordion-title-is-open-color);
  }
}

.cmp-accordion-list {
  margin-top: spacing(6);

  &__item {
    @include paragraph-lead-l;
    margin-bottom: spacing(4);
    cursor: pointer;
    transition: all 200ms ease-in-out;
    @include link(var(--navigation-accordion-item-link-color), false);

    @media #{$mq-12} {
      padding-bottom: spacing(5);
      margin-bottom: 0;
      @include paragraph-lead-m;
    }

    &--is-active {
      color: var(--navigation-accordion-item-link-hover-active-color);
    }

    &:hover,
    &:focus,
    &:active {
      color: var(--navigation-accordion-item-link-hover-active-color);
    }
  }
}

.cmp-navigation-accordion-content {
  opacity: 0;
  pointer-events: none;
  transition: max-height 0.3s ease-out 0.1s, opacity 0.2s linear;

  &--open {
    opacity: 1;
    pointer-events: all;
    transition: max-height 0.3s ease-out, opacity 0.2s linear 0.1s;
  }
}
