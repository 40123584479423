@use 'sass:map';

$bullet-square: '\f1a0';
$bullet-check: '\eb7b';

@mixin bullet-list-item($bulletColor: var(--bullet-color), $bulletChar: 'check') {
  @include paragraph-lead-m;
  display: flex;
  align-items: center;
  margin-bottom: spacing(4);

  &::before {
    padding-right: spacing(3);
    color: $bulletColor;
    font-family: var(--remixicon-font);
    line-height: 100%;
    @if $bulletChar == 'square' {
      padding-left: spacing(2);
      content: $bullet-square;
      font-size: spacing(4);
    } @else if $bulletChar == 'check' {
      content: $bullet-check;
      font-size: spacing(6);
    }
  }
}

@mixin bullets($bulletColor: var(--bullet-color), $bulletChar: 'check', $isGeneric: false) {
  padding: 0;

  @if $isGeneric {
    li {
      @include bullet-list-item($bulletColor, $bulletChar);
    }
  } @else {
    .cmp-bullet-list__item {
      @include bullet-list-item($bulletColor, $bulletChar);
    }
  }
}
