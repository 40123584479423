.media-info {
  position: fixed;
  z-index: 9;
  right: 0;
  bottom: 0;
  display: none;
  padding: spacing(5);
  background-color: lime;
  color: black;
  opacity: 0.6;
  pointer-events: none;

  &--small-s {
    @media #{$mq-1} {
      display: block;
    }
  }

  &--small-m {
    @media #{$mq-2} {
      display: block;
    }
  }

  &--medium-l {
    @media #{$mq-3} {
      display: block;
    }
  }

  &--large-xl {
    @media #{$mq-4} {
      display: block;
    }
  }

  &--x-large {
    @media #{$mq-5} {
      display: block;
    }
  }
}
