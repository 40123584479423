.cmp-form-options {
  display: flex;
  flex-direction: column;
  padding: 0;
  border: 0;
  margin-bottom: spacing(8);

  &__legend {
  }

  &__error-message {
    display: none;
  }

  &__field-label {
    display: flex;
    align-items: center;
  }

  &__drop-down-field {
    @include paragraph-base;
    color: var(--form-options-drop-down-text-color);
  }

  &__field {
  }

  &__field--checkbox {
    width: spacing(4);
    height: spacing(4);
  }

  &--checkbox {
    margin: spacing(6) 0 0 0;

    input[type='checkbox'] {
      width: spacing(6);
      height: spacing(6);
      flex-shrink: 0;
      border: $form-border-width solid var(--form-checkbox-border-color);
      margin: 0 spacing(4) 0 0;
      appearance: none;
      border-radius: 0;
      cursor: pointer;
    }

    input[type='checkbox']:checked {
      border: 0;
      background: var(--form-checkbox-checked-background-color);
      text-align: center;
    }

    input[type='checkbox']:checked::before {
      color: var(--form-checkbox-checked-text-color);
      content: '\eb7a';
      font-family: var(--remixicon-font);
      font-size: spacing(5);
      vertical-align: middle;
    }
  }

  &__field--radio {
    width: spacing(4);
    height: spacing(4);
  }

  &--radio {
    margin: spacing(6) 0 0 0;

    input[type='checkbox'] {
      width: spacing(6);
      height: spacing(6);
      flex-shrink: 0;
      border: $form-border-width solid var(--form-radio-border-color);
      margin: 0 spacing(4) 0 0;
      appearance: none;
      border-radius: 50%;
      cursor: pointer;
    }

    input[type='checkbox']:checked {
      border: 0;
      background: var(--form-radio-checked-background-color);
      text-align: center;
    }

    input[type='checkbox']:checked::before {
      color: var(--form-radio-checked-text-color);
      content: '\eb7d';
      font-family: var(--remixicon-font);
      font-size: spacing(6);
      vertical-align: middle;
    }
  }

  &__field--drop-down {
    @include paragraph-base;
    padding: spacing(3);
    border: $form-border-width solid var(--form-dropdown-border-color);
    background-color: var(--form-dropdown-background-color);
    color: var(--form-dropdown-text-color);
    @media #{$mq-12} {
      width: 100%;
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 5, 4);
    }

    @media #{$mq-4} {
      width: grid-spacing(4, 5, 4);
    }

    @media #{$mq-5} {
      width: grid-spacing(5, 5, 4);
    }

    &:focus,
    &:focus-visible {
      border: $form-border-width solid var(--form-dropdown-border-color);
      outline: none;
    }

    &:invalid {
      color: var(--form-options-is-invalid-text-color);
    }

    .cmp-form-options--is-invalid &,
    .cmp-form-options--is-interacted & {
      &:invalid {
        border: $form-border-width solid var(--form-options-is-invalid-border-color);
        color: var(--form-options-is-invalid-text-color);
      }
    }

    option {
      color: var(--form-dropdown-option-text-color);
    }
  }

  &__field-description {
    color: var(--form-text-label-color);
    @media #{$mq-12} {
      @include paragraph-s;
    }

    @media #{$mq-345} {
      @include paragraph-base;
    }

    a {
      @include link(var(--form-description-link-color), false, var(--form-description-hover-link-color));
    }
  }

  &__label {
    @include paragraph-s-bold;
    color: var(--form-text-label-color);
  }

  &__multi-drop-down-field {
    padding: spacing(2);
  }

  &__help-message {
  }

  &--is-invalid {
    color: var(--form-description-invalid-text-color);

    .cmp-form-options__error-message {
      display: block;
      @include paragraph-s;
      padding: spacing(3);
    }

    input[type='checkbox'] {
      border: $form-border-width solid var(--form-options-is-invalid-border-color);
    }
  }
}
