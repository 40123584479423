.cmp-navigation-teaser {
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  height: 100vh;
  pointer-events: none;

  @media #{$mq-4} {
    width: grid-spacing(4, 6, 5, 1);
  }

  @media #{$mq-5} {
    width: grid-spacing(5, 6, 5, 1);
  }

  &__inner {
    position: absolute;
    top: 0;
    right: 0;
    display: grid;
    width: 100%;
    height: 100vh;
    align-items: center;
    padding: spacing(12) 0;
    background-color: var(--navigation-teaser-background-color);
    color: var(--navigation-teaser-text-color);
    grid-template-rows: 1fr spacing(22);
    opacity: 0;
    transform: translateX(calc(100% - spacing(5)));
    transition: all 0.3s ease-in-out;
  }

  &__illustration-label {
    @include display-xxl-expanded-bold;
    margin-right: spacing(-2);
    font-size: 7vh;
    grid-row: 2;
    text-align: right;
  }

  &__image {
    @media #{$mq-4} {
      max-width: grid-spacing(4, 4, 3, 0);
      height: auto;
    }

    @media #{$mq-5} {
      max-width: grid-spacing(5, 3, 2, 0);
      height: auto;
    }
    min-width: spacing(60);
    margin-bottom: spacing(6);
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }

  &__content {
    grid-row: 1;

    @media #{$mq-4} {
      margin-left: grid-spacing(4, 1, 1, 0);
    }

    @media #{$mq-5} {
      margin-left: grid-spacing(5, 1, 1, 0);
    }

    &-text {
      @media #{$mq-4} {
        width: grid-spacing(4, 4, 3, 0);
        margin-left: grid-spacing(4, 1, 1, 0);
      }

      @media #{$mq-5} {
        width: grid-spacing(5, 3, 2, 0);
        margin-left: grid-spacing(5, 1, 1, 0);
      }
    }

    &-description {
      @include heading-m-extra-bold;
      margin-bottom: spacing(3);
    }

    &-link {
      @include heading-base-extra-bold;
      @include link(var(--navigation-teaser-content-link), true, var(--navigation-teaser-content-link));
    }
  }

  &--is-displayed {
    pointer-events: all;

    .cmp-navigation-teaser__inner {
      opacity: 1;
    }
  }

  &--is-opened {
    .cmp-navigation-teaser__inner {
      transform: translateX(0);
    }
  }

  &--content-shown {
    .cmp-navigation-teaser__content {
      opacity: 1;
      transform: translateY(0);
      transition: transform 0.5s ease-out, opacity 0.3s ease-out;
    }

    .cmp-navigation-teaser__illustration-label {
      opacity: 1;
      transform: translateX(0);
      transition: transform 0.5s ease-out, opacity 0.3s ease-out;
    }
  }

  &--content-hidden {
    .cmp-navigation-teaser__content {
      opacity: 0;
      transform: translateY(spacing(10));
      transition: transform 0.3s ease-out 0.2s, opacity 0.3s ease-out 0.2s;
    }

    .cmp-navigation-teaser__illustration-label {
      opacity: 0;
      transform: translateX(30%);
      transition: transform 0.3s ease-out 0.2s, opacity 0.3s ease-out 0.2s;
    }
  }
}
