.cmp-key-figure {
  &__title {
    @include display-l-expanded-bold;
    margin-bottom: spacing(20);
    @media #{$mq-3} {
      margin-right: grid-spacing(3, 1);
      margin-left: grid-spacing(3, 1);
    }
    @media #{$mq-4} {
      margin-right: grid-spacing(4, 1, 1);
      margin-left: grid-spacing(4, 1, 1);
    }
    @media #{$mq-5} {
      margin-right: grid-spacing(5, 1, 1);
      margin-left: grid-spacing(5, 1, 1);
    }
  }

  &__item-number-wrapper {
    @include heading-extra-bold-3xl;
    margin-bottom: spacing(6);
    color: var(--key-figure-number-color);
  }
}
