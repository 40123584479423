.cmp-global-search-results {
  &__products {
    display: flex;
    padding: 0;
    @media #{$mq-345} {
      margin-bottom: spacing(10);
    }
    @media #{$mq-5} {
      gap: grid-spacing(5, 0, 1);
    }
    @media #{$mq-4} {
      gap: grid-spacing(4, 0, 1);
    }
    @media #{$mq-3} {
      gap: grid-spacing(3, 0, 1);
    }
    @media #{$mq-12} {
      flex-direction: column;
    }

    .cmp-product-list-item {
      border: 1px solid var(--grayscale-frk-cool-grey-1);

      &__title {
        border-top: transparent;
        @media #{$mq-12} {
          @include paragraph-lead-m-bold;
        }
      }
      @media #{$mq-12} {
        width: 100%;
        margin-bottom: spacing(6);
      }
    }

    .cmp-product-list-item__colors-list {
      padding: spacing(2) spacing(6);
    }

    .cmp-product-list-item__color {
      width: spacing(6);
      height: spacing(6);
    }
  }
}
