.cmp-hotspot-teaser {
  position: fixed;
  width: spacing(97);
  max-height: 100%;
  padding: 0;
  border: none;
  margin: 0;
  background-color: var(--hotspots-slider-text-background);
  opacity: 0;
  @media #{$mq-12} {
    position: absolute;
  }

  &::backdrop {
    background-color: var(--primary-frk-black);
    opacity: 0.3;
  }

  @media #{$mq-3} {
    width: spacing(72);
  }
  @media #{$mq-12} {
    width: 100%;
    max-width: var(--page-max-width);
  }

  &__close {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    display: flex;
    padding: spacing(2);
    border: none;
    background-color: var(--primary-frk-vibrant-red);
    color: var(--primary-frk-white);
    cursor: pointer;
    font-size: spacing(6);
    outline: none;
  }

  &__media {
    position: relative;
    aspect-ratio: 3 / 2;

    .cmp-video__dam-video {
      object-fit: cover;
    }
  }

  &__description {
    padding: spacing(6);
  }

  &__description-title {
    @include heading-l-extended-bold;
    @media #{$mq-12} {
      @include heading-m-extended-bold;
      color: var(--hotspots-slider-text-color);
    }
  }

  &__description-text {
    @include paragraph-s;
    padding: spacing(6) 0;
    color: var(--hotspots-slider-text-color);
  }

  &__description-link {
    @include heading-base-extra-bold;
    display: flex;
    color: var(--hotspots-slider-text-color);
    cursor: pointer;
    gap: spacing(2);
    text-decoration: none;
  }
}
