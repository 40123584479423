.cmp-interactive-icon-grid {
  &__title {
    margin-bottom: spacing(20);
    margin-left: grid-spacing(5, 1, 1);
    @include display-l-expanded-bold;
    @media #{$mq-4} {
      margin-left: grid-spacing(1, 1, 1);
    }
    @media #{$mq-3} {
      margin-left: grid-spacing(3, 1, 1);
    }

    @media #{$mq-2} {
      margin-bottom: spacing(12);
      margin-left: grid-spacing(2, 0, 0);
      @include display-l-expanded-bold;
    }
    @media #{$mq-1} {
      margin-bottom: spacing(12);
      margin-left: grid-spacing(2, 0, 0);
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
  }

  &__border-overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    box-shadow: 0 0 0 1px var(--interactive-grid-border-overlay) inset, 0 0 0 1px var(--interactive-grid-border-overlay);
    pointer-events: none;
  }

  &__item {
    flex-basis: 50%;
    padding: spacing(3);
    border-right: 1px solid var(--interactive-grid-item-border);
    border-bottom: 1px solid var(--interactive-grid-item-border);
    @media #{$mq-3} {
      flex-basis: 25%;
    }
    @media #{$mq-45} {
      flex-basis: 16.6666%;
    }
    @media #{$mq-12} {
      padding-left: 0;

      &:nth-of-type(odd) {
        padding: spacing(3);
      }
    }

    &-link {
      display: flex;
      height: 100%;
      flex-direction: column;
      text-decoration: none;
      transition: all 200ms ease-in-out;

      @media #{$mq-12} {
        flex-flow: row wrap;
        align-content: space-between;
        justify-content: space-between;
      }
      @media #{$mq-345} {
        &:hover,
        &:focus,
        &:active {
          background-color: var(--interactive-grid-item-link-hover-background-color);

          .cmp-interactive-icon-grid__item-icon {
            visibility: visible;
          }
        }
      }
    }

    &-title {
      @include heading-s-extended-bold;
      padding: spacing(4) spacing(4) spacing(14) spacing(8);
      color: var(--interactive-grid-title-color);
      word-break: break-word;
      @media #{$mq-12} {
        display: flex;
        flex-basis: 100%;
        padding: spacing(4) spacing(3) spacing(9) spacing(4);
      }
    }

    &-image {
      display: block;
      width: spacing(25);
      height: spacing(25);
      margin: auto;
      margin-bottom: spacing(25);
      aspect-ratio: 1/1;
      object-fit: contain;
      @media #{$mq-12} {
        display: inline-flex;
        width: spacing(12);
        height: spacing(12);
        align-items: flex-end;
        margin: 0;
        margin-left: spacing(4);
      }
    }

    &-icon {
      display: inline-flex;
      align-items: flex-end;
      padding: spacing(2);
      color: var(--interactive-grid-icon-color);
      font-size: spacing(6);
      text-align: right;
      @media #{$mq-345} {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        visibility: hidden;
      }
    }
  }
}
