@use 'sass:map';

:root {
  --remixicon-font: remixicon, sans-serif;
}

[data-font='antartica-latin'] {
  --typography-font: frankefont, arial, sans-serif;
}

[data-font='others'] {
  --typography-font: ping, arial, sans-serif;
}

[data-font='chinese'] {
  --typography-font: ping-chinese, arial, sans-serif;
}

[data-font='lato'] {
  --typography-font: lato, arial, sans-serif;
}

[data-font='lato-latin'] {
  --typography-font: lato-latin, arial, sans-serif;
}

$typography-size-map: (
  large-display-xxxxl-expanded: 180px,
  large-display-xxxl-expanded: 120px,
  large-display-xxl-expanded: 80px,
  large-display-xl-expanded: 70px,
  large-display-l-expanded: 60px,
  medium-display-xxl-expanded: 70px,
  medium-display-xl-expanded: 60px,
  medium-display-l-expanded: 50px,
  small-display-xxl-expanded: 40px,
  small-display-xl-expanded: 30px,
  small-display-l-expanded: 25px,
  large-display-xxl-extra: 80px,
  large-display-xl-extra: 70px,
  large-display-l-extra: 60px,
  medium-display-xxl-extra: 70px,
  medium-display-xl-extra: 60px,
  medium-display-l-extra: 50px,
  small-display-xxl-extra: 60px,
  small-display-xl-extra: 50px,
  small-display-l-extra: 40px,
  4xl-extra: 55px,
  3xl-extra: 45px,
  xxl-extra: 35px,
  xl-extra: 30px,
  l-extra: 25px,
  m-extra: 20px,
  base-extra: 16px,
  s-extra: 14px,
  xxl-expanded: 35px,
  xl-expanded: 30px,
  l-expanded: 25px,
  m-expanded: 20px,
  base-expanded: 16px,
  s-expanded: 14px,
  paragraph-lead-xl: 28px,
  paragraph-lead-l: 24px,
  paragraph-lead-m: 20px,
  paragraph-lead-m-bold: 20px,
  paragraph-base: 16px,
  paragraph-base-bold: 16px,
  paragraph-s: 14px,
  paragraph-s-bold: 14px,
  caption: 12px,
  caption-bold: 12px,
  pre-title-s: 12px,
  pre-title-l: 14px,
  small-display-xl-expanded-countdown: 22px,
  countdown-page-date-time-item-value-mq4: 9vw,
  countdown-page-date-time-item-value-mq32: 7vw,
  countdown-page-date-time-item-value-mq1: 22px,
  countdown-page-headlines-mq3: 45px,
  countdown-page-headlines-mq2: 17px,
  countdown-page-paragraph-s: 3vw,
  massive-text-mq345: 120px,
  anchor-navigation-link-waterhub: 16px,
  anchor-navigation-link-generic: 14px,
);
$typography-line-height-map: (
  large-display-xxl-expanded: 90px,
  large-display-xl-expanded: 80px,
  large-display-l-expanded: 70px,
  large-display-xxxl-expanded: 180px,
  large-display-xxxxl-expanded: 195px,
  medium-display-xxl-expanded: 80px,
  medium-display-xl-expanded: 70px,
  medium-display-l-expanded: 60px,
  small-display-xxl-expanded: 50px,
  small-display-xl-expanded: 40px,
  small-display-l-expanded: 35px,
  large-display-xxl-extra: 90px,
  large-display-xl-extra: 80px,
  large-display-l-extra: 70px,
  medium-display-xxl-extra: 80px,
  medium-display-xl-extra: 70px,
  medium-display-l-extra: 60px,
  small-display-xxl-extra: 70px,
  small-display-xl-extra: 60px,
  small-display-l-extra: 50px,
  4xl-extra: 65px,
  3xl-extra: 55px,
  xxl-extra: 45px,
  xl-extra: 40px,
  l-extra: 32px,
  m-extra: 26px,
  base-extra: 22px,
  s-extra: 20px,
  xxl-expanded: 45px,
  xl-expanded: 40px,
  l-expanded: 32px,
  m-expanded: 26px,
  base-expanded: 22px,
  s-expanded: 20px,
  paragraph-lead-xl: 38px,
  paragraph-lead-l: 34px,
  paragraph-lead-m: 30px,
  paragraph-lead-m-bold: 30px,
  paragraph-base: 26px,
  paragraph-base-bold: 26px,
  paragraph-s: 22px,
  paragraph-s-bold: 20px,
  caption: 18px,
  caption-bold: 18px,
  pre-title-s: 18px,
  pre-title-l: 20px,
  small-display-xl-expanded-countdown: 40px,
  countdown-page-date-time-item-value-mq4: 9vw,
  countdown-page-date-time-item-value-mq32: 7vw,
  countdown-page-date-time-item-value-mq1: 22px,
  countdown-page-headlines-mq2: 30px,
  countdown-page-headlines-mq3: 70px,
  countdown-page-paragraph-s: 3vw,
  massive-text-mq345: 130px,
  anchor-navigation-link-waterhub: 32px,
  anchor-navigation-link-generic: 20px,
);
$typography-weight-map: (
  extra-bold: 800,
  expanded-bold: 700,
  semi-bold: 600,
  medium: 500,
  regular: 400,
  light: 200,
);

@mixin typography($size, $weight, $important: false) {
  @include get-typography(
    map.get($typography-size-map, $size),
    map.get($typography-line-height-map, $size),
    $important
  );

  @if $important {
    font-weight: map.get($typography-weight-map, $weight) !important;
  } @else {
    font-weight: map.get($typography-weight-map, $weight);
  }
}

// Typography (responsive)
@mixin display-xxl-expanded-bold {
  @media #{$mq-45} {
    @include typography(large-display-xxl-expanded, expanded-bold);
  }

  @media #{$mq-3} {
    @include typography(medium-display-xxl-expanded, expanded-bold);
  }

  @media #{$mq-12} {
    @include typography(small-display-xxl-expanded, expanded-bold);
  }
}

@mixin display-xl-expanded-bold {
  @media #{$mq-45} {
    @include typography(large-display-xl-expanded, expanded-bold);
  }

  @media #{$mq-3} {
    @include typography(medium-display-xl-expanded, expanded-bold);
  }

  @media #{$mq-12} {
    @include typography(small-display-xl-expanded, expanded-bold);
  }
}

@mixin display-l-expanded-bold {
  @media #{$mq-45} {
    @include typography(large-display-l-expanded, expanded-bold);
  }

  @media #{$mq-3} {
    @include typography(medium-display-l-expanded, expanded-bold);
  }

  @media #{$mq-12} {
    @include typography(small-display-l-expanded, expanded-bold);
  }
}

@mixin display-xxl-extra-bold {
  @media #{$mq-45} {
    @include typography(large-display-xxl-extra, extra-bold);
  }

  @media #{$mq-3} {
    @include typography(medium-display-xxl-extra, extra-bold);
  }

  @media #{$mq-12} {
    @include typography(small-display-xxl-extra, extra-bold);
  }
}

@mixin display-xl-extra-bold {
  @media #{$mq-45} {
    @include typography(large-display-xl-extra, extra-bold);
  }

  @media #{$mq-3} {
    @include typography(medium-display-xl-extra, extra-bold);
  }

  @media #{$mq-12} {
    @include typography(small-display-xl-extra, extra-bold);
  }
}

@mixin display-l-extra-bold {
  @media #{$mq-45} {
    @include typography(large-display-l-extra, extra-bold);
  }

  @media #{$mq-3} {
    @include typography(medium-display-l-extra, extra-bold);
  }

  @media #{$mq-12} {
    @include typography(small-display-l-extra, extra-bold);
  }
}

// Typography (not responsive)
@mixin heading-xxl-extended-bold {
  @include typography(xxl-expanded, expanded-bold);
}

@mixin heading-xl-extended-bold {
  @include typography(xl-expanded, expanded-bold);
}

@mixin heading-l-extended-bold {
  @include typography(l-expanded, expanded-bold);
}

@mixin heading-m-extended-bold {
  @include typography(m-expanded, expanded-bold);
}

@mixin heading-base-extended-bold {
  @include typography(base-expanded, expanded-bold);
}

@mixin heading-s-extended-bold {
  @include typography(s-expanded, expanded-bold);
}

@mixin heading-4xl-extra-bold {
  @include typography(4xl-extra, extra-bold);
}

@mixin heading-extra-bold-3xl {
  @include typography(3xl-extra, extra-bold);
}

@mixin heading-xxl-extra-bold {
  @include typography(xxl-extra, extra-bold);
}

@mixin heading-xl-extra-bold {
  @include typography(xl-extra, extra-bold);
}

@mixin heading-l-extra-bold {
  @include typography(l-extra, extra-bold);
}

@mixin heading-m-extra-bold {
  @include typography(m-extra, extra-bold);
}

@mixin heading-base-extra-bold {
  @include typography(base-extra, extra-bold);
}

@mixin heading-s-extra-bold {
  @include typography(s-extra, extra-bold);
}

@mixin heading-xl-extended {
  @include typography(xl-expanded, regular);
}

@mixin paragraph-lead-xl {
  @include typography(paragraph-lead-xl, regular);
}

@mixin paragraph-lead-l {
  @include typography(paragraph-lead-l, regular);
}

@mixin paragraph-lead-m {
  @include typography(paragraph-lead-m, regular);
}

@mixin paragraph-lead-m-bold {
  @include typography(paragraph-lead-m, medium);
}

@mixin paragraph-base($important: false) {
  @include typography(paragraph-base, light, $important);
}

@mixin paragraph-base-bold {
  @include typography(paragraph-base-bold, regular);
}

@mixin paragraph-s {
  @include typography(paragraph-s, light);
}

@mixin paragraph-s-bold {
  @include typography(paragraph-s-bold, medium);
}

@mixin caption {
  @include typography(caption, regular);
}

@mixin caption-bold($important: false) {
  @include typography(caption-bold, expanded-bold, $important);
}

@mixin pre-title-s {
  @include typography(pre-title-s, semi-bold);
  letter-spacing: 0.09em;
  text-transform: uppercase;
}

@mixin pre-title-l {
  @include typography(pre-title-l, extra-bold);
}

// only for contact component

@mixin display-xxxxl-expanded-bold-large-title {
  @media #{$mq-45} {
    @include typography(small-display-l-expanded, expanded-bold);
  }

  @media #{$mq-123} {
    @include heading-m-extended-bold;
  }
}

@mixin display-xxxxl-expanded-bold-large {
  @media #{$mq-45} {
    @include typography(large-display-xxxxl-expanded, expanded-bold);
  }

  @media #{$mq-3} {
    @include typography(small-display-xxl-expanded, expanded-bold);
  }

  @media #{$mq-12} {
    @include typography(small-display-xl-expanded, expanded-bold);
  }
}

@mixin display-xxxxl-expanded-bold-small {
  @media #{$mq-45} {
    @include typography(large-display-xxxl-expanded, expanded-bold);
  }

  @media #{$mq-3} {
    @include typography(small-display-xxl-expanded, expanded-bold);
  }

  @media #{$mq-12} {
    @include typography(small-display-xl-expanded, expanded-bold);
  }
}

/** TYPOGRAPHIES ONLY FOR COUNTDOWN PAGE **/

@mixin countdown-page-date-time-item-value {
  @media #{$mq-5} {
    @include typography(large-display-xxxxl-expanded, expanded-bold);
  }
  @media #{$mq-4} {
    @include typography(countdown-page-date-time-item-value-mq4, expanded-bold);
  }
  @media #{$mq-23} {
    @include typography(countdown-page-date-time-item-value-mq32, expanded-bold);
  }

  @media #{$mq-1} {
    @include typography(countdown-page-date-time-item-value-mq1, expanded-bold);
  }
}

@mixin paragraph-countdown-page-type {
  @include typography(countdown-page-paragraph-s, medium);
}

@mixin display-xl-expanded-bold-countdown-headlines {
  @media #{$mq-45} {
    @include typography(large-display-xl-expanded, expanded-bold);
  }

  @media #{$mq-3} {
    @include typography(countdown-page-headlines-mq3, expanded-bold);
  }

  @media #{$mq-12} {
    @include typography(countdown-page-headlines-mq2, expanded-bold);
  }
}

/** TYPOGRAPHIES ONLY FOR MASSIVE TEXT COMPONENT **/

@mixin massive-text-component-title {
  @media #{$mq-345} {
    @include typography(massive-text-mq345, expanded-bold);
  }
  @media #{$mq-2} {
    @include typography(medium-display-xl-expanded, expanded-bold);
  }
  @media #{$mq-1} {
    @include typography(small-display-xxl-expanded, expanded-bold);
  }
}

@mixin massive-text-component-subtitle {
  @media #{$mq-345} {
    @include typography(medium-display-xl-expanded, expanded-bold);
  }
  @media #{$mq-2} {
    @include typography(small-display-xl-expanded, expanded-bold);
  }
  @media #{$mq-1} {
    @include typography(base-expanded, expanded-bold);
  }
}

/** TYPOGRAPHIES ONLY FOR ANCHOR NAVIGATION COMPONENT **/

@mixin anchor-navigation-link-waterhub {
  @include typography(anchor-navigation-link-waterhub, expanded-bold);
}

@mixin anchor-navigation-link-generic {
  @include typography(anchor-navigation-link-generic, medium);
}

/** TYPOGRAPHIES ONLY FOR HERO COMPONENT **/

@mixin hero-component-title {
  @media #{$mq-5} {
    @include typography(large-display-xxl-expanded, expanded-bold);
  }
  @media #{$mq-4-custom2} {
    @include typography(large-display-xl-expanded, expanded-bold);
  }
  @media #{$mq-4-custom1} {
    @include typography(medium-display-l-expanded, expanded-bold);
  }
  @media #{$mq-23} {
    @include typography(small-display-xl-expanded, expanded-bold);
  }
  @media #{$mq-1} {
    @include typography(small-display-l-expanded, expanded-bold);
  }
}

/** TYPOGRAPHIES ONLY FOR ENTRY AND TABS TEASERS COMPONENT **/
@mixin entry-tabs-teasers-title {
  @media #{$mq-5} {
    @include typography(medium-display-l-expanded, expanded-bold);
  }
  @media #{$mq-4} {
    @include typography(small-display-xxl-expanded, expanded-bold);
  }
  @media #{$mq-23} {
    @include typography(small-display-xl-expanded, expanded-bold);
  }
  @media #{$mq-1} {
    @include typography(small-display-l-expanded, expanded-bold);
  }
}

/**  TYPOGRAPHIES ONLY FOR ANIMATED TEASER COMPONENT **/
@mixin animated-teaser-title {
  @media #{$mq-45} {
    @include typography(large-display-l-expanded, regular);
  }
  @media #{$mq-123} {
    @include typography(small-display-l-expanded, regular);
  }
}

/** TYPOGRAPHIES ONLY FOR IMAGE TABS COMPONENT **/
@mixin image-tabs-title {
  @media #{$mq-45} {
    @include typography(large-display-l-expanded, regular);
  }
  @media #{$mq-3} {
    @include typography(medium-display-l-expanded, regular);
  }
  @media #{$mq-2} {
    @include typography(small-display-l-extra, regular);
  }
  @media #{$mq-1} {
    @include typography(xl-expanded, regular);
  }
}
