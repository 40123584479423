.cmp-product-wishlist {
  width: 100%;
  padding-bottom: spacing(8);
  margin: auto;
  @media #{$mq-1} {
    width: grid-spacing(1, 12, 11);
  }

  @media #{$mq-2} {
    width: grid-spacing(2, 12, 11);
  }

  @media #{$mq-3} {
    width: grid-spacing(3, 12, 11);
  }

  @media #{$mq-4} {
    width: grid-spacing(4, 8, 7);
  }

  @media #{$mq-5} {
    width: grid-spacing(5, 8, 7);
  }

  @media print {
    margin: unset;
  }

  &__title {
    @include display-l-expanded-bold;
    margin-bottom: spacing(4);
    @media print {
      font-size: spacing(13);
    }
  }

  &__description {
    @include paragraph-base;
    margin-bottom: spacing(4);
    @media print {
      display: none;
    }
    @media #{$mq-45} {
      width: 80%;
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    margin: spacing(16) 0;
    gap: spacing(6);
    @media #{$mq-12} {
      justify-content: flex-start;
      gap: spacing(4);
    }
    @media print {
      display: none;
    }
  }

  &__sum {
    @include paragraph-lead-m;
    width: auto;
    margin-right: auto;

    &--number {
      color: var(--product-wishlist-sum-products-color);
    }
    @media #{$mq-12} {
      width: 100%;
    }
  }

  &__button {
    @include heading-base-extended-bold;
    padding: spacing(4) spacing(7);
    border: 2px solid var(--primary-frk-black);
    line-height: $icon-height-medium;

    &--remove {
      padding: 0;
      border: unset;

      .cmp-button-link__menu-item-content {
        padding: spacing(4) spacing(7);
        border: 2px solid var(--primary-frk-black);
        @media #{$mq-1} {
          padding: spacing(3) spacing(6);
        }
      }
      @media #{$mq-12} {
        flex-basis: 100%;
      }
    }

    &--inverted {
      border: 2px solid var(--product-wishlist-invert-button-color);
      background: var(--product-wishlist-invert-button-color);
      color: var(--primary-frk-white);
    }

    .cmp-button-link__icon {
      height: spacing(6);
      margin-right: spacing(2);
      font-size: spacing(6);
      line-height: unset;
    }
    @media #{$mq-1} {
      .cmp-button-link__icon {
        font-size: spacing(5);
      }
      @include heading-s-extended-bold;

      &:not(&--remove) {
        padding: spacing(3) spacing(6);
      }
    }
  }

  &__product,
  &__product-unavailable {
    display: flex;
    padding: spacing(8) 0 spacing(6) 0;
    border-bottom: 1px solid var(--gradations-frk-rosemary-50);
    gap: spacing(6);
    @media #{$mq-1} {
      flex-flow: column;
    }
  }

  &__product-thumbnail {
    max-width: spacing(42);
    @media print {
      max-width: spacing(38);
    }
  }

  &__product-content {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    justify-content: space-between;
    @media #{$mq-1} {
      gap: spacing(6);
    }
    @media print {
      justify-content: flex-start;
      gap: spacing(6);
    }
  }

  &__product-heading,
  &__product-description,
  &__product-actions {
    display: flex;
    justify-content: space-between;
    gap: spacing(2);
  }

  &__product-heading {
    @media #{$mq-1} {
      align-items: center;
    }
  }

  &__product-actions {
    @media #{$mq-1} {
      flex-flow: column;
    }
    @media print {
      display: none;
    }
  }

  &__product-title {
    @include heading-m-extended-bold;
  }

  &__product-label {
    @include paragraph-base;
  }

  &__product-sku {
    @include paragraph-base-bold;
    margin: spacing(2) 0;
  }

  &__product-download {
    @include heading-m-extended-bold;
    display: flex;
    color: var(--primary-frk-black);
    gap: spacing(4);
    text-decoration: none;
    @media #{$mq-2} {
      width: min-content;
    }
    @media #{$mq-1} {
      width: auto;
    }
  }

  &__product-icon {
    color: var(--product-wishlist-product-icon-color);
  }

  &__product-price {
    text-align: right;
  }

  &__product-price-value {
    @include heading-m-extra-bold;
  }

  &__product-price-vat {
    @include paragraph-base-bold;
    color: var(--grayscale-frk-cool-grey-8);
  }

  &__product-delete {
    .cmp-button-link__icon {
      color: var(--product-wishlist-product-delete-color);
      font-size: spacing(8);
    }
    @media print {
      display: none;
    }
  }

  &__product-link {
    @include heading-base-extended-bold;
    display: flex;
    align-items: end;
    color: var(--product-wishlist-product-link-color);
    gap: spacing(2);
    text-align: right;
    text-decoration: none;
  }

  &__product-unavailable {
    flex-flow: column;
  }

  &__product-unavailable-description {
    @include paragraph-base-bold;
    display: flex;
    align-items: center;
    padding: spacing(3) spacing(4);
    background-color: var(--notification-error);
    border-radius: spacing(2);
    gap: spacing(4);

    &--hasIcon {
      font-size: spacing(8);
    }
  }

  &__dialog {
    position: absolute;
    top: 10%;
    max-width: spacing(165);
    padding: spacing(8);
    border: none;
    background-color: var(--primary-frk-white);

    &::backdrop {
      background-color: var(--transparency-frk-black-50-trans);
    }
  }

  &__dialog-close {
    @include heading-base-extra-bold;
    display: flex;
    flex-flow: row-reverse;
    margin-left: auto;
  }

  &__dialog-description {
    @include paragraph-base;
    padding: spacing(6);
  }

  &__dialog-actions {
    display: flex;
    justify-content: end;
    gap: spacing(6);
    @media #{$mq-1} {
      flex-flow: column;
    }
  }

  &__dialog-button {
    display: flex;
    padding: spacing(4) spacing(7);
    border: 2px solid var(--primary-frk-black);
    margin-left: auto;
    background-color: white;
    color: var(--primary-frk-black);
    @include heading-base-extra-bold;

    &:hover,
    &:focus {
      cursor: pointer;
    }

    &--positive {
      border: 2px solid var(--product-wishlist-dialog-button-color);
      margin: 0;
      background-color: var(--product-wishlist-dialog-button-color);
      color: var(--primary-frk-white);
      @media #{$mq-1} {
        margin-left: auto;
      }
    }

    &--copied {
      @include paragraph-base;
      padding: spacing(4) 0;
      border: unset;
    }
  }

  &__share {
    display: flex;
    flex-flow: column;
    margin-top: spacing(12);
    gap: spacing(6);
  }

  &__share-category {
    display: flex;
    align-items: center;
    @media #{$mq-1} {
      flex-flow: column;
      align-items: flex-start;

      .cmp-product-wishlist__dialog-button {
        margin-left: 0;
      }
    }
  }

  &__share-label {
    @include paragraph-base;
    color: var(--grayscale-frk-cool-gray-11);
  }

  &__share-description {
    @include paragraph-base;
    color: var(--grayscale-frk-cool-gray-11);
  }

  &--isEmpty {
    @include paragraph-base;
    padding: spacing(20);
    margin-top: spacing(20);
    background-color: var(--grayscale-frk-cool-grey-5);
    color: var(--grayscale-frk-cool-gray-11);
    text-align: center;
  }
}

.cmp-product-wishlist-container {
  margin-left: spacing(3);
}

@media print {
  .wishlist-print:not(.wishlist-print--shared) {
    main * {
      display: none;
    }
  }
}
