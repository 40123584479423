.cmp-product-carousel-zoom-modal {
  .cmp-modal__close-button {
    &::after {
      font-size: spacing(8);
    }
    @media #{$mq-345} {
      width: spacing(16);
      height: spacing(16);
      background-color: var(--transparency-frk-white-50-trans);
    }

    @media #{$mq-12} {
      top: inherit;
      right: 0;
      bottom: 0;
      display: flex;
      width: spacing(16.25);
      height: spacing(16.25);
      align-items: center;
      justify-content: center;
      border-top: 1px solid var(--grayscale-frk-cool-grey-5);
      border-left: 1px solid var(--primary-frk-black);
      background: var(--primary-frk-white);
    }
  }

  .cmp-modal__inner-content {
    flex-direction: row;
  }

  &__zoom-area {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100vw;
    cursor: zoom-out;

    @media #{$mq-12} {
      bottom: spacing(16.25);
    }

    &--is-zoomed-out {
      position: relative;
      height: auto;
      cursor: zoom-in;

      @media #{$mq-12} {
        width: 100%;
      }
      @media #{$mq-5} {
        width: grid-spacing(5, 8, 7);
        margin-left: grid-spacing(5, 1, 1);
      }
      @media #{$mq-4} {
        width: grid-spacing(4, 8, 7);
        margin-left: grid-spacing(4, 1, 1);
      }

      @media #{$mq-3} {
        width: grid-spacing(3, 8, 7);
        margin-left: grid-spacing(3, 1, 1);
      }
    }
  }

  &__modal-image-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    @media #{$mq-12} {
      overflow: auto;
    }

    > img {
      width: auto;
    }

    &--is-zoomed-out {
      position: relative;

      @media #{$mq-345} {
        height: auto;
      }
      @media #{$mq-5} {
        width: grid-spacing(5, 8, 7);
      }
      @media #{$mq-4} {
        width: grid-spacing(4, 8, 7);
      }

      @media #{$mq-3} {
        width: grid-spacing(3, 8, 7);
      }

      img {
        margin: 0 !important;
        @media #{$mq-12} {
          width: 100%;
        }
        @media #{$mq-345} {
          height: auto;
        }
        @media #{$mq-5} {
          width: grid-spacing(5, 8, 7);
          margin-left: grid-spacing(5, 2, 2);
        }
        @media #{$mq-4} {
          width: grid-spacing(4, 8, 7);
          margin-left: grid-spacing(4, 2, 2);
        }

        @media #{$mq-3} {
          width: grid-spacing(3, 8, 7);
          margin-left: grid-spacing(3, 2, 2);
        }
      }
    }
  }

  &__modal-mobile-layout {
    position: fixed;
    right: spacing(16.25);
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    padding: spacing(3.5) 0;
    border-top: 1px solid var(--grayscale-frk-cool-grey-5);
    background-color: var(--primary-frk-white);

    .cmp-product-carousel__number-slides {
      padding-left: spacing(8);
      color: var(--primary-frk-black);
      @include paragraph-s-bold;
    }
  }

  &__modal-mobile-buttons-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    .cmp-product-carousel__scroller {
      @include button(
        var(--primary-frk-white),
        var(--primary-frk-black),
        var(--primary-frk-white),
        var(--primary-frk-white),
        var(--primary-frk-black),
        var(--primary-frk-white)
      );
      position: relative;
      bottom: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      font-size: spacing(6) !important;
    }

    .cmp-product-carousel__scroller--bottom {
      right: inherit;
    }

    .cmp-product-carousel__scroller--top {
      left: inherit;
    }
  }
}
