.cmp-bullet-list {
  &--style-check {
    @include bullets(var(--bullet-color), 'check');
  }

  &--style-square {
    @include bullets(var(--bullet-color), 'square');
  }

  &--typo-regular {
    .cmp-bullet-list__item {
      @media #{$mq-12} {
        @include paragraph-base;
      }

      @media #{$mq-345} {
        @include paragraph-lead-m;
      }
    }
  }

  &--typo-bold {
    .cmp-bullet-list__item {
      @media #{$mq-12} {
        @include heading-base-extra-bold;
      }

      @media #{$mq-345} {
        @include heading-m-extra-bold;
      }
    }
  }
}
