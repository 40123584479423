.language-switcher {
  @media #{$mq-3} {
    padding: spacing(21) grid-spacing(3, 2, 2, 1) 0;
  }
  @media #{$mq-4} {
    padding: spacing(21) grid-spacing(4, 2, 2, 1) 0;
  }
  @media #{$mq-5} {
    padding: spacing(21) grid-spacing(5, 2, 2, 1) 0;
  }
}

.language-switcher__heading-label {
  @media #{$mq-12} {
    @include heading-l-extended-bold;
    @include with-left-and-right-spacing;
    margin-bottom: spacing(3);
  }
  @media #{$mq-345} {
    @include heading-xl-extended-bold;
    margin-bottom: spacing(6);
  }
}

.language-switcher__search-container {
  @media #{$mq-12} {
    @include with-left-and-right-spacing;
  }
}

.language-switcher__country-list {
  @media #{$mq-345} {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }
}

.language-switcher__country {
  position: relative;
  @include with-left-and-right-spacing;

  @media #{$mq-12} {
    padding-top: spacing(4);
    padding-bottom: spacing(4);
  }
  @media #{$mq-345} {
    flex-basis: calc(50% - grid-spacing(3, 0, 1, 0) / 2);
    padding: spacing(4);
  }

  &:nth-of-type(even) {
    @media #{$mq-3} {
      margin-left: grid-spacing(3, 0, 1, 0);
    }
    @media #{$mq-4} {
      margin-left: grid-spacing(4, 0, 1, 0);
    }
    @media #{$mq-5} {
      margin-left: grid-spacing(5, 0, 1, 0);
    }
  }

  &--active {
    background: var(--language-switcher-active-color);
  }

  &::after {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    display: block;
    height: 1px;
    margin: 0 auto;
    background-color: var(--language-switcher-country-divider);
    content: '';
    @media #{$mq-1} {
      right: var(--page-container-side-margin-s);
      left: var(--page-container-side-margin-s);
    }
    @media #{$mq-2} {
      right: var(--page-container-side-margin-m);
      left: var(--page-container-side-margin-m);
    }
  }
}

.language-switcher__country-name {
  @include heading-s-extended-bold;
  margin-bottom: spacing(2);
}

.language-switcher__country-language {
  @include link(inherit, false, var(--language-switcher-link-color));

  @media #{$mq-12} {
    @include paragraph-s-bold;
    margin-right: spacing(2);

    :not(:first-child) {
      margin-left: spacing(2);
    }
  }

  @media #{$mq-345} {
    @include paragraph-s-bold;
    margin-right: spacing(3);

    :not(:first-child) {
      margin-left: spacing(3);
    }
  }

  &--active {
    color: var(--language-switcher-link-color);
  }
}
