.cmp-multi-select-filter-popover__header {
  display: flex;
  border-bottom: solid 1px var(--product-explorer-multi-select-filter-popover-header-border-color);
  margin-bottom: spacing(5);

  @media #{$mq-345} {
    display: none;
  }
}

.cmp-multi-select-filter-popover__dismiss {
  padding: spacing(3) spacing(4);
  border-right: solid 1px var(--product-explorer-multi-select-filter-popover-dismiss-border-color);
  cursor: pointer;
  @media #{$mq-123} {
    outline: transparent;
  }
}

.cmp-multi-select-filter-popover__title {
  @include heading-s-extended-bold;
  padding: spacing(3) spacing(4);
}
