.cmp-scrolling-arrow {
  border: transparent;
  background: transparent;
  color: var(--primary-frk-vibrant-red);
  cursor: pointer;
  font-size: spacing(8);
  @media #{$mq-12} {
    padding: 0;
    text-align: right;
  }

  &--is-up {
  }

  &--is-down {
  }
}
