.cmp-planner-data {
  display: flex;
  flex-direction: column;

  &__filters {
    display: grid;
    grid-gap: spacing(6);

    @media #{$mq-12} {
      align-items: start;
      margin-bottom: spacing(13);
    }

    @media #{$mq-345} {
      align-items: flex-end;
      margin-bottom: spacing(20);
    }
  }

  // override default spacing
  .cmp-download-list {
    margin-bottom: 0;
  }

  .cmp-download-list__item {
    margin: 0;
  }

  .cmp-download-list__content {
    .cmp-download-list__title {
      @media #{$mq-123} {
        @include paragraph-base;
      }

      @media #{$mq-45} {
        @include paragraph-lead-m;
      }
    }
  }

  &__document-type {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--gradations-frk-rosemary-50);
    cursor: pointer;

    &-icon {
      transition: all 0.3s ease-in-out;

      &--is-open {
        transform: rotate(45deg);
      }
    }

    @media #{$mq-123} {
      padding: spacing(4) 0;
      @include heading-base-extra-bold;
    }

    @media #{$mq-45} {
      padding: spacing(6) 0;
      @include heading-m-extra-bold;
    }
  }

  &__filter,
  &__search-wrapper {
    @media #{$mq-45} {
      grid-row: 1;
    }
  }

  &__reset {
    color: var(--grayscale-frk-cool-grey-5);
    @include paragraph-s-bold;

    @media #{$mq-12} {
      grid-row: 4;
    }

    @media #{$mq-3} {
      grid-column: 2;
      grid-row: 2;
    }

    @media #{$mq-45} {
      grid-column: 1;
      grid-row: 2;
    }

    .cmp-button-link__icon {
      margin-right: spacing(1);
      @include paragraph-base;
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
  }

  &__load-more {
    @include button(
      transparent,
      var(--primary-frk-black),
      var(--primary-frk-black),
      var(--primary-frk-black),
      var(--primary-frk-white),
      var(--primary-frk-black)
    );
    cursor: pointer;
  }

  &__search {
    position: relative;
    margin-top: spacing(7);
    color: var(--primary-frk-black);

    &-label {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: spacing(7, true);
      @include paragraph-s-bold;
    }

    &-input-field {
      width: 100%;
      padding: spacing(2) spacing(3);
      border: solid 2px var(--primary-frk-black);
      color: var(--primary-frk-black);
      @include paragraph-base;

      &:focus-within {
        outline: solid 1px;
      }
    }
  }

  &__results {
    padding: 0;
  }
}
