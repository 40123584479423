.cmp-blog {
  &__floating-bar {
    @include button(
      var(--primary-frk-vibrant-red),
      var(--primary-frk-white),
      var(--primary-frk-vibrant-red),
      var(--gradations-frk-vibrant-red-20),
      var(--primary-frk-white),
      var(--gradations-frk-vibrant-red-20)
    );
    position: fixed;
    z-index: 1;
    right: 0;
    bottom: spacing(20);
    display: flex;
    width: spacing(12);
    height: spacing(12);
    align-items: center;
    justify-content: center;
    padding: 0;
    text-decoration: none;

    &:hover,
    &:focus {
      cursor: pointer;
    }

    > i {
      font-size: spacing(5);
    }
  }

  &-floating-button {
    &__modal-image {
      @media #{$mq-5} {
        width: calc(grid-spacing(5, 5, 4) - spacing(8));
        margin-right: grid-spacing(5, 0, 1);
      }
      @media #{$mq-4} {
        width: calc(grid-spacing(4, 5, 4) - spacing(8));
        margin-right: grid-spacing(4, 0, 1);
      }
      @media #{$mq-123} {
        padding: spacing(16) 0 spacing(4);
      }
      @media #{$mq-3} {
        width: grid-spacing(3, 10, 9);
        aspect-ratio: 4/3;
        object-fit: cover;
      }
      @media #{$mq-2} {
        width: grid-spacing(2, 10, 9);
      }
      @media #{$mq-1} {
        width: grid-spacing(1, 10, 9);
      }
    }
  }
}
