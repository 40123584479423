@use 'sass:map';

@mixin link(
  $color: var(--link-font-color),
  $showIcon: true,
  $hoverColor: var(--link-font-color),
  $inline: true,
  $reverse: false
) {
  padding: 0;
  border: none;
  background-color: transparent;
  color: $color;
  text-decoration: none;
  transition: all 0.15s linear;

  @if $inline {
    display: inline-block;
  }

  @if $showIcon {
    // TODO: check on mobile if that does not mess up. Eg. link overlaping on elements
    transform: translateX(-20px);

    &::after {
      padding-left: spacing(1);
      content: '\ea6c';
      font-family: var(--remixicon-font);
      opacity: 1;
      vertical-align: bottom;
    }

    &::before {
      padding-right: spacing(1);
      content: '\ea6c';
      font-family: var(--remixicon-font);
      opacity: 0;
      vertical-align: bottom;
    }

    &:hover,
    &:focus {
      transform: translateX(5px);

      &::after {
        opacity: 0;
      }

      &::before {
        opacity: 1;
      }
    }
  }

  @if $hoverColor {
    &:hover,
    &:focus {
      color: $hoverColor;
      cursor: pointer;
    }
  }

  @if $reverse {
    transform: translateX(0);

    &::after {
      content: '\ea60';
      opacity: 0;
    }

    &::before {
      content: '\ea60';
      opacity: 1;
    }

    &:hover,
    &:focus {
      transform: translateX(-20px);

      &::after {
        opacity: 1;
      }

      &::before {
        opacity: 0;
      }
    }
  }
}
