.cmp-global-search-view-more {
  .cmp-button-link__content {
    @include heading-base-extra-bold;
  }

  &__label {
    display: flex;
    align-items: flex-end;
    @include heading-l-extended-bold;
    @media #{$mq-12} {
      margin-bottom: spacing(4);
    }

    @media #{$mq-345} {
      margin-bottom: spacing(5);
    }

    &--counter {
      margin-left: spacing(2);
      font-size: 70%;
    }
  }

  &__container {
    @media #{$mq-345} {
      margin-top: spacing(20);
      margin-bottom: spacing(20);
    }

    @media #{$mq-123} {
      padding-left: 0;
      margin-top: spacing(8);
      margin-bottom: spacing(16);
    }

    @media #{$mq-4} {
      padding-left: grid-spacing(4, 1, 1);
    }

    @media #{$mq-5} {
      padding-left: grid-spacing(5, 1, 1);
    }
  }
}
