.sample-spacing {
  @include with-max-width-center;
  @include with-left-and-right-spacing;
  display: flex;

  @media #{$mq-12} {
    flex-direction: column;
  }

  &__element-1 {
    display: flex;

    @media #{$mq-3} {
      width: grid-spacing(3, 3, 2);
      margin-left: grid-spacing(3, 3, 3);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 3, 2);
      margin-left: grid-spacing(4, 2, 2);
    }
    @media #{$mq-5} {
      width: grid-spacing(5, 3, 2);
      margin-left: grid-spacing(5, 2, 2);
    }
  }

  &__element-2 {
    display: flex;

    @media #{$mq-3} {
      width: grid-spacing(3, 3, 2);
      margin-left: grid-spacing(3, 1, 2);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 3, 2);
      margin-left: grid-spacing(4, 1, 2);
    }
    @media #{$mq-5} {
      width: grid-spacing(5, 3, 2);
      margin-left: grid-spacing(5, 1, 2);
    }
  }

  &__content {
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: lime;
    color: white;
  }
}
