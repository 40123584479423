.cmp-search-result-item {
  padding: spacing(8) 0;
  border-bottom: 1px solid var(--global-search-result-item-border-color);

  &__wrapper {
    &:link,
    &:visited,
    &:active,
    &:focus,
    &:hover {
      color: var(--global-search-result-item-wrapper-hover-color);
      text-decoration: none;
    }
  }

  &__content-date {
    @include paragraph-s;
    margin-bottom: spacing(1);
    color: var(--global-search-result-item-date-color);
  }

  &__content-title {
    @include heading-m-extra-bold;
    margin-bottom: spacing(1);
  }

  &__content-short-description {
    @include paragraph-base;
    display: flex;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  &--with-image {
    display: flex;
    align-items: flex-start;

    .cmp-search-result-item__image {
      display: flex;
      width: grid-spacing(3, 1, 0);
      align-items: center;
      justify-content: center;
      background-color: var(--global-search-result-item-image-background-color);

      @media #{$mq-12} {
        display: none;
        width: grid-spacing(2, 3, 2);
      }

      img {
        padding: spacing(2);
        aspect-ratio: 1/1;
        object-fit: contain;
      }
    }

    .cmp-search-result-item__content {
      @media #{$mq-5} {
        width: grid-spacing(5, 6, 5);
        margin-left: grid-spacing(5, 0, 1);
      }
      @media #{$mq-4} {
        width: grid-spacing(4, 6, 5);
        margin-left: grid-spacing(4, 0, 1);
      }
      @media #{$mq-3} {
        width: grid-spacing(3, 6, 5);
        margin-left: grid-spacing(3, 0, 1);
      }

      @media #{$mq-12} {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}
