.cmp-overview-page-hero {
  @include grid-layout;
  @include with-max-width-center;
  @include remove-side-margins;

  @media #{$mq-345} {
    padding-top: $header-height-desktop;
    grid-template-rows: spacing(20) auto;
  }

  @media #{$mq-12} {
    padding-top: $header-height-mobile;
  }

  &__main-article {
    background-color: var(--overview-page-hero-background-color);
    color: var(--overview-page-hero-text-color);
    @media #{$mq-345} {
      grid-column: #{column-index(0)} / #{column-index(7, 1)};
      grid-row: 1/4;
    }
    @media #{$mq-12} {
      grid-column: #{column-index(0)} / #{column-index(13, 1)};
      grid-row: 1/2;
    }
  }

  &__main-image {
    aspect-ratio: 16 /9;
    object-fit: cover;
  }

  &__main-content {
    @include with-left-and-right-spacing;
    @media #{$mq-5} {
      margin-right: grid-spacing(5, 1, 1);
      margin-left: grid-spacing(5, 1, 1);
    }

    @media #{$mq-345} {
      padding-top: spacing(11);
      padding-bottom: spacing(20);
    }
    @media #{$mq-12} {
      padding-top: spacing(8);
      padding-bottom: spacing(9);
    }
  }

  &__main-title {
    @include display-l-expanded-bold;
    margin-bottom: spacing(5);
  }

  &__main-description {
    @include paragraph-lead-m;
    margin-bottom: spacing(20);
  }

  &__main-button {
    @include button(
      var(--overview-page-hero-button-background-color),
      var(--overview-page-hero-button-text-color),
      var(--overview-page-hero-button-border-color),
      var(--overview-page-hero-button-hover-background-color),
      var(--overview-page-hero-button-hover-text-color),
      var(--overview-page-hero-button-hover-border-color)
    );
  }

  &__content-meta {
    margin-bottom: spacing(6);
  }

  &__content-date {
    display: inline;
    padding-right: spacing(3);
    border-right: 1px solid var(--primary-frk-black);
    @include paragraph-s;

    @media #{$mq-1} {
      padding-right: spacing(2);
    }
  }

  &__content-tag {
    padding: spacing(1) spacing(3);
    border: 1px solid var(--primary-frk-black);
    margin-right: spacing(3);
    margin-left: spacing(3);
    border-radius: spacing(24);
    @include paragraph-s-bold;
  }

  &__content-views {
    display: flex;
    align-items: center;
    padding-left: spacing(3);
    border-left: 1px solid var(--primary-frk-black);
    @include paragraph-s;
  }

  &__featured-articles {
    background-color: var(--overview-page-feature-articles-background-color);
    @media #{$mq-345} {
      padding: spacing(16) spacing(20) spacing(20) spacing(20);
      grid-column: #{column-index(7, 1)} / #{column-index(13, 0)};
      grid-row: 2/3;
    }
    @media #{$mq-12} {
      padding-top: spacing(8);
      grid-column: #{column-index(0)} / #{column-index(13, 1)};
      grid-row: 2/3;
      @include with-left-and-right-spacing;
    }
  }

  &__featured-title {
    @include heading-l-extended-bold;
    margin-bottom: spacing(12);
  }

  &__featured-articles-article {
    display: flex;
    color: inherit;
    text-decoration: none;
  }

  &__featured-articles-list {
    padding: 0;
    margin: 0;
  }

  &__featured-articles-item {
    display: flex;

    @media #{$mq-345} {
      margin-bottom: spacing(22);
    }
    @media #{$mq-12} {
      margin-bottom: spacing(10);
    }
  }

  &__article-image {
    width: 90px;
    height: 90px;
    margin-right: spacing(6);
    aspect-ratio: 1/1;
    object-fit: cover;
  }

  &__article-content {
    color: inherit;
    text-decoration: none;
  }

  &__article-meta {
    @include paragraph-s;
  }

  &__article-date {
    padding-right: spacing(3);
    border-right: 1px solid var(--primary-frk-black);
  }

  &__article-tag {
    padding: spacing(1) spacing(3);
    border: 1px solid var(--primary-frk-black);
    margin-left: spacing(3);
    border-radius: spacing(24);
    @include paragraph-s-bold;
  }

  &__article-title {
    @include heading-s-extended-bold;
    display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
    overflow: hidden;
    margin-top: spacing(3);
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
  }

  &__article-cta {
    @include link(var(--overview-page-feature-articles-link-color));
  }
}
