.cmp-newsletter {
  &__title {
    @include heading-xxl-extended-bold;
    @media #{$mq-12} {
      @include heading-m-extended-bold;
    }

    @media #{$mq-1} {
      margin-left: grid-spacing(1, 0, 0);
    }
    @media #{$mq-2} {
      margin-left: grid-spacing(2, 0, 0);
    }

    @media #{$mq-3} {
      margin-left: grid-spacing(3, 1, 1);
    }
    @media #{$mq-4} {
      margin-left: grid-spacing(4, 1, 1);
    }
    @media #{$mq-5} {
      margin-left: grid-spacing(5, 1, 1);
    }
  }

  &__description {
    margin-top: spacing(4);
    @include paragraph-lead-m;
    @media #{$mq-1} {
      margin-left: grid-spacing(1, 0, 0);
    }
    @media #{$mq-2} {
      margin-left: grid-spacing(2, 0, 0);
    }

    @media #{$mq-3} {
      margin-left: grid-spacing(3, 1, 1);
    }
    @media #{$mq-4} {
      margin-left: grid-spacing(4, 1, 1);
    }
    @media #{$mq-5} {
      margin-left: grid-spacing(5, 1, 1);
    }
  }

  &__form {
    margin-top: spacing(12);
    @media #{$mq-1} {
      width: 100%;
      margin-top: spacing(10);
      margin-left: grid-spacing(1, 0, 0);
    }
    @media #{$mq-2} {
      width: 100%;
      margin-top: spacing(10);
      margin-left: grid-spacing(2, 0, 0);
    }

    @media #{$mq-3} {
      width: grid-spacing(3, 6, 5);
      margin-left: grid-spacing(3, 2, 2);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 5, 4);
      margin-left: grid-spacing(4, 2, 2);
    }
    @media #{$mq-5} {
      width: grid-spacing(5, 5, 4);
      margin-left: grid-spacing(5, 2, 2);
    }
  }
}
