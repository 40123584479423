.cmp-navigation-product-teaser {
  position: relative;
  @media #{$mq-12} {
    width: 100%;
    height: 220px;
  }
  @media #{$mq-3} {
    width: 100%;
    height: 280px;
  }
  @media #{$mq-45} {
    height: 280px;
    margin-top: spacing(85);
  }

  &__image {
    aspect-ratio: 16/9;
    object-fit: cover;
  }

  &__content {
    position: absolute;
    bottom: spacing(8);
    left: spacing(10);
  }

  &__title {
    @include heading-m-extended-bold;
    margin-bottom: spacing(4);
    color: var(--navigation-product-teaser-title-color);
    @media #{$mq-1} {
      width: grid-spacing(1, 10, 9);
    }
    @media #{$mq-2} {
      width: grid-spacing(2, 10, 9);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 4, 3);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 4, 3);
    }
    @media #{$mq-5} {
      width: grid-spacing(5, 4, 3);
    }
  }

  &__button {
    @include button(
      var(--navigation-product-teaser-button-background-color),
      var(--navigation-product-teaser-button-text-color),
      var(--navigation-product-teaser-button-border-color),
      var(--navigation-product-teaser-button-hover-background-color),
      var(--navigation-product-teaser-button-hover-text-color),
      var(--navigation-product-teaser-button-hover-border-color)
    );
    width: fit-content;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
