.cmp-slider-hero-preview {
  display: grid;
  align-items: start;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr auto;
  opacity: 0.5;
  transition: 0.5s linear opacity;

  @media #{$mq-1} {
    width: grid-spacing(1, 9, 8);
    margin-right: grid-spacing(1, 0, 1);
  }
  @media #{$mq-2} {
    width: grid-spacing(2, 9, 8);
    margin-right: grid-spacing(2, 0, 1);
  }

  // FRAN-2239
  @media #{$mq-12} {
    &:last-child {
      width: 100%;
    }
  }
  @media #{$mq-3} {
    width: grid-spacing(3, 3, 2);
    margin-right: grid-spacing(3, 0, 1);
  }
  @media #{$mq-4} {
    width: grid-spacing(4, 3, 2);
    margin-right: grid-spacing(4, 0, 1);
  }
  @media #{$mq-5} {
    width: grid-spacing(5, 3, 2);
    margin-right: grid-spacing(5, 0, 1);
  }

  &__thumbnail {
    width: spacing(20);
    height: auto;
    margin-right: spacing(6);
    aspect-ratio: 1 / 1;
    grid-column: 1;
    grid-row: 1 / 3;

    @media #{$mq-123} {
      display: none;
    }
  }

  &__pretitle,
  &__title {
    word-break: break-word;
  }

  &__pretitle {
    @include caption-bold;
    grid-column: 2;
    grid-row: 1;
  }

  &__title {
    @include heading-s-extended-bold;
    grid-column: 2;
    grid-row: 2;
    word-break: break-word;
  }

  &__timer {
    position: relative;
    overflow: hidden;
    margin-top: spacing(6);
    background-color: var(--slider-hero-timer-background-color);
    grid-column: 1 / 3;
    grid-row: 3;
    opacity: 0;
    transition: opacity 0.5s;

    @media #{$mq-1} {
      width: grid-spacing(1, 7.5, 7);
    }
    @media #{$mq-2} {
      width: grid-spacing(2, 7.5, 7);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 2.5, 2);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 2.5, 2);
    }
    @media #{$mq-5} {
      width: grid-spacing(5, 2.5, 2);
    }
  }

  &__progress {
    width: 0;
    height: spacing(0.5);
    background-color: var(--slider-hero-timer-progress-color);
    visibility: hidden;
  }

  &--is-active {
    opacity: 1;

    .cmp-slider-hero-preview__timer {
      opacity: 1;
    }

    .cmp-slider-hero-preview__progress {
      animation: progress-bar 6s linear;
      visibility: visible;
    }
  }

  &:hover,
  &:focus {
    .cmp-slider-hero-preview__progress {
      animation: reset-progress-bar 0.4s linear;
      animation-play-state: paused;
    }
  }
}

@keyframes progress-bar {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@keyframes reset-progress-bar {
  100% {
    width: 0%;
  }
}
