.cmp-product-list-item {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--product-explorer-list-item-background-color);

  @media #{$mq-1} {
    width: grid-spacing(1, 6, 5);
  }
  @media #{$mq-2} {
    width: grid-spacing(2, 6, 5);
  }
  @media #{$mq-3} {
    width: grid-spacing(3, 4, 3);
  }
  @media #{$mq-4} {
    width: grid-spacing(4, 3, 2);
  }
  @media #{$mq-5} {
    width: grid-spacing(5, 3, 2);
  }

  a {
    display: flex;
    height: 100%;
    flex-flow: column;
    color: var(--product-explorer-list-item-link-text-color);
    text-decoration: none;
  }

  &__image {
    padding: spacing(12);
    aspect-ratio: 1 / 1;
    @media #{$mq-3} {
      padding: spacing(8);
    }

    @media #{$mq-12} {
      padding: spacing(6);
      font-size: 14px;
    }

    .cmp-responsive-image__outer-wrapper {
      width: fit-content;
      height: fit-content;
    }

    img {
      display: block;
      object-fit: contain;
      transition: all 0.15s linear;
    }
  }

  &__colors-list {
    display: flex;
    padding: spacing(4) spacing(6);
    margin: 0;
    @media #{$mq-3} {
      padding-left: spacing(4);
    }

    @media #{$mq-12} {
      padding: spacing(2);
    }
  }

  &__color {
    display: inline-flex;
    width: spacing(6);
    height: spacing(6);
    align-items: center;
    justify-content: center;
    aspect-ratio: 1/1;
    font-size: spacing(3);

    &:not(:last-of-type) {
      margin-right: spacing(2);
    }

    &--more {
      font-size: spacing(6);
      visibility: hidden;
    }

    &[data-index='4'] ~ li:not(.cmp-product-list-item__color--more) {
      display: none;
    }

    &[data-index='5'] ~ li.cmp-product-list-item__color--more {
      display: inline-flex;
      visibility: visible;
    }

    @media #{$mq-12} {
      width: spacing(4);
      height: spacing(4);

      &:not(:last-of-type) {
        margin-right: spacing(1);
      }

      &--more {
        font-size: spacing(4);
      }
    }
  }

  &__title {
    @include paragraph-lead-m-bold;
    flex: 1;
    padding: spacing(6) spacing(6) spacing(7) spacing(6);
    border-top: 2px solid var(--product-explorer-list-item-title-border-color);

    @media #{$mq-3} {
      padding: spacing(6) spacing(6) spacing(7) spacing(4);
    }
    @media #{$mq-12} {
      @include paragraph-s-bold;
    }
  }

  &__variant {
    display: flex;
    align-items: center;
    @media #{$mq-45} {
      padding-left: spacing(6);
    }
    @media #{$mq-3} {
      padding-left: spacing(4);
    }
    @media #{$mq-12} {
      padding-left: spacing(2);
    }

    &--is-hidden {
      visibility: hidden;
    }
  }

  &__label {
    @include caption;
    margin-left: spacing(2);
  }

  &:hover,
  &:focus {
    .cmp-product-list-item__title {
      background: var(--secondary-frk-champagne);

      &::after {
        position: absolute;
        right: spacing(3);
        bottom: spacing(3);
        color: var(--primary-frk-vibrant-red);
        content: '\ea6c';
        font-family: var(--remixicon-font);
      }
    }

    .cmp-product-list-item__image {
      .cmp-responsive-image__image {
        transform: scale(1.1);
      }
    }
  }
}
