.cmp-teaser-slider {
  @include with-max-width-center;
  @include remove-side-margins;
  @include grid-layout;

  @media #{$mq-12} {
    @include with-left-and-right-spacing;
  }

  &__headline {
    @include display-l-expanded-bold;

    @media #{$mq-345} {
      grid-column: #{column-index(2)} / #{column-index(13, 1)};
    }
    @media #{$mq-45} {
      margin-bottom: spacing(20);
    }
    @media #{$mq-3} {
      margin-bottom: spacing(6);
    }
    @media #{$mq-12} {
      margin-bottom: spacing(8);
      grid-column: #{column-index(0)} / #{column-index(13, 1)};
    }
    margin-bottom: spacing(20);
  }

  &__main-article {
    background-color: var(--teaser-slider-background-color);
    color: var(--teaser-slider-text-color);
    @media #{$mq-345} {
      grid-column: #{column-index(2)} / #{column-index(7, 1)};
    }
    @media #{$mq-12} {
      margin-bottom: spacing(10);
      grid-column: #{column-index(0)} / #{column-index(13, 1)};
    }
  }

  &__featured-articles {
    @media #{$mq-45} {
      flex-direction: column;
      grid-column: #{column-index(8)} / #{column-index(11, 1)};
    }
    @media #{$mq-3} {
      flex-direction: column;
      grid-column: #{column-index(8)} / #{column-index(12, 1)};
    }
    @media #{$mq-345} {
      display: grid;
      grid-template-rows:
        spacing(26.5) 1fr spacing(26.5) 1fr spacing(26.5)
        1fr spacing(26.5);
    }
    @media #{$mq-12} {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      grid-column: #{column-index(0)} / #{column-index(13, 1)};
      overflow-x: auto;
    }
  }
}
