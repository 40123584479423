.wishlist-print--shared {
  .cmp-product-shared-wishlist {
    @media print {
      padding-top: spacing(9);
    }

    &__header {
      display: none;
      width: spacing(37);
      height: spacing(12);
      margin-top: spacing(5);

      .cmp-product-wishlist__title {
        margin-top: spacing(5);
      }
      @media print {
        display: flex;
        flex-flow: column-reverse;
      }
    }
  }
}
