.cmp-product-intro {
  &-container {
    display: flex;
    padding-top: spacing(20);
    padding-bottom: spacing(30);
    @media #{$mq-12} {
      flex-direction: column;
      padding-top: spacing(10);
      padding-bottom: spacing(20);
    }
  }

  &-filters-price {
    @media #{$mq-12} {
      margin-top: spacing(16);
    }
  }
}
