.cmp-product-carousel {
  position: relative;

  @media #{$mq-3} {
    width: grid-spacing(3, 6, 5);
  }
  @media #{$mq-4} {
    width: grid-spacing(4, 6, 5);
  }
  @media #{$mq-5} {
    width: grid-spacing(5, 6, 5);
  }
  @media #{$mq-345} {
    display: grid;
  }
  @media #{$mq-12} {
  }

  &__thumbnail {
    border: solid 2px transparent;
    aspect-ratio: 1;
    cursor: pointer;

    @media #{$mq-1} {
      width: grid-spacing(1, 2, 1);
      height: grid-spacing(1, 2, 1);
      margin-right: grid-spacing(2, 0, 1);
    }

    @media #{$mq-2} {
      width: grid-spacing(2, 2, 1);
      height: grid-spacing(2, 2, 1);
      margin-right: grid-spacing(2, 0, 1);
    }

    @media #{$mq-3} {
      width: grid-spacing(3, 1, 0);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 1, 0);
    }
    @media #{$mq-5} {
      width: grid-spacing(5, 1, 0);
    }

    &--is-selected {
      border-color: var(--product-details-page-carousel-selected-thumbnail-border-color);
    }

    &-layout {
      position: relative;
      grid-column: 1;

      @media #{$mq-12} {
        max-width: 100%;
        margin-bottom: spacing(2);
        grid-row: 2;
      }
      @media #{$mq-1} {
        width: grid-spacing(1, 12, 11);
      }
      @media #{$mq-2} {
        width: grid-spacing(2, 12, 11);
      }
      @media #{$mq-345} {
        height: 100%;
        grid-row: 1;
      }
      @media #{$mq-3} {
        width: grid-spacing(3, 1, 0);
        max-height: grid-spacing(3, 5, 4);
        margin-right: grid-spacing(3, 0, 1);
      }
      @media #{$mq-4} {
        width: grid-spacing(4, 1, 0);
        max-height: grid-spacing(4, 5, 4);
        margin-right: grid-spacing(4, 0, 1);
      }
      @media #{$mq-5} {
        width: grid-spacing(5, 1, 0);
        max-height: grid-spacing(5, 5, 4);
        margin-right: grid-spacing(5, 0, 1);
      }

      &--is-in-modal {
        width: auto;
        @media #{$mq-1} {
          margin-left: var(--page-container-side-margin-s);
        }
        @media #{$mq-2} {
          margin-left: var(--page-container-side-margin-m);
        }
        @media #{$mq-3} {
          height: grid-spacing(3, 5, 4);
          margin-left: var(--page-container-side-margin-l);
        }
        @media #{$mq-4} {
          height: grid-spacing(4, 5, 4);
          margin-left: var(--page-container-side-margin-xl);
        }
        @media #{$mq-5} {
          height: grid-spacing(5, 5, 4);
          margin-left: var(--page-container-side-margin-x-large);
        }
      }
    }

    &-container {
      display: flex;
      -ms-overflow-style: none;
      scrollbar-width: none;

      @media #{$mq-12} {
        width: 100%;
        max-width: 100%;
        column-gap: 0;
        overflow-x: auto;
        overflow-y: hidden;
      }
      @media #{$mq-345} {
        height: 100%;
        max-height: 100%;
        flex-direction: column;
        overflow-y: auto;
        row-gap: spacing(6);
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__scroller {
    @include button(
      var(--product-details-page-carousel-scroller-button-background-color),
      var(--product-details-page-carousel-scroller-button-text-color),
      var(--product-details-page-carousel-scroller-button-border-color),
      var(--product-details-page-carousel-scroller-button-hover-background-color),
      var(--product-details-page-carousel-scroller-button-hover-text-color),
      var(--product-details-page-carousel-scroller-button-hover-border-color)
    );
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: inherit;
    cursor: pointer;

    @media #{$mq-12} {
      bottom: calc($product-hero-overlap-height + 1px);
      margin-top: spacing(3, true);
      @include remove-side-margins;

      &--top {
        left: 0;

        & > :first-child {
          display: none;
        }
      }

      &--bottom {
        right: 0;

        & > :first-child {
          display: none;
        }
      }
    }
    @media #{$mq-1} {
      width: var(--page-container-side-margin-s);
      height: var(--page-container-side-margin-s);
    }
    @media #{$mq-2} {
      width: var(--page-container-side-margin-m);
      height: var(--page-container-side-margin-m);
    }
    @media #{$mq-345} {
      left: 50%;
      width: spacing(12);
      height: spacing(12);
      margin-left: spacing(6, true);
      @include paragraph-lead-l;

      &--top {
        top: 0;

        & > :last-child {
          display: none;
        }
      }

      &--bottom {
        bottom: 0;

        & > :last-child {
          display: none;
        }
      }
    }
  }

  &__selected-image {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
    object-fit: contain;
    object-position: center;
    opacity: 0;
    transition: 0.2s all ease-out;

    &--is-selected {
      opacity: 1;
    }

    &-layout {
      position: relative;
      aspect-ratio: 1;
      background-color: var(--primary-frk-white);

      @media #{$mq-12} {
        width: 100%;
        grid-column: 2;
        grid-column: 1;
      }
      @media #{$mq-345} {
        height: 100%;
        grid-column: 2;
        grid-row: 1;
      }
      @media #{$mq-3} {
        width: grid-spacing(3, 5, 4);
      }
      @media #{$mq-4} {
        width: grid-spacing(4, 5, 4);
      }
      @media #{$mq-5} {
        width: grid-spacing(5, 5, 4);
      }
    }

    &-container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: spacing(10);
    }
  }

  &__number-slides {
    position: absolute;
    padding-top: spacing(2);
    color: var(--primary-frk-white);
    @include paragraph-s-bold;
  }

  &__zoom-mode-button {
    width: 100%;
    height: 100%;
    border: 0;
    background: none;

    &:hover,
    &:focus {
      background: none;
    }
  }

  &__zoom {
    @include button(
      transparent,
      var(--product-details-page-zoom-icon-color),
      transparent,
      transparent,
      var(--product-details-page-zoom-icon-color),
      transparent
    );
    position: absolute;
    right: spacing(2);
    bottom: spacing(-1);
    padding: 0;
    cursor: pointer;
    font-size: spacing(6) !important;
    font-weight: inherit !important;
  }
}
