.cmp-store-locator__container {
  @media #{$mq-4} {
    padding: 0 grid-spacing(4, 1, 1);
  }
  @media #{$mq-5} {
    padding: 0 grid-spacing(5, 1, 1);
  }

  .cmp-store-locator {
    &__title {
      @include display-l-expanded-bold;
    }

    &__search {
      position: relative;
      display: flex;
      width: fit-content;
      flex-direction: column;
      margin: spacing(20) 0 spacing(16);
      @media #{$mq-123} {
        width: 100%;
        margin: spacing(10) 0 spacing(20) 0;
      }
    }

    &__search-label {
      @include paragraph-s-bold;
      margin-bottom: spacing(2);
    }

    &__search-input {
      width: grid-spacing(5, 3, 2);
      padding: spacing(3) spacing(4);
      @media #{$mq-123} {
        width: 100%;
      }
    }

    &__search-button {
      position: absolute;
      z-index: 2;
      right: spacing(1);
      bottom: 0;
      height: spacing(11);
      border: none;
      background: transparent;
    }

    &__results {
      display: flex;
      justify-content: space-between;
      gap: spacing(20);
      @media #{$mq-123} {
        flex-flow: column;
      }
    }

    &__entity {
      flex: 1 1 0;
    }

    &__entity-title {
      @include heading-m-extended-bold;
      padding-bottom: spacing(4);
      border-bottom: 1px solid var(--secondary-frk-rosemary);
    }

    &__entity-name {
      @include paragraph-lead-m;
      padding: spacing(10) 0 spacing(4) 0;
      @media #{$mq-123} {
        padding: spacing(6) 0 spacing(4) 0;
        @include paragraph-s-bold;
      }
    }

    &__entity-address {
      @include paragraph-s;
      margin-bottom: spacing(4);
    }

    &__entity-contact {
      @include paragraph-s-bold;
      display: flex;
      align-items: center;

      i {
        margin-right: spacing(2);
        font-size: spacing(4);
      }
    }

    &__entity-contact-tel {
      color: var(--primary-frk-black);
      text-decoration: none;
    }

    &__entity-fallbackLink {
      @include link(var(--primary-frk-vibrant-red), false, var(--primary-frk-vibrant-red));
      @include heading-base-extra-bold;
      display: flex;
      margin: spacing(4) 0;
      gap: spacing(2);
      text-decoration: none;

      &:hover,
      &:focus {
        color: var(--gradations-frk-vibrant-red-20);
      }
    }

    &__entity-appointment {
      margin: spacing(3) 0;
      color: var(--primary-frk-vibrant-red);
      @include paragraph-s-bold;
    }
  }
}
