.cmp-product-price-and-buy-container {
  @media #{$mq-5} {
    width: grid-spacing(5, 4, 3);
    margin-left: grid-spacing(5, 1, 1);
  }
  @media #{$mq-4} {
    width: grid-spacing(4, 4, 3);
    margin-left: grid-spacing(4, 1, 1);
  }
  @media #{$mq-3} {
    width: grid-spacing(3, 4, 3);
    margin-left: grid-spacing(3, 1, 1);
  }
  @media #{$mq-345} {
    margin-top: spacing(15);
  }
  @media #{$mq-12} {
    width: 100%;
    margin-top: spacing(6);
  }
}

.cmp-product {
  &__price {
    @include heading-xl-extended-bold;
    margin-bottom: spacing(1);
    @media #{$mq-12} {
      @include heading-m-extended-bold;
    }

    .cmp-product-price-and-buy-container--is-out-of-stock & {
      text-decoration: line-through;
    }
  }

  &__vat {
    @include caption;
  }

  &__buy {
    @include button(
      var(--product-details-page-buy-button-background-color),
      var(--product-details-page-buy-button-text-color),
      var(--product-details-page-buy-button-border-color),
      var(--product-details-page-buy-button-hover-background-color),
      var(--product-details-page-buy-button-hover-text-color),
      var(--product-details-page-buy-button-hover-border-color)
    );
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: spacing(6);
    cursor: pointer;
    text-decoration: none;
  }

  &__buy-icon {
    font-size: spacing(6);
  }

  &__buy-label {
    @include heading-base-extended-bold;
    padding-left: spacing(3);
  }
}
