.cmp-teaser-slider {
  &__main-item {
    margin-right: 0;
  }

  &__main-image {
    aspect-ratio: 16/9;
    object-fit: cover;
  }

  &__main-content {
    padding: spacing(9) spacing(6) spacing(8) spacing(6);
  }

  &__main-meta {
    display: flex;
    margin-bottom: spacing(5);
  }

  &__main-title {
    @include heading-l-extended-bold;
    margin-bottom: spacing(1);
  }

  &__main-description {
    @include paragraph-lead-m;
    margin-bottom: spacing(4);
  }

  &__main-date {
    @include paragraph-s;
    padding-right: spacing(3);
    border-right: 1px solid var(--teaser-slider-border-color);

    @media #{$mq-1} {
      padding-right: spacing(2);
    }
  }

  &__main-cta {
    @include link(var(--teaser-slider-link-color), true, var(--teaser-slider-link-hover-color));
    @include heading-base-extra-bold;
  }
}
