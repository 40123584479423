.cmp-countdown-page {
  position: relative;

  &__scrolling-arrow {
    position: fixed;
    z-index: 3;
    left: 50%;
    transform: translateX(-50%);

    @media #{$mq-12} {
      right: 0;
      left: inherit;
      padding-right: var(--page-container-side-margin-s);
      padding-left: var(--page-container-side-margin-s);
      transform: none;
    }

    &--is-up {
      top: 0;
      padding-top: spacing(11);
      @media #{$mq-12} {
        top: spacing(26);
        padding-top: 0;
      }
    }

    &--is-down {
      bottom: 0;
      padding-bottom: spacing(6);
    }

    &--is-middle-section {
      @media #{$mq-12} {
        display: none;
      }
    }
  }

  &__timer-headlines {
    position: absolute;
    bottom: spacing(46);
    width: 100%;
  }

  &__teaser {
    position: absolute;
    bottom: 0;
    width: 100%;

    @media #{$mq-345} {
      padding-top: $header-height-desktop;
      padding-bottom: spacing(20);
    }
    @media #{$mq-12} {
      padding-bottom: spacing(11);
    }

    &--is-fixed {
      position: fixed;
      bottom: unset;
      @media #{$mq-3} {
        padding-top: spacing(26);
      }
      @media #{$mq-2} {
        width: calc(100% - grid-spacing(2, 0, 0, 2));
      }
      @media #{$mq-1} {
        width: calc(100% - grid-spacing(1, 0, 0, 2));
      }
    }
  }

  &__sections {
    > .cmp-section:nth-of-type(2) {
      margin: spacing(2) 0;
    }

    .cmp-video__dam-video {
      position: fixed;
      top: 0;
      opacity: 1;
      pointer-events: none;
      transition: opacity 0.2s ease-in-out, top 0.4s ease-in-out;
    }

    &--hide-video {
      .cmp-video__dam-video {
        top: -100%;
        opacity: 0;
      }
    }
  }

  &__placeholder-section {
    width: 0;
    height: 0;
  }
}
