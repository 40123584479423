.cmp-download-filter-list-item {
  &__link {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: spacing(6) 0;
    border-bottom: 1px solid var(--gradations-frk-rosemary-50);
    text-decoration: none;
    @media #{$mq-123} {
      padding: spacing(4) 0;
    }
  }

  &__icon {
    color: var(--primary-frk-vibrant-red);
    font-size: spacing(6);
  }

  &__title {
    color: var(--primary-frk-black);
    @include heading-base-extra-bold;
    word-break: break-word;
  }
}
