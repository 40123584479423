.cmp-text {
  @media #{$mq-345} {
    @include paragraph-base;
  }

  @media #{$mq-12} {
    @include paragraph-s;
  }

  h1,
  h2,
  h3,
  p {
    margin-bottom: spacing(4);
  }

  b {
    @include heading-base-extra-bold;
  }

  a {
    @include link(var(--text-link-color), false);
  }

  ul {
    @include bullets(var(--bullet-color-in-text), 'square', true);

    li {
      position: relative;
      padding-left: spacing(8);
      @media #{$mq-345} {
        @include paragraph-base;
        margin-bottom: spacing(4);
      }

      @media #{$mq-12} {
        @include paragraph-s;
        margin-bottom: spacing(3);
      }

      &::before {
        position: absolute;
        top: spacing(1);
        left: 0;
      }
    }
  }
}

.cmp-text__heading--large {
  @include display-l-expanded-bold;
}

.cmp-text__heading--medium {
  @media #{$mq-345} {
    @include heading-xxl-extended-bold;
  }
  @media #{$mq-12} {
    @include heading-m-extended-bold;
  }
}

.cmp-text__heading--small {
  @media #{$mq-345} {
    @include heading-l-extended-bold;
  }
  @media #{$mq-12} {
    @include heading-base-extended-bold;
  }
}

.cmp-text__paragraph--lead {
  @include paragraph-lead-m;

  &-large {
    @include paragraph-lead-xl;
    @media #{$mq-123} {
      @include paragraph-lead-l;
    }
  }
}
