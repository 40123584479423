.cmp-navigation-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  height: 100vh;
  pointer-events: none;

  @media #{$mq-4} {
    width: grid-spacing(4, 6, 5, 1);
  }

  @media #{$mq-5} {
    width: grid-spacing(5, 6, 5, 1);
  }

  &--is-opened {
    pointer-events: all;

    @media #{$mq-123} {
      left: 0;
      margin-top: $header-height-mobile;
    }

    .cmp-navigation-wrapper__inner {
      opacity: 1;
      transform: translateX(0);
      @media #{$mq-45} {
        border-right: spacing(5) solid var(--navigation-wrapper-border-color);
      }
    }
  }

  &__inner {
    position: relative;
    top: 0;
    right: 0;
    display: grid;
    width: 100%;
    height: 100vh;
    align-items: center;
    background-color: var(--primary-frk-white);
    color: var(--primary-frk-black);
    opacity: 0;
    transform: translateX(calc(100% - spacing(5)));
    @media #{$mq-123} {
      grid-template-rows: 1fr spacing(28.5);
      transition: transform 0.3s ease-in-out, left 0.3s ease-in-out;
    }
    @media #{$mq-3} {
      padding-bottom: spacing(8);
    }
    @media #{$mq-45} {
      padding-bottom: spacing(10);
      grid-template-rows: 1fr spacing(22);
      transition: transform 0.3s ease-in-out, opacity 0.6s ease-in-out, left 0.3s ease-in-out;
    }
  }
}
