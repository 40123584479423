.cmp-partner-finder {
  @include remove-side-margins;
  position: relative;
  height: 100vh;

  &__map {
    width: 100%;
    height: 100vh;

    .gm-style-iw {
      background-color: var(--partner-finder-accent-color);
    }

    .gm-style-iw-tc::after {
      background-color: var(--partner-finder-accent-color);
    }

    .gm-style .gm-style-iw-c {
      padding: 0;
      border-radius: 0;
      box-shadow: 0 0 16px 0 rgb(1 1 1 / 40%);
      color: var(--partner-finder-accent-text-color);
    }

    .gm-ui-hover-effect[type='button'] {
      top: 0 !important;
      right: 0 !important;

      > span {
        background-color: var(--partner-finder-accent-text-color);
      }
    }

    .gm-style-iw-d {
      overflow: auto !important;
    }

    &--has-premium-partner-open {
      .gm-style-iw {
        background-color: var(--partner-finder-premium-accent-color);
        color: var(--partner-finder-premium-accent-text-color);
      }

      .gm-style-iw-tc::after {
        background-color: var(--partner-finder-premium-accent-color);
      }

      .gm-style .gm-style-iw-c {
        color: var(--partner-finder-premium-accent-text-color);
      }

      .gm-ui-hover-effect[type='button'] > span {
        background-color: var(--partner-finder-premium-accent-text-color);
      }
    }

    &--is-standalone {
      @media #{$mq-12} {
        height: $header-open-height-mobile;
        margin-top: $header-height-mobile;
      }

      @media #{$mq-345} {
        height: $header-open-height-desktop;
        margin-top: $header-height-desktop;
      }
    }
  }

  &__filter-section {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: spacing(4) spacing(8);
    backdrop-filter: blur(spacing(4));
    background-color: var(--transparency-frk-white-50);
    gap: spacing(4);
    overflow-y: hidden;
    transition: max-height ease-out 0.2s;

    @media #{$mq-1} {
      top: 0;
      right: 0;
      left: 0;
      max-height: spacing(33);
    }
    @media #{$mq-2} {
      top: 0;
      right: 0;
      left: 0;
      max-height: spacing(33);
    }
    @media #{$mq-3} {
      top: spacing(4);
      left: grid-spacing(3, 0, 0, 1);
      width: grid-spacing(3, 5, 4);
    }
    @media #{$mq-4} {
      top: spacing(4);
      left: grid-spacing(4, 0, 0, 1);
      width: grid-spacing(4, 5, 4);
    }
    @media #{$mq-5} {
      top: spacing(4);
      left: grid-spacing(5, 0, 0, 1);
      width: grid-spacing(5, 5, 4);
    }

    // setting max-height and padding to 1px in order to prevent the backdrop-filter disappearing on ios devices after the partner-finder-section is visible again on the page
    &--has-partner-open {
      @media #{$mq-12} {
        max-height: 1px;
        padding-top: 1px;
        padding-bottom: 0;
      }
    }
  }

  &__category-list {
    display: flex;
    gap: spacing(2);

    @media #{$mq-12} {
      padding-bottom: spacing(2);
      overflow-x: auto;
    }

    @media #{$mq-345} {
      flex-wrap: wrap;
    }
  }

  .cmp-partner-finder-info-window {
    @include paragraph-s;

    &__title {
      @include paragraph-s-bold;
      padding-right: spacing(4);
    }

    &__header {
      padding: spacing(2) spacing(2) spacing(2) spacing(4);
      border-bottom: solid 1px var(--partner-finder-accent-text-color);
    }

    &__body {
      display: flex;
      flex-direction: column;
      padding: spacing(4) spacing(4) spacing(6);
      row-gap: spacing(4);
    }

    &__distance {
      @include pre-title-s;
      margin-bottom: spacing(4);
    }

    &__category {
      width: fit-content;
      padding: spacing(1) spacing(2);
      border: solid 1px var(--partner-finder-accent-text-color);
      border-radius: spacing(25);
    }

    &__link {
      @include link(var(--partner-finder-accent-text-color), false, var(--partner-finder-accent-text-color));
      @include heading-base-extended-bold;
    }

    &__icon {
      margin-right: spacing(2);
      vertical-align: middle;
    }

    &__google-link {
      @include link(var(--partner-finder-accent-text-color), true, var(--partner-finder-accent-text-color));
      @include heading-base-extended-bold;
      margin-top: spacing(3);
    }

    &__section {
      display: flex;
      flex-direction: column;
    }
  }

  &__cluster:not(:empty) {
    display: flex;
    width: 34px !important;
    height: 34px !important;
    align-items: center;
    justify-content: center;
    background-color: var(--partner-finder-premium-accent-color);
    color: var(--partner-finder-premium-accent-text-color) !important;

    > img {
      display: none;
    }

    > div {
      @include caption-bold(true);
      position: static !important;
      color: var(--store-locator-cluster-div-color) !important;
    }
  }
}
