$black-border: solid $form-border-width var(--product-explorer-multi-select-filter-black-border);

.cmp-multi-select-filter__trigger {
  position: relative;
  display: flex;
  min-width: fit-content;
  box-sizing: border-box;
  padding: spacing(3) spacing(4);
  border: $black-border;
  background: none;
  background-color: var(--product-explorer-multi-select-filter-trigger-background-color);
  color: var(--product-explorer-multi-select-filter-trigger-color);
  cursor: pointer;
  @include paragraph-s;

  &--is-open {
    outline: $black-border;
  }

  &--product-variants-selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: spacing(6);

    .cmp-multi-select-filter__counter {
      display: none;
    }

    .cmp-multi-select-filter__trigger-arrow {
      display: block;
    }

    @media #{$mq-12} {
      width: 100%;
    }

    @media #{$mq-3} {
      width: grid-spacing(3, 4, 3);
    }

    @media #{$mq-4} {
      width: grid-spacing(4, 3, 2);
    }

    @media #{$mq-5} {
      width: grid-spacing(5, 3, 2);
    }
  }
}

.cmp-multi-select-filter__trigger-arrow {
  margin-left: spacing(3);

  @media #{$mq-12} {
    display: none;
  }

  [class^='ri-'] {
    vertical-align: bottom;
  }
}

.cmp-multi-select-filter__popover {
  display: flex;
  flex-direction: column;
  background: var(--product-explorer-multi-select-filter-popover-background-color);

  @media #{$mq-12} {
    position: fixed;
    z-index: 4;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    overflow: auto;
    flex-direction: column;
  }

  @media #{$mq-345} {
    min-width: spacing(80);
    border: $black-border;
    outline: $black-border;

    &--above-button {
      border-bottom: none;
    }
  }

  .cmp-multi-select-filter__counter {
    margin-left: spacing(5);
  }

  &--product-variants-selector {
    @media #{$mq-12} {
      width: 100%;
    }

    @media #{$mq-3} {
      width: grid-spacing(3, 5, 4);
    }

    @media #{$mq-4} {
      width: grid-spacing(4, 3, 2);
    }

    @media #{$mq-5} {
      width: grid-spacing(5, 3, 2);
    }

    .cmp-multi-select-filter__counter {
      display: none;
    }
  }
}

.cmp-multi-select-filter__popover-border-adjustment {
  position: absolute;
  left: -1px;
  display: block;
  height: 8px;
  border: $black-border;
  background: var(--product-explorer-multi-select-filter-popover-border-adjustment-color);
  outline: $black-border;

  &--above {
    bottom: 0;
    margin-bottom: -10px;
  }

  &--below {
    top: 0;
    margin-top: -9px;
  }

  &::after {
    position: absolute;
    top: -2px;
    right: 0;
    bottom: -2px;
    left: 0;
    background-color: var(--product-explorer-multi-select-filter-popover-border-adjustment-color);
    content: '';
  }
}

.cmp-multi-select-filter__counter {
  display: inline-flex;
  width: spacing(5);
  height: spacing(5);
  align-items: center;
  justify-content: center;
  padding: spacing(0.5) spacing(1);
  margin-left: spacing(3);
  background-color: var(--product-explorer-multi-select-filter-counter-background-color);
  color: var(--product-explorer-multi-select-filter-counter-color);
  opacity: 0;

  &--is-visible {
    opacity: 1;
  }
}
