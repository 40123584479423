.cmp-no-results {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: spacing(3) 0;
  background-color: var(--grayscale-frk-black-5);
  border-radius: spacing(2);

  &--is-error {
    background-color: var(--system-frk-error);
  }

  &--has-spacing {
    margin: spacing(8) 0;
  }
}

.cmp-no-results__content {
  flex-grow: 1;
}

.cmp-no-results__title {
  @include paragraph-base-bold;
}

.cmp-no-results__description {
  @include paragraph-s;
}

.cmp-no-results__icon {
  @include paragraph-base-bold;
  padding: spacing(4);

  &.ri-information-line,
  &.ri-close-circle-line {
    font-size: 26px;
  }
}
