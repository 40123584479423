.react-component-sample {
  @include with-max-width-center;
  @include with-left-and-right-spacing;
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
  background-color: fuchsia;
  color: white;
}
