.cmp-location-search-input-field {
  position: relative;
  display: flex;
  width: 100%;
  border: solid 2px var(--primary-frk-black);
  background: none;
  color: var(--primary-frk-black);
  @include paragraph-base;

  &--has-error,
  &--has-error ::placeholder {
    border-color: var(--system-frk-error);
    color: var(--system-frk-error);
  }

  &:focus-within {
    outline: solid 1px;
  }

  &__autocomplete-datalist {
    // To Be Designed
  }

  &__autocomplete-option {
    // To Be Designed
  }
}

.cmp-location-search-input-field__label {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: calc(spacing(7) * -1);
  @include paragraph-s-bold;
}

.cmp-location-search-input-field__icon {
  padding: spacing(2) spacing(2);
  cursor: pointer;

  @media #{$mq-3} {
    padding: spacing(2) spacing(1);
  }

  &:last-child {
    margin-right: spacing(2);
  }
}

.cmp-location-search-input-field__input {
  width: 100%;
  flex-grow: 1;
  padding: spacing(2) spacing(3);
  border: none;
  background: none;

  .cmp-location-search-input-field:not(.cmp-location-search-input-field--has-error) &:placeholder-shown {
    color: var(--grayscale-frk-cool-grey-8);

    & ~ .ri-arrow-right-line {
      color: var(--grayscale-frk-cool-grey-8);
      cursor: not-allowed;
    }
  }

  &::placeholder {
    color: var(--primary-frk-black);
    opacity: 1;
  }

  &::-webkit-list-button,
  &::-webkit-calendar-picker-indicator {
    display: none !important;
    opacity: 0 !important;
  }
}
