.cmp-logo {
  position: relative;
  z-index: 1;
  @media #{$mq-12} {
    width: $logo-width-mobile;
    height: $logo-height-mobile;
  }
  @media #{$mq-345} {
    width: $logo-width-desktop;
    height: $logo-height-desktop;
  }

  @media print {
    .wishlist-print & {
      width: spacing(37);
      height: spacing(12);
    }
  }

  &__svg {
    width: 100%;
    height: 100%;
  }

  &__foreground {
    .logo--white & {
      filter: grayscale(100%) brightness(0) invert(1);
    }
    position: absolute;
    top: 0;
    left: 0;
  }

  &__background {
    filter: grayscale(100%) brightness(0) invert(1);

    .logo--white & {
      filter: none;
    }

    .logo--red & {
      filter: grayscale(100%) brightness(0) invert(1);
    }
  }
}

.cmp-header--is-opened {
  .logo--white & {
    .cmp-logo__foreground {
      filter: none;
    }

    .cmp-logo__background {
      filter: grayscale(100%) brightness(0) invert(1);
    }
  }
}
