.cmp-button-link {
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
  font: inherit;

  /* TODO: create a mixin as this will be reused */
  &__icon {
    @media #{$mq-3},
    #{$mq-345} {
      margin-right: spacing(3);
      font-size: $icon-width-medium;
      line-height: $icon-height-medium;
    }

    @media #{$mq-12} {
      font-size: $icon-width-small;
      line-height: $icon-height-small;
    }
  }

  &__menu-item-content--has-spacing {
    margin-right: spacing(3);
    margin-left: spacing(3);
  }
}
