.cmp-storytelling-teaser {
  @include with-max-width-center;
  @include grid-layout;

  .cmp-storytelling-teaser__image-spacer {
    aspect-ratio: 720 / 556;
    background-color: var(--storytelling-teaser-background);
    grid-column: #{column-index(0)} / #{column-index(6, 1, 1)};
  }

  .cmp-storytelling-teaser__image-spacer-top {
    @media #{$mq-123} {
      grid-row: 3 / 4;
    }
    @media #{$mq-45} {
      grid-row: 2 / 3;
    }
  }

  .cmp-storytelling-teaser__background-top {
    background-color: var(--storytelling-teaser-background);
    grid-column: #{column-index(0)} / #{column-index(13)};
    grid-row: 1 / 2;
    @media #{$mq-45} {
      height: spacing(20);
    }
    @media #{$mq-123} {
      height: spacing(12);
    }
  }

  .cmp-storytelling-teaser__title-box {
    background-color: var(--storytelling-teaser-background);
    @media #{$mq-123} {
      grid-column: #{column-index(0)} / #{column-index(13)};
      grid-row: 11;
    }

    @media #{$mq-45} {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 spacing(32) 0 spacing(12);
      grid-column: #{column-index(6, 1, 1)} / #{column-index(13)};
      grid-row: 9 / 11;
    }

    @media #{$mq-1} {
      padding: spacing(6) grid-spacing(1, 1);
    }

    @media #{$mq-2} {
      padding: spacing(6) grid-spacing(2, 1);
    }

    @media #{$mq-3} {
      padding: spacing(6) grid-spacing(3, 1);
    }
  }

  .cmp-storytelling-teaser__title-box-top {
    @media #{$mq-123} {
      grid-row: 2 / 3;
    }

    @media #{$mq-45} {
      grid-column: #{column-index(0)} / #{column-index(6, 1, 1)};
      grid-row: 2 / 3;
    }
  }

  .cmp-storytelling-teaser__subtitle-box {
    grid-column: #{column-index(6, 1, 1)} / #{column-index(13)};

    @media #{$mq-123} {
      grid-column: #{column-index(0)} / #{column-index(13)};
      grid-row: 8;
    }

    @media #{$mq-45} {
      padding: spacing(32) spacing(12) 0 spacing(32);
      grid-row: 4 / 8;
    }

    @media #{$mq-1} {
      padding: spacing(6) grid-spacing(1, 1) spacing(6) grid-spacing(1, 1);
    }

    @media #{$mq-2} {
      padding: spacing(6) grid-spacing(2, 1) spacing(6) grid-spacing(2, 1);
    }

    @media #{$mq-3} {
      padding: spacing(6) grid-spacing(3, 1) spacing(6) grid-spacing(3, 1);
    }
  }

  .cmp-storytelling-teaser__pre-title {
    @include paragraph-base-bold;
    margin-bottom: spacing(6);
    color: var(--storytelling-teaser-pre-title-color);
  }

  .cmp-storytelling-teaser__title {
    margin-bottom: spacing(6);
    color: var(--storytelling-teaser-title-color);
    word-wrap: break-word;
    @include display-l-expanded-bold;

    @media #{$mq-123} {
      margin-bottom: spacing(4);
    }
  }

  .cmp-storytelling-teaser__subtitle {
    margin-bottom: spacing(4);
    color: var(--storytelling-teaser-subtitle-color);

    @media #{$mq-123} {
      @include heading-l-extended-bold;
    }

    @media #{$mq-45} {
      @include heading-xl-extended-bold;
    }
  }

  .cmp-storytelling-teaser__short-description {
    margin-bottom: spacing(4);
    color: var(--storytelling-teaser-description-color);

    @media #{$mq-123} {
      @include paragraph-base;
    }

    @media #{$mq-45} {
      @include paragraph-lead-m;
    }

    &--invert {
      color: var(--storytelling-teaser-description-color-invert);
    }
  }

  .cmp-storytelling-teaser__image {
    aspect-ratio: 1;
    object-fit: cover;
  }

  .cmp-storytelling-teaser__first-image {
    grid-column: #{column-index(6, 1, 1)} / #{column-index(13)};

    @media #{$mq-123} {
      grid-row: 3 / 5;
    }

    @media #{$mq-45} {
      grid-row: 2 / 4;
    }
  }

  .cmp-storytelling-teaser__second-image {
    grid-column: #{column-index(0)} / #{column-index(6, 1, 1)};

    @media #{$mq-123} {
      grid-row: 4 / 7;
    }

    @media #{$mq-45} {
      grid-row: 3 / 6;
    }
  }

  .cmp-storytelling-teaser__color-block-image-spacer {
    aspect-ratio: 2;
    grid-column: #{column-index(4)} / #{column-index(6, 1, 1)};
    object-fit: cover;

    @media #{$mq-123} {
      grid-row: 6 / 7;
    }

    @media #{$mq-45} {
      grid-row: 5 / 6;
    }
  }

  .cmp-storytelling-teaser__color-block-image {
    aspect-ratio: 1;
    background-color: var(--storytelling-teaser-background);
    grid-column: #{column-index(4)} / #{column-index(6, 1, 1)};
    object-fit: cover;

    @media #{$mq-123} {
      grid-row: 6 / 8;
    }

    @media #{$mq-45} {
      grid-row: 5 / 7;
    }
  }

  .cmp-storytelling-teaser__link {
    @include link(var(--storytelling-teaser-link-color), true, var(--storytelling-teaser-link-on-hover-color));
    @include heading-base-extra-bold;
  }

  .cmp-storytelling-teaser__image-spacer-middle {
    background-color: var(--primary-frk-white);
    @media #{$mq-123} {
      grid-column: #{column-index(0)} / #{column-index(6, 1, 1)};
      grid-row: 9;
    }

    @media #{$mq-45} {
      grid-row: 6 / 8;
    }
  }

  .cmp-storytelling-teaser__description-box {
    align-self: center;
    color: var(--storytelling-teaser-description-color);
    grid-column: #{column-index(0)} / #{column-index(6, 1, 1)};
    @media #{$mq-123} {
      @include paragraph-base;
      grid-column: #{column-index(0)} / #{column-index(13)};
      grid-row: 8;
    }

    @media #{$mq-45} {
      @include paragraph-lead-m;
      padding: 0 spacing(32) 0 spacing(12);
      grid-row: 6 / 8;
    }

    @media #{$mq-1} {
      padding: spacing(6) grid-spacing(1, 2) spacing(6) grid-spacing(1, 1);
    }

    @media #{$mq-2} {
      padding: spacing(6) grid-spacing(2, 2) spacing(6) grid-spacing(2, 1);
    }

    @media #{$mq-3} {
      padding: spacing(6) grid-spacing(3, 2) spacing(6) grid-spacing(3, 1);
    }
  }

  .cmp-storytelling-teaser__title-box-bottom {
    @media #{$mq-123} {
      padding-top: spacing(6);
    }
    @media #{$mq-45} {
      padding: 0 spacing(12) 0 spacing(32);
    }
  }

  &--medium,
  &--large {
    .cmp-storytelling-teaser__subtitle-box {
      @media #{$mq-123} {
        grid-row: 7;
      }

      @media #{$mq-45} {
        grid-row: 4 / 6;
      }
    }
  }

  &--medium {
    .cmp-storytelling-teaser__title-box-bottom {
      @media #{$mq-123} {
        grid-row: 9;
      }

      @media #{$mq-45} {
        grid-row: 6/8;
      }
    }

    .cmp-storytelling-teaser__description-box {
      @media #{$mq-123} {
        grid-column: #{column-index(0)} / #{column-index(12, 1, 1)};
        grid-row: 8;
      }
    }

    .cmp-storytelling-teaser__background-bottom {
      background-color: var(--storytelling-teaser-background);
      grid-column: #{column-index(0)} / #{column-index(13)};
      grid-row: 8;
      @media #{$mq-45} {
        height: spacing(20);
      }
      @media #{$mq-123} {
        width: spacing(8);
        height: 100%;
        margin-left: auto;
      }
    }
  }

  &--large {
    .cmp-storytelling-teaser__third-image {
      grid-column: #{column-index(6, 1, 1)} / #{column-index(13)};

      @media #{$mq-123} {
        grid-row: 9 / 11;
      }

      @media #{$mq-45} {
        grid-row: 6 / 9;
      }
    }

    .cmp-storytelling-teaser__fourth-image {
      grid-column: #{column-index(0)} / #{column-index(6, 1, 1)};

      @media #{$mq-123} {
        grid-row: 10;
      }

      @media #{$mq-45} {
        grid-row: 8 / 10;
      }
    }

    .cmp-storytelling-teaser__image-spacer-bottom {
      background-color: var(--storytelling-teaser-background);
      grid-column: #{column-index(6, 1, 1)} / #{column-index(13)};

      @media #{$mq-123} {
        aspect-ratio: auto;
        grid-row: 10 / 12;
      }

      @media #{$mq-45} {
        grid-row: 9 / 10;
      }
    }

    .cmp-storytelling-teaser__title-box-bottom {
      @media #{$mq-123} {
        grid-row: 11;
      }

      @media #{$mq-45} {
        grid-row: 9 / 11;
      }
    }

    .cmp-storytelling-teaser__background-bottom {
      background-color: var(--storytelling-teaser-background);
      grid-column: #{column-index(0)} / #{column-index(13)};
      @media #{$mq-45} {
        height: spacing(20);
        grid-row: 10;
      }
      @media #{$mq-123} {
        display: none;
      }
    }
  }

  &--mirrored {
    .cmp-storytelling-teaser__image-spacer {
      @media #{$mq-123} {
        grid-column: 14/27;
      }
    }

    .cmp-storytelling-teaser__title-box {
      background-color: var(--storytelling-teaser-background);

      @media #{$mq-123} {
        grid-column: #{column-index(13)} / #{column-index(0)};
      }

      @media #{$mq-45} {
        padding: 0 spacing(12) 0 spacing(32);
        grid-column: #{column-index(6, 1, 1)} / #{column-index(13)};
      }
    }

    .cmp-storytelling-teaser__title-box-bottom {
      background-color: var(--storytelling-teaser-background);
      @media #{$mq-123} {
        grid-column: #{column-index(13)} / #{column-index(0)};
      }

      @media #{$mq-45} {
        padding: 0 spacing(32) 0 spacing(12);
        grid-column: #{column-index(0)} / #{column-index(6, 1, 1)};
      }
    }

    .cmp-storytelling-teaser__subtitle-box {
      @media #{$mq-123} {
        grid-column: #{column-index(13)} / #{column-index(0)};
      }
      @media #{$mq-45} {
        padding: spacing(32) spacing(32) 0 spacing(12);
        grid-column: #{column-index(0)} / #{column-index(6, 1, 1)};
      }
    }

    .cmp-storytelling-teaser__first-image {
      grid-column: #{column-index(0)} / #{column-index(6, 1, 1)};
    }

    .cmp-storytelling-teaser__second-image {
      grid-column: #{column-index(6, 1, 1)} / #{column-index(13)};
    }

    .cmp-storytelling-teaser__color-block-image {
      grid-column: #{column-index(6, 1, 1)} / #{column-index(9, 1)};
    }

    .cmp-storytelling-teaser__third-image {
      grid-column: #{column-index(0)} / #{column-index(6, 1, 1)};
    }

    .cmp-storytelling-teaser__fourth-image {
      grid-column: #{column-index(6, 1, 1)} / #{column-index(13)};
    }

    .cmp-storytelling-teaser__description-box {
      @media #{$mq-45} {
        padding: 0 spacing(12) 0 spacing(32);
        grid-column: #{column-index(6, 1, 1)} / #{column-index(13)};
      }
    }

    .cmp-storytelling-teaser__image-spacer-bottom {
      @media #{$mq-123} {
        grid-column: #{column-index(13)} / #{column-index(0)};
      }
    }
  }
}
