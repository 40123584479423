.cmp-footer--is-mini {
  .cmp-footer {
    &__navigation {
      margin-bottom: 0;

      &--column {
        margin-bottom: spacing(14);
        margin-left: 0;
        @media #{$mq-5} {
          width: grid-spacing(5, 10, 9);
          margin-left: grid-spacing(5, 1, 1);
        }
        @media #{$mq-4} {
          width: grid-spacing(4, 10, 9);
          margin-left: grid-spacing(4, 1, 1);
        }
        @media #{$mq-3} {
          width: grid-spacing(3, 10, 9);
          margin-left: grid-spacing(3, 1, 1);
        }

        .cmp-list {
          text-align: center;

          &__item {
            display: inline-block;
            padding-right: spacing(6);
            @media #{$mq-3} {
              padding-bottom: spacing(2);
            }
          }
        }

        .cmp-title__text {
          padding-bottom: spacing(3);
          text-align: center;
        }

        @media #{$mq-12} {
          width: 100%;
        }
      }
    }
  }
}
