.cmp-product-hero {
  &__wishlist {
    display: flex;
    height: spacing(20);
    justify-content: end;
    padding: spacing(6) spacing(6) spacing(5) 0;

    &--button {
      display: flex;
      border: none;
      background: none;
      color: var(--primary-frk-white);
      font-size: spacing(6);

      &:hover,
      &:focus {
        cursor: pointer;
      }
    }

    &--icon {
      transform: scale(1);
      transition: all 0.3s ease;
    }

    &--filled {
      transform: scale(1.1);
    }

    @media #{$mq-12} {
      padding: spacing(23) 0 0;
    }
  }
}
