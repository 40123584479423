.cmp-video__dam-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .cmp-video--has-mobile-video & {
    @media #{$mq-12} {
      position: relative;
      width: 100%;
      height: auto;
    }
  }
}
