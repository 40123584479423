.cmp-countdown-teaser {
  width: grid-spacing(5, 4, 3);
  margin-left: grid-spacing(5, 1, 1);
  color: var(--countdown-teaser-text-color);
  @media #{$mq-4} {
    width: grid-spacing(4, 4, 3);
    margin-left: grid-spacing(4, 1, 1);
  }
  @media #{$mq-3} {
    width: grid-spacing(3, 7, 6);
    margin-left: grid-spacing(3, 1, 1);
  }
  @media #{$mq-12} {
    width: 100%;
    margin-left: 0;
  }

  &__title {
    @include heading-l-extended-bold;
    margin-bottom: spacing(4);
  }

  &__lead-text {
    @include paragraph-lead-m;
    margin-bottom: spacing(4);
  }

  &__description {
    @include paragraph-base;
    margin-bottom: spacing(10);
  }

  &__button {
    @include button(
      var(--countdown-teaser-button-background-color),
      var(--countdown-teaser-button-text-color),
      var(--countdown-teaser-button-border-color),
      var(--countdown-teaser-button-background-hover-color),
      var(--countdown-teaser-button-text-hover-color),
      var(--countdown-teaser-button-border-hover-color)
    );
  }
}
