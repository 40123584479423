.cmp-form {
  @media #{$mq-3} {
    width: grid-spacing(3, 5, 4);
    margin-left: grid-spacing(3, 2, 2);
  }

  @media #{$mq-4} {
    width: grid-spacing(4, 5, 4);
    margin-left: grid-spacing(4, 2, 2);
  }

  @media #{$mq-5} {
    width: grid-spacing(5, 5, 4);
    margin-left: grid-spacing(5, 2, 2);
  }

  label {
    margin-bottom: spacing(3);
  }

  &-error {
    margin: spacing(5) 0;
    background-color: var(--form-error-background-color);

    &__item {
      @include paragraph-s;
      padding: spacing(3);
      color: var(--form-item-error-text-color);
      text-align: center;
    }
  }
}
