.cmp-entry-teaser {
  position: relative;
  display: grid;
  margin: 0 auto;
  background-color: var(--entry-teaser-background-color);
  @media #{$mq-5} {
    width: grid-spacing(5, 12, 11);
    padding: spacing(30) 0;
    gap: spacing(10);
  }
  @media #{$mq-4} {
    width: grid-spacing(4, 12, 11);
    padding: spacing(30) 0;
    gap: spacing(10);
  }
  @media #{$mq-3} {
    width: grid-spacing(3, 12, 11);
    padding: spacing(14) 0;
    gap: spacing(8);
  }
  @media #{$mq-2} {
    width: grid-spacing(2, 12, 11);
  }
  @media #{$mq-1} {
    width: grid-spacing(1, 12, 11);
  }
  @media #{$mq-12} {
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw;
    box-sizing: border-box;
    padding: spacing(14) 0;
    gap: spacing(8);
    transform: translateX(-50%);
  }

  &__title {
    @include entry-tabs-teasers-title;
    margin: 0 auto;
    color: var(--entry-teaser-text-color);
    text-align: center;
    @media #{$mq-5} {
      width: grid-spacing(5, 6, 5);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 6, 5);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 8, 7);
    }
    @media #{$mq-2} {
      width: grid-spacing(2, 12, 11);
    }
    @media #{$mq-1} {
      width: grid-spacing(1, 12, 11);
    }
  }

  &__image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media #{$mq-345} {
      gap: spacing(6);
    }
    @media #{$mq-2} {
      gap: spacing(4);
    }
    @media #{$mq-1} {
      gap: spacing(8);
    }
  }

  &__image {
    display: flex;
    flex-flow: column;
    align-items: center;
    @media #{$mq-5} {
      width: grid-spacing(5, 4, 3);
      gap: spacing(10);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 4, 3);
      gap: spacing(10);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 5, 4);
      gap: spacing(8);
    }
    @media #{$mq-2} {
      width: grid-spacing(2, 6, 5);
      gap: spacing(8);
    }
    @media #{$mq-1} {
      width: grid-spacing(1, 12, 11);
      gap: spacing(8);
    }

    img {
      aspect-ratio: 4 / 3;
      background-size: cover;
    }

    &-link {
      @include link;
      @include heading-base-extended-bold;
      display: flex;
      justify-content: center;
      color: var(--entry-teaser-text-color);
      gap: spacing(1);
      text-decoration: none;
    }
  }

  &__button {
    @include heading-base-extended-bold;
    display: flex;
    padding: spacing(5) spacing(8);
    border: none;
    margin: 0 auto;
    background: var(--entry-teaser-button-background-color);
    color: var(--entry-teaser-button-text-color);
    text-decoration: none;

    &:hover,
    &:focus {
      background: var(--entry-teaser-button-hover-background-color);
    }
  }
}
