.cmp-video {
  display: flex;
  flex-direction: column;
  color: var(--video-text-color);

  @media #{$mq-3} {
    margin-right: grid-spacing(3, 1, 1);
    margin-left: grid-spacing(3, 1, 1);
  }
  @media #{$mq-4} {
    margin-right: grid-spacing(4, 1, 1);
    margin-left: grid-spacing(4, 1, 1);
  }
  @media #{$mq-5} {
    margin-right: grid-spacing(5, 1, 1);
    margin-left: grid-spacing(5, 1, 1);
  }

  &__container {
    display: flex;

    @media #{$mq-12} {
      flex-direction: column;

      .cmp-video__player {
        width: 100%;
      }
    }
  }

  &--has-mobile-video {
    @media #{$mq-12} {
      width: 100%;
      height: auto;
      aspect-ratio: initial;
    }
  }

  &__player {
    position: relative;
    border: none;
    aspect-ratio: 16 / 9;
    @supports not (aspect-ratio: 16 / 9) {
      padding-top: 56.25%;
    }

    .cmp-video--has-mobile-video & {
      @media #{$mq-12} {
        width: 100%;
        height: auto;
        aspect-ratio: initial;
      }
    }
  }

  &__content {
    padding-top: spacing(8);
    word-wrap: break-word;

    @media #{$mq-1} {
      margin-right: grid-spacing(1, 1, 1);
      margin-left: grid-spacing(1, 1, 1);
    }
    @media #{$mq-2} {
      margin-right: grid-spacing(2, 1, 1);
      margin-left: grid-spacing(2, 1, 1);
    }

    .cmp-video--left & {
      @media #{$mq-3} {
        padding-left: grid-spacing(3, calc(1 - 0.6222));
      }
      @media #{$mq-4} {
        padding-left: grid-spacing(4, calc(1 - 0.6222));
      }
      @media #{$mq-5} {
        padding-left: grid-spacing(5, calc(1 - 0.6222));
      }
    }

    .cmp-video--right & {
      @media #{$mq-3} {
        // this is the percentage of 56px for 1440px width and with column width 90px in Figma
        padding-left: grid-spacing(3, 0.6222);
      }
      @media #{$mq-4} {
        padding-left: grid-spacing(4, 0.6222);
      }
      @media #{$mq-5} {
        padding-left: grid-spacing(5, 0.6222);
      }
    }

    .cmp-video--left &,
    .cmp-video--right & {
      @media #{$mq-3} {
        width: grid-spacing(3, 4, 3);
      }
      @media #{$mq-4} {
        width: grid-spacing(4, 4, 3);
      }
      @media #{$mq-5} {
        width: grid-spacing(5, 4, 3);
      }
    }

    .cmp-video--full & {
      @media #{$mq-3} {
        width: grid-spacing(3, 6, 5);
        margin-left: grid-spacing(3, 1, 1);
      }
      @media #{$mq-4} {
        width: grid-spacing(4, 6, 5);
        margin-left: grid-spacing(4, 1, 1);
      }
      @media #{$mq-5} {
        width: grid-spacing(5, 6, 5);
        margin-left: grid-spacing(5, 1, 1);
      }
    }
  }

  &__headline {
    @include display-l-expanded-bold;

    @media #{$mq-123} {
      margin-bottom: spacing(6);
    }

    @media #{$mq-45} {
      margin-bottom: spacing(14);
    }
  }

  &__title {
    @include heading-m-extended-bold;
    margin-bottom: spacing(1);
  }

  &__description {
    @include paragraph-base;
  }

  &__image {
    object-fit: cover;
  }

  &__cover,
  &__iframe,
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__button {
    z-index: 2;
    display: flex;
    width: spacing(15);
    height: spacing(15);
    align-items: center;
    justify-content: center;
    border: none;
    background-color: var(--video-button-color);
    box-shadow: none;
    cursor: pointer;
  }

  &__cover {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--bleed {
    .cmp-video {
      @media #{$mq-1} {
        @include remove-side-margins;
      }
      @media #{$mq-2} {
        @include remove-side-margins;
      }
      @media #{$mq-3} {
        @include remove-side-margins;
      }
      @media #{$mq-4} {
        @include remove-side-margins;
      }
      @media #{$mq-5} {
        margin-right: 0;
        margin-left: 0;
      }

      &__container {
        flex-flow: column;
      }

      &__headline {
        width: auto;
        @media #{$mq-1} {
          margin-left: grid-spacing(1, 1, 1);
        }
        @media #{$mq-2} {
          margin-left: grid-spacing(2, 1, 1);
        }
        @media #{$mq-3} {
          margin-left: grid-spacing(3, 1, 1);
        }
        @media #{$mq-4} {
          margin-left: grid-spacing(4, 1, 1);
        }
        @media #{$mq-5} {
          margin-left: grid-spacing(5, 1, 1);
        }
      }

      &__content {
        width: auto;
        @media #{$mq-1} {
          margin-left: grid-spacing(1, 1, 1);
        }
        @media #{$mq-2} {
          margin-left: grid-spacing(2, 1, 1);
        }
        @media #{$mq-3} {
          margin-left: grid-spacing(3, 2, 1);
        }
        @media #{$mq-4} {
          margin-left: grid-spacing(4, 2, 1);
        }
        @media #{$mq-5} {
          margin-left: grid-spacing(5, 2, 1);
        }
      }
    }
  }
}

@media #{$mq-345} {
  .cmp-video__player {
    width: 100%;
  }

  .cmp-video--right {
    .cmp-video__content {
      @media #{$mq-3} {
        margin-right: grid-spacing(3, 0, 1);
      }
      @media #{$mq-4} {
        margin-right: grid-spacing(4, 0, 1);
      }
      @media #{$mq-5} {
        margin-right: grid-spacing(5, 0, 1);
      }
    }

    .cmp-video__container {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }

  .cmp-video--left {
    .cmp-video__content {
      @media #{$mq-3} {
        margin-left: grid-spacing(3, 0, 1);
      }
      @media #{$mq-4} {
        margin-left: grid-spacing(4, 0, 1);
      }
      @media #{$mq-5} {
        margin-left: grid-spacing(5, 0, 1);
      }
    }

    .cmp-video__container {
      flex-direction: row;
    }
  }

  .cmp-video--full {
    .cmp-video__container {
      flex-direction: column;
    }
  }

  .cmp-video--right,
  .cmp-video--left {
    .cmp-video__player {
      @media #{$mq-3} {
        width: grid-spacing(3, 6, 5);
      }
      @media #{$mq-4} {
        width: grid-spacing(4, 6, 5);
      }
      @media #{$mq-5} {
        width: grid-spacing(5, 6, 5);
      }
    }

    .cmp-video__content {
      overflow-wrap: break-word;
    }
  }
}
