.industries-theme {
  // Accordion
  --accordion-item-border-color: var(--gradations-frk-rosemary-50);
  --accordion-button-text-color: var(--primary-frk-black);
  --accordion-button-border-color: transparent;
  --accordion-button-background-color: transparent;
  --accordion-icon-color: var(--primary-frk-vibrant-red);
  --accordion-button-title-text-color: var(--primary-frk-vibrant-red);

  // Basic hero
  --basic-hero-background-color: var(--grayscale-frk-cool-gray-11);
  --basic-hero-text-color: var(--primary-frk-white);
  --basic-hero-title-color: var(--primary-frk-white);

  // Breadcrumbs
  --breadcrumbs-background-color: var(--grayscale-frk-cool-gray-11);
  --breadcrumbs-home-icon-color: var(--primary-frk-white);
  --breadcrumbs-icon-color: var(--transparency-frk-white-50-trans);
  --breadcrumbs-text-color: var(--primary-frk-white);

  // Bullet
  --bullet-color: var(--primary-frk-vibrant-red);

  // Contact
  --contact-cta-first-card-text-color: var(--primary-frk-vibrant-red);
  --contact-cta-second-card-text-color: var(--primary-frk-vibrant-red);
  --contact-first-card-background-color: var(--grayscale-frk-black-5);
  --contact-first-cta-button-background-color: var(--primary-frk-vibrant-red);
  --contact-first-cta-button-border-color: var(--primary-frk-vibrant-red);
  --contact-first-cta-button-font-color: var(--primary-frk-white);
  --contact-first-cta-button-on-hover-focus-background-color: var(--gradations-frk-vibrant-red-20);
  --contact-first-cta-button-on-hover-focus-border-color: var(--gradations-frk-vibrant-red-20);
  --contact-first-cta-button-on-hover-focus-font-color: var(--primary-frk-white);
  --contact-header-color: var(--primary-frk-vibrant-red);
  --contact-second-card-background-color-default: var(--grayscale-frk-black-5);
  --contact-second-cta-button-background-color: var(--primary-frk-vibrant-red);
  --contact-second-cta-button-border-color: var(--primary-frk-vibrant-red);
  --contact-second-cta-button-font-color: var(--primary-frk-white);
  --contact-second-cta-button-on-hover-focus-background-color: var(--gradations-frk-vibrant-red-20);
  --contact-second-cta-button-on-hover-focus-border-color: var(--gradations-frk-vibrant-red-20);
  --contact-second-cta-button-on-hover-focus-font-color: var(--primary-frk-white);

  // Card teaser
  --card-teaser-background-color: var(--grayscale-frk-black-5);
  --card-teaser-button-background-color: var(--primary-frk-vibrant-red);
  --card-teaser-button-border-color: var(--primary-frk-vibrant-red);
  --card-teaser-button-font-color: var(--primary-frk-white);
  --card-teaser-button-on-hover-focus-background-color: var(--gradations-frk-vibrant-red-20);
  --card-teaser-button-on-hover-focus-border-color: var(--gradations-frk-vibrant-red-20);
  --card-teaser-button-on-hover-focus-font-color: var(--primary-frk-white);
  --card-teaser-description-font-color: var(--primary-frk-black);
  --card-teaser-pretitle-font-color: var(--primary-frk-black);
  --card-teaser-title-font-color: var(--primary-frk-black);

  // Download list
  --download-list-item-border-color: var(--gradations-frk-rosemary-50);
  --download-list-item-label-link-color: var(--primary-frk-vibrant-red);
  --download-list-item-languages-background-color: var(--primary-frk-white);
  --download-list-item-languages-link-color: var(--primary-frk-black);
  --download-list-item-languages-link-hover-color: var(--gradations-frk-champagne-50);
  --download-list-item-languages-link-border-color: var(--gradations-frk-rosemary-50);
  --download-list-item-languages-link-icon-color: var(--primary-frk-vibrant-red);
  --download-list-item-languages-link-hover-icon-color: var(--primary-frk-black);
  --download-list-item-label-hover-color: var(--primary-frk-black);
  --download-list-item-label-hover-icon-color: var(--primary-frk-black);
  --download-list-item-action-label-color: var(--primary-frk-vibrant-red);
  --download-list-item-action-label-icon-color: var(--primary-frk-vibrant-red);
  --download-list-item-action-label-hover-icon-color: var(--primary-frk-black);

  // Emotional teaser
  --emotional-teaser-cta-background: var(--primary-frk-vibrant-red);
  --emotional-teaser-cta-text: var(--primary-frk-white);
  --emotional-teaser-image-background: var(--primary-frk-vibrant-red);
  --emotional-teaser-pre-title-color: var(--primary-frk-black);
  --emotional-teaser-title-color: var(--primary-frk-black);

  // Event list
  --event-list-item-color: var(--primary-frk-black);
  --event-list-box-shadow-color: var(--gradations-frk-rosemary-50);
  --event-list-location-border-color: var(--gradations-frk-rosemary-50);
  --event-list-item-hover-text-color: var(--primary-frk-vibrant-red);
  --event-list-item-hover-background-color: var(--gradations-frk-champagne-50);
  --event-list-item-icon-color: var(--primary-frk-vibrant-red);

  // External Link
  --external-link-color: var(--primary-frk-vibrant-red);

  // Footer
  --footer-background-color: #f2f2f2;
  --footer-text-color: var(--primary-frk-black);
  --footer-scroll-to-top-button-background-color: var(--primary-frk-vibrant-red);
  --footer-scroll-to-top-button-text-color: var(--primary-frk-white);
  --footer-scroll-to-top-button-border-color: var(--primary-frk-vibrant-red);
  --footer-scroll-to-top-button-hover-background-color: var(--gradations-frk-vibrant-red-20);
  --footer-scroll-to-top-button-hover-text-color: var(--primary-frk-white);
  --footer-scroll-to-top-button-hover-border-color: var(--gradations-frk-vibrant-red-20);
  --footer-social-icons-title: var(--transparency-frk-black-50-trans);
  --footer-social-icons-color: var(--primary-frk-black);
  --footer-navigation-title-text-color: var(--transparency-frk-black-50-trans);
  --footer-navigation-link-color: var(--primary-frk-black);
  --footer-navigation-accordion-button-color: var(--primary-frk-black);
  --footer-navigation-accordion-title-color: var(--primary-frk-black);
  --footer-navigation-accordion-icon-color: var(--primary-frk-black);
  --footer-navigation-accordion-item-border-color: var(--transparency-frk-white-15-trans);
  --footer-navigation-accordion-list-item-link-color: var(--primary-frk-black);
  --footer-newsletter-title-text-color: var(--transparency-frk-black-50-trans);
  --footer-newsletter-list-title-text-color: var(--primary-frk-white);
  --footer-newsletter-list-item-link-hover-text-color: var(--primary-frk-black);
  --footer-copyright-text-color: var(--primary-frk-black);
  --footer-legal-links-item-text-color: var(--primary-frk-black);
  --footer-legal-links-item-link-hover-text-color: var(--primary-frk-black);

  // Floater bar
  --floater-bar-container-background-color: var(--primary-frk-vibrant-red);
  --floater-bar-item-color: var(--primary-frk-white);
  --floater-bar-item-border-color: var(--primary-frk-white);
  --floater-bar-toggle-border-color: var(--primary-frk-white);

  // Forms
  --form-error-background-color: var(--gradations-frk-vibrant-red-10);
  --form-item-error-text-color: var(--system-frk-error);
  --form-text-placeholder-text-color: var(--grayscale-frk-cool-grey-8);
  --form-textarea-placeholder-text-color: var(--grayscale-frk-cool-grey-8);
  --form-text-textarea-border-color: var(--primary-frk-black);
  --form-text-textarea-focus-border-color: var(--primary-frk-black);
  --form-text-is-invalid-text-color: var(--system-frk-error);
  --form-text-is-invalid-border-color: var(--system-frk-error);
  --form-button-background-color: var(--primary-frk-vibrant-red);
  --form-button-text-color: var(--primary-frk-white);
  --form-button-border-color: var(--primary-frk-vibrant-red);
  --form-button-background-hover-color: var(--gradations-frk-vibrant-red-20);
  --form-button-text-hover-color: var(--primary-frk-white);
  --form-button-border-hover-color: var(--gradations-frk-vibrant-red-20);
  --form-options-drop-down-text-color: var(--grayscale-frk-cool-grey-8);
  --form-options-is-invalid-text-color: var(--system-frk-error);
  --form-options-is-invalid-border-color: var(--system-frk-error);
  --form-checkbox-border-color: var(--primary-frk-black);
  --form-checkbox-checked-background-color: var(--primary-frk-vibrant-red);
  --form-checkbox-checked-text-color: var(--primary-frk-white);
  --form-radio-border-color: var(--primary-frk-black);
  --form-radio-checked-background-color: var(--primary-frk-vibrant-red);
  --form-radio-checked-text-color: var(--primary-frk-white);
  --form-dropdown-border-color: var(--primary-frk-black);
  --form-dropdown-invalid-text-color: var(--grayscale-frk-cool-grey-8);
  --form-description-link-color: var(--primary-frk-vibrant-red);
  --form-description-hover-link-color: var(--gradations-frk-vibrant-red-20);
  --form-description-invalid-text-color: var(--system-frk-error);

  // Global search
  --global-search-title-color: var(--primary-frk-vibrant-red);
  --global-search-border-color: var(--primary-frk-black);
  --global-search-border-focus-color: var(--primary-frk-vibrant-red);
  --globar-search-input-border-color: var(--grayscale-frk-cool-grey-1);
  --globar-search-input-focus-border-color: var(--primary-frk-vibrant-red);
  --globar-search-view-all-button-background-color: var(--primary-frk-vibrant-red);
  --global-search-view-all-button-text-color: var(--primary-frk-white);
  --global-search-view-all-button-border-color: transparent;
  --globar-search-view-all-button-hover-background-color: var(--gradations-frk-vibrant-red-20);
  --global-search-view-all-button-hover-text-color: var(--primary-frk-white);
  --global-search-view-all-button-hover-border-color: transparent;
  --global-search-result-item-border-color: var(--grayscale-frk-cool-grey-1);
  --global-search-result-item-wrapper-hover-color: var(--primary-frk-black);
  --global-search-result-item-image-background-color: var(--grayscale-frk-black-5);

  // Header
  --header-background-color-on-open: var(--primary-frk-white);
  --header-background-color-on-scroll: var(--primary-frk-white);
  --header-background-color: var(--grayscale-frk-cool-gray-11);
  --header-font-color-on-hover: var(--primary-frk-black);
  --header-font-color: var(--primary-frk-white);
  --header-divider-color: var(--transparency-frk-white-50-trans);

  // Icon grid
  --icon-grid-button-background-color: var(--primary-frk-vibrant-red);
  --icon-grid-button-border-color: transparent;
  --icon-grid-button-font-color: var(--primary-frk-white);
  --icon-grid-button-on-hover-focus-background-color: var(--gradations-frk-vibrant-red-20);
  --icon-grid-button-on-hover-focus-border-color: transparent;
  --icon-grid-button-on-hover-focus-font-color: var(--primary-frk-white);
  --icon-grid-border-overlay-color: var(--primary-frk-white);
  --icon-grid-item-box-shadow-color: var(--grayscale-frk-cool-grey-1);

  // Interactive icon grid
  --interactive-grid-border-overlay: var(--primary-frk-white);
  --interactive-grid-item-border: var(--grayscale-frk-cool-grey-1);
  --interactive-grid-item-link-hover-background-color: var(--gradations-frk-champagne-50);
  --interactive-grid-title-color: var(--primary-frk-black);
  --interactive-grid-icon-color: var(--primary-frk-vibrant-red);

  // Link
  --link-font-color: var(--primary-frk-vibrant-red);

  // Link list
  --link-list-item-border-color: var(--gradations-frk-rosemary-50);
  --link-list-item-link-text-color: var(--primary-frk-vibrant-red);
  --link-list-item-link-hover-text-color: var(--primary-frk-black);
  --link-list-item-link-hover-background-color: var(--gradations-frk-champagne-50);
  --link-list-item-title-color: var(--primary-frk-black);
  --link-list-item-description-color: var(--grayscale-frk-cool-gray-11);

  // Language switcher
  --language-switcher-active-color: var(--secondary-frk-champagne);
  --language-switcher-country-divider: var(--grayscale-frk-cool-grey-1);
  --language-switcher-link-color: var(--primary-frk-vibrant-red);
  --language-switcher-in-nav-color: var(--transparency-frk-black-50-trans);
  --language-switcher-in-nav-active-color: var(--primary-frk-white);
  --product-wishlist-counter: var(--transparency-frk-black-50-trans);

  // Newslist
  --newslist-item-box-shadow-color: var(--gradations-frk-rosemary-50);
  --newslist-item-text-color: var(--primary-frk-black);
  --newslist-item-hover-text-color: var(--primary-frk-vibrant-red);
  --newslist-item-hover-background-color: var(--gradations-frk-champagne-50);
  --newslist-item-location-border-color: var(--gradations-frk-rosemary-50);
  --newslist-item-hover-location-border-color: var(--primary-frk-vibrant-red);
  --newslist-button-background-color: transparent;
  --newslist-button-border-color: var(--primary-frk-black);
  --newslist-button-font-color: var(--primary-frk-black);
  --newslist-button-on-hover-focus-background-color: var(--primary-frk-black);
  --newslist-button-on-hover-focus-border-color: var(--primary-frk-black);
  --newslist-button-on-hover-focus-font-color: var(--primary-frk-white);

  // Navigation
  --navigation-background-color: var(--primary-frk-white);
  --navigation-text-color: var(--primary-frk-black);
  --navigation-section-title-color: var(--grayscale-frk-cool-grey-5);
  --navigation-division-title-hover-color: var(--primary-frk-vibrant-red);
  --navigation-accordion-item-icon-color: var(--primary-frk-vibrant-red);
  --navigation-accordion-title-color: var(--primary-frk-black);
  --navigation-accordion-title-hover-color: var(--primary-frk-vibrant-red);
  --navigation-accordion-title-is-open-color: var(--primary-frk-vibrant-red);
  --navigation-subitem-color-is-active: var(--primary-frk-vibrant-red);
  --navigation-wrapper-border-color: var(--primary-frk-vibrant-red);
  --navigation-accordion-item-link-color: var(--primary-frk-black);
  --navigation-accordion-item-link-hover-active-color: var(--primary-frk-vibrant-red);
  --navigation-teaser-background-color: var(--primary-frk-vibrant-red);
  --navigation-teaser-text-color: var(--primary-frk-white);
  --navigation-teaser-content-link-color: var(--primary-frk-white);
  --navigation-product-item-cta-icon-color: var(--primary-frk-vibrant-red);

  // Product explorer
  --product-explorer-multi-select-filter-popover-header-border-color: var(--primary-frk-black);
  --product-explorer-multi-select-filter-popover-dismiss-border-color: var(-primary-frk-black);
  --product-explorer-multi-select-filter-black-border: var(--primary-frk-black);
  --product-explorer-multi-select-filter-trigger-color: var(--primary-frk-black);
  --product-explorer-multi-select-filter-trigger-background-color: var(--primary-frk-white);
  --product-explorer-multi-select-filter-popover-background-color: var(--primary-frk-white);
  --product-explorer-multi-select-filter-popover-border-adjustment-color: var(--primary-frk-white);
  --product-explorer-multi-select-filter-counter-color: var(--primary-frk-white);
  --product-explorer-multi-select-filter-counter-background-color: var(--primary-frk-black);
  --product-explorer-multi-select-listbox-info-button-background-color: var(--primary-frk-white);
  --product-explorer-multi-select-listbox-info-button-text-color: var(--primary-frk-black);
  --product-explorer-multi-select-listbox-action-border-color: var(--primary-frk-black);
  --product-explorer-multi-select-listbox-action-apply-text-color: var(--primary-frk-white);
  --product-explorer-multi-select-listbox-action-apply-background-color: var(--primary-frk-black);
  --product-explorer-multi-select-listbox-action-reset-text-color: var(--primary-frk-black);
  --product-explorer-multi-select-listbox-action-reset-background-color: var(--primary-frk-white);
  --product-explorer-page-counter-item-color: var(--primary-frk-black);
  --product-explorer-page-counter-selected-item-text-color: var(--primary-frk-white);
  --product-explorer-page-counter-selected-item-background-color: var(--primary-frk-white);
  --product-explorer-page-counter-arrow-icon-color: var(--grayscale-frk-cool-grey-5);
  --product-explorer-layout-reset-all-text-color: var(--grayscale-frk-cool-grey-8);
  --product-explorer-layout-total-count-text-color: var(--primary-frk-vibrant-red);
  --product-explorer-layout-total-count-label-text-color: var(--primary-frk-black);
  --product-explorer-list-item-background-color: var(--primary-frk-white);
  --product-explorer-list-item-link-text-color: var(--primary-frk-black);
  --product-explorer-list-item-title-border-color: var(--gradations-frk-rosemary-50);

  // Product details page
  --product-details-page-carousel-selected-thumbnail-border-color: var(--grayscale-frk-cool-gray-11);
  --product-details-page-carousel-scroller-button-background-color: var(--grayscale-frk-cool-gray-11);
  --product-details-page-carousel-scroller-button-text-color: var(--primary-frk-white);
  --product-details-page-carousel-scroller-button-border-color: var(--grayscale-frk-cool-gray-11);
  --product-details-page-carousel-scroller-button-hover-background-color: var(--primary-frk-black);
  --product-details-page-carousel-scroller-button-hover-text-color: var(--primary-frk-white);
  --product-details-page-carousel-scroller-button-hover-border-color: var(--primary-frk-black);
  --product-details-page-buy-button-background-color: var(--primary-frk-white);
  --product-details-page-buy-button-text-color: var(--primary-frk-black);
  --product-details-page-buy-button-border-color: transparent;
  --product-details-page-buy-button-hover-background-color: var(--grayscale-frk-black-5);
  --product-details-page-buy-button-hover-text-color: var(--primary-frk-black);
  --product-details-page-buy-button-hover-border-color: transparent;
  --product-details-page-information-background-color: var(--grayscale-frk-cool-gray-11);
  --product-details-page-information-text-color: var(--primary-frk-white);
  --product-details-page-separator-color: var(--primary-frk-white);
  --product-details-page-zoom-icon-color: var(--grayscale-frk-cool-gray-11);

  // Product functionalities
  --product-functionalities-button-background-color: transparent;
  --product-functionalities-button-text-color: var(--primary-frk-black);
  --product-functionalities-button-border-color: var(--primary-frk-black);
  --product-functionalities-button-hover-background-color: var(--primary-frk-black);
  --product-functionalities-button-hover-text-color: var(--primary-frk-white);
  --product-functionalities-button-hover-border-color: var(--primary-frk-black);

  // Product information table
  --product-information-table-item-border: var(--grayscale-frk-cool-grey-1);

  // Quote
  --quote-text-color: var(--primary-frk-vibrant-red);
  --quote-name-text-color: var(--primary-frk-black);
  --quote-title-text-color: var(--primary-frk-black);
  --quote-border-color: var(--primary-frk-vibrant-red);

  // Slider hero
  --slider-hero-background-color: var(--grayscale-frk-cool-gray-11);
  --slider-hero-button-background-color: var(--primary-frk-vibrant-red);
  --slider-hero-button-border-color: transparent;
  --slider-hero-button-font-color: var(--primary-frk-white);
  --slider-hero-button-hover-background-color: var(--gradations-frk-vibrant-red-20);
  --slider-hero-button-hover-border-color: transparent;
  --slider-hero-button-hover-font-color: var(--primary-frk-white);
  --slider-hero-text-color: var(--primary-frk-white);
  --slider-hero-timer-background-color: var(--grayscale-frk-black-5);
  --slider-hero-timer-progress-color: var(--primary-frk-black);

  // Storytelling teaser
  --storytelling-teaser-background: var(--primary-frk-white);
  --storytelling-teaser-description-color: var(--primary-frk-black);
  --storytelling-teaser-pre-title-color: var(--primary-frk-black);
  --storytelling-teaser-subtitle-color: var(--primary-frk-black);
  --storytelling-teaser-title-color: var(--primary-frk-vibrant-red);

  // Teaser
  --teaser-background-color: var(--primary-frk-white);
  --teaser-button-background-color: var(--primary-frk-vibrant-red);
  --teaser-button-border-color: transparent;
  --teaser-button-font-color: var(--primary-frk-white);
  --teaser-button-on-hover-focus-background-color: var(--gradations-frk-vibrant-red-20);
  --teaser-button-on-hover-focus-border-color: transparent;
  --teaser-button-on-hover-focus-font-color: var(--primary-frk-black);
  --teaser-text-color: var(--primary-frk-black);

  // Text emotional
  --text-emotional-background-color: transparent;
  --text-emotional-description-color: var(--primary-frk-black);
  --text-emotional-title-color: var(--primary-frk-black);

  // Text
  --text-link-color: var(--primary-frk-vibrant-red);
}
