.cmp-global-search-sections-item {
  @media #{$mq-12} {
    margin-top: spacing(16);
  }

  @media #{$mq-345} {
    margin-top: spacing(20);
  }

  &__label {
    display: flex;
    align-items: flex-end;
    @include heading-l-extended-bold;
    @media #{$mq-12} {
      margin-bottom: spacing(4);
    }

    @media #{$mq-345} {
      margin-bottom: spacing(5);
    }

    &--counter {
      margin-left: spacing(2);
      font-size: 70%;
    }
  }

  &__container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  &__results {
    width: 100%;

    .cmp-download-list {
      &__item {
        margin: 0;
      }

      &__title {
        margin-right: spacing(7);
        word-break: break-word;
      }
    }
  }

  &__view-all.cmp-button-link {
    margin-top: spacing(4);

    @include heading-m-extended-bold;
    @include button(
      var(--globar-search-view-all-button-background-color),
      var(--global-search-view-all-button-text-color),
      var(--global-search-view-all-button-border-color),
      var(--globar-search-view-all-button-hover-background-color),
      var(--global-search-view-all-button-hover-text-color),
      var(--global-search-view-all-button-hover-border-color)
    );

    .cmp-navigation--is-opened & {
      @media #{$mq-12} {
        padding: spacing(4) spacing(7);
      }
    }
  }

  &__view-all {
    &--is-link {
      margin-top: spacing(4);
      @include heading-m-extended-bold;
      @include button(
        var(--globar-search-view-all-button-background-color),
        var(--global-search-view-all-button-text-color),
        var(--global-search-view-all-button-border-color),
        var(--globar-search-view-all-button-hover-background-color),
        var(--global-search-view-all-button-hover-text-color),
        var(--global-search-view-all-button-hover-border-color)
      );
    }
  }

  .cmp-download-list {
    padding: 0;

    &__item {
      margin: 0;
    }
  }
}
