.cmp-search {
  width: 100%;

  @media #{$mq-1} {
    width: grid-spacing(1, 12, 11);
    margin-left: grid-spacing(1, 0, 0, 1);
  }

  @media #{$mq-2} {
    width: grid-spacing(2, 12, 11);
    margin-left: grid-spacing(2, 0, 0, 1);
  }

  @media #{$mq-3} {
    width: grid-spacing(3, 12, 11);
    margin-left: grid-spacing(3, 0, 0, 1);
  }

  @media #{$mq-4} {
    width: grid-spacing(4, 8, 7);
    margin-left: grid-spacing(4, 2, 2, 1);
  }

  @media #{$mq-5} {
    width: grid-spacing(5, 8, 7);
    margin: auto;
  }

  &__title {
    color: var(--global-search-title-color);

    @media #{$mq-123} {
      margin-bottom: spacing(11);
    }
    @media #{$mq-45} {
      margin-bottom: spacing(25);
    }

    @media #{$mq-3} {
      width: grid-spacing(3, 6, 6);
    }

    @media #{$mq-4} {
      width: grid-spacing(4, 6, 6);
    }

    @media #{$mq-5} {
      width: grid-spacing(5, 6, 6);
    }

    @media #{$mq-12} {
      @include heading-xxl-extended-bold;
    }
    @media #{$mq-345} {
      @include display-l-expanded-bold;
    }
  }

  &__input {
    @media #{$mq-12} {
      margin: spacing(20) 0 spacing(14) 0;
    }
    @media #{$mq-345} {
      margin: spacing(8) 0;
    }
  }

  &__search-field {
    display: grid;
    align-items: center;
    justify-content: center;
    padding: spacing(4) 0;
    border-bottom: 2px solid var(--global-search-border-color);
    grid-template-columns: max-content 1fr max-content;
    grid-template-rows: 1fr;
    transition: border 500ms ease-out;

    &:focus-within {
      border-bottom: 2px solid var(--global-search-border-focus-color);
    }

    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: none;
      cursor: pointer;
      grid-column: 3;
      grid-row: 1;
      opacity: 0;
      pointer-events: none;
      transition: opacity 200ms ease-out;
      @include heading-base-extra-bold;

      &--is-active {
        justify-content: end;
        color: var(--primary-frk-black);
        opacity: 1;
        pointer-events: all;
        @media #{$mq-345} {
          font-size: spacing(4);
        }
        @media #{$mq-12} {
          font-size: spacing(5);
        }
      }

      &-icon {
        margin-left: spacing(2);
      }
    }

    &-input {
      width: 100%;
      border: none;
      margin: 0 spacing(4);
      grid-column: 2;
      grid-row: 1;
      outline: none;
      @include paragraph-lead-xl;
    }

    &-label {
      transform: rotate(90deg);
    }

    &-icon {
      grid-column: 1;
      grid-row: 1;
      @include paragraph-lead-xl;
    }

    @media #{$mq-123} {
      margin: spacing(8) 0;
    }

    @media #{$mq-45} {
      margin: spacing(20) 0 spacing(14) 0;
    }
  }

  &__result-wrapper {
    display: none;
    pointer-events: none;
    transition: border 500ms ease-out;

    &--is-visible {
      display: block;
      pointer-events: all;
    }
  }

  .cmp-tabs {
    @media #{$mq-45} {
      margin: 0;
    }
  }
}
