.cmp-coffee-machine-modal-content {
  &__image,
  &__wrapper {
    @media #{$mq-123} {
      width: inherit;
    }
    @media #{$mq-1} {
      width: grid-spacing(1, 10, 9);
    }
    @media #{$mq-2} {
      width: grid-spacing(2, 10, 9);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 10, 9);
    }
  }

  &__image {
    aspect-ratio: 4/3;
    object-fit: cover;

    @media #{$mq-123} {
      padding: spacing(16) 0 spacing(4);
    }
    @media #{$mq-4} {
      width: (calc(grid-spacing(4, 6, 5) - spacing(8)));
    }
    @media #{$mq-5} {
      width: (calc(grid-spacing(5, 6, 5) - spacing(8)));
    }
  }

  &__wrapper {
    @media #{$mq-45} {
      display: flex;
      flex-direction: column;
      align-self: center;
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 4, 3);
      margin-left: grid-spacing(4, 1, 2);
    }
    @media #{$mq-5} {
      width: grid-spacing(5, 4, 3);
      margin-left: grid-spacing(5, 1, 2);
    }
  }

  &__title {
    @media #{$mq-123} {
      @include display-l-expanded-bold;
    }
    @media #{$mq-45} {
      @include heading-xxl-extended-bold;
    }
  }

  &__description {
    @media #{$mq-123} {
      @include paragraph-base;
    }
    @media #{$mq-45} {
      @include paragraph-lead-m;
    }

    @media #{$mq-123} {
      padding: spacing(3) 0 spacing(6);
    }
    @media #{$mq-45} {
      padding: spacing(5) 0 spacing(10);
    }
  }

  &__cta {
    @include heading-base-extended-bold;
    width: fit-content;
    padding: spacing(4) spacing(7);
    cursor: pointer;
    text-align: center;

    @media #{$mq-123} {
      margin-bottom: spacing(7);
    }

    @include button(
      var(--form-button-background-color),
      var(--form-button-text-color),
      var(--form-button-border-color),
      var(--form-button-background-hover-color),
      var(--form-button-text-hover-color),
      var(--form-button-border-hover-color)
    );
  }
}
