.cmp-form-text {
  display: flex;
  flex-direction: column;
  margin-bottom: spacing(8);
  color: var(--form-text-label-color);

  label {
    @include paragraph-s-bold;
  }

  &__text {
    padding: spacing(3);
    color: var(--form-text-color);

    &::placeholder {
      @include paragraph-base;
      position: absolute;
      top: 50%;
      color: var(--form-text-placeholder-text-color);
      transform: translateY(-50%);
    }
  }

  &__error-message {
    display: none;
  }

  &__textarea {
    padding: spacing(3);
    background-color: var(--form-text-textarea-background-color);
    color: var(--form-text-textarea-color);
    resize: none;

    &::placeholder {
      @include paragraph-base;
      position: absolute;
      top: spacing(3);
      left: spacing(4);
      color: var(--form-textarea-placeholder-text-color);
    }
  }

  &__text,
  &__textarea {
    position: relative;
    border: $form-border-width solid var(--form-text-textarea-border-color);
    background-color: var(--form-text-background-color);

    &:focus,
    &:focus-visible {
      border: $form-border-width solid var(--form-text-textarea-focus-border-color);
      outline: none;
    }

    &:focus::placeholder {
      color: transparent;
    }

    // On submit
    .cmp-form-text--is-invalid &,
    // Once interacted with element
    .cmp-form-text--is-interacted & {
      &:invalid {
        border-color: var(--form-text-is-invalid-border-color);

        &::placeholder {
          color: var(--form-text-is-invalid-text-color);
        }
      }
    }
  }

  &--is-invalid {
    color: var(--form-text-is-invalid-text-color);

    .cmp-form-text__error-message {
      display: block;
      @include paragraph-s;
      padding: spacing(3);
    }
  }
}
