.cmp-corporate-values {
  display: grid;
  gap: 0 spacing(6);

  @media #{$mq-5} {
    padding-top: spacing(10);
    grid-template-columns: repeat(3, 1fr);
  }

  @media #{$mq-4-custom2} {
    padding-top: spacing(10);
    grid-template-columns: repeat(3, 1fr);
  }

  @media #{$mq-4-custom1} {
    padding-top: spacing(10);
    grid-template-columns: repeat(2, 1fr);
  }

  @media #{$mq-123} {
    @include remove-side-margins;
    @include with-max-width-center;
    gap: 0;
    grid-template-columns: repeat(1, 1fr);
  }

  &__teaser {
    @media #{$mq-45} {
      display: grid;
      margin-bottom: spacing(20);
      gap: 0;
      grid-row: span 4;
      grid-template-columns: subgrid;
      grid-template-rows: subgrid;
    }

    @media #{$mq-123} {
      position: relative;
      display: flex;
      flex-flow: wrap;
      margin-top: spacing(10);

      &:not(first-child) {
        margin-top: spacing(17);
      }

      @media #{$mq-12} {
        @include with-left-and-right-spacing;
      }
    }

    &:nth-child(odd) {
      @media #{$mq-45} {
        .cmp-corporate-values__teaser-top--icon-wrapper,
        .cmp-corporate-values__teaser-bottom {
          background: var(--gradations-frk-wheat-50);
        }
      }

      @media #{$mq-3} {
        .cmp-corporate-values__teaser-top,
        .cmp-corporate-values__teaser-bottom--title,
        .cmp-corporate-values__teaser-bottom--description,
        .cmp-corporate-values__teaser-middle {
          background: var(--gradations-frk-wheat-50);
        }
      }

      @media #{$mq-12} {
        background: var(--gradations-frk-wheat-50);
      }
    }

    &:nth-child(even) {
      @media #{$mq-45} {
        .cmp-corporate-values__teaser-top--icon-wrapper,
        .cmp-corporate-values__teaser-bottom {
          background: var(--grayscale-frk-black-5);
        }
      }
      @media #{$mq-3} {
        .cmp-corporate-values__teaser-top,
        .cmp-corporate-values__teaser-bottom--title,
        .cmp-corporate-values__teaser-bottom--description,
        .cmp-corporate-values__teaser-middle {
          background: var(--grayscale-frk-black-5);
        }
      }
      @media #{$mq-12} {
        background: var(--grayscale-frk-black-5);
      }
    }
  }

  &__teaser-top {
    display: flex;
    @media #{$mq-45} {
      margin-top: spacing(10);
    }

    @media #{$mq-123} {
      padding-top: spacing(14);
    }

    @media #{$mq-3} {
      width: 100%;
      padding-left: grid-spacing(3, 0, 0, 1);
    }

    @media #{$mq-2} {
      width: grid-spacing(2, 12, 11, 0);
    }

    @media #{$mq-1} {
      width: grid-spacing(1, 12, 11, 0);
    }

    &--icon-wrapper {
      @media #{$mq-5} {
        width: grid-spacing(5, 1, 0);
      }
      @media #{$mq-45} {
        position: relative;
        padding-top: spacing(10);
      }
      @media #{$mq-4-custom2} {
        width: grid-spacing(4, 1, 1);
      }
      @media #{$mq-4-custom1} {
        width: grid-spacing(4, 2, 1);
      }

      .cmp-corporate-values__teaser-top--icon {
        position: absolute;
        top: 0;
        width: fit-content;
        margin-inline: auto;
        transform: translateY(-50%);
        @media #{$mq-45} {
          right: 0;
          left: 0;
        }
        @media #{$mq-123} {
          right: unset;
          left: unset;
        }
      }
    }

    &--intro {
      @media #{$mq-45} {
        padding-left: spacing(6);
      }
      @media #{$mq-5} {
        width: grid-spacing(5, 3, 3);
      }
      @media #{$mq-4-custom2} {
        width: grid-spacing(4, 3, 2);
      }
      @media #{$mq-4-custom1} {
        width: grid-spacing(4, 4, 4);
      }
      @media #{$mq-3} {
        width: grid-spacing(3, 7, 6, 0);
      }
      @media #{$mq-2} {
        width: grid-spacing(2, 12, 11, 0);
      }
    }

    &--intro-title {
      @include heading-m-extra-bold;
      @media #{$mq-1} {
        @include heading-base-extra-bold;
      }
    }

    &--intro-description {
      @include paragraph-base;
      margin: spacing(2) 0 spacing(4);

      @media #{$mq-2} {
        width: grid-spacing(2, 7, 8, 0);
        margin: spacing(2) 0 spacing(4);
      }
      @media #{$mq-2} {
        width: grid-spacing(2, 10, 9, 0);
        margin: spacing(2) 0 spacing(4);
      }
      @media #{$mq-1} {
        width: grid-spacing(1, 12, 11, 0);
        @include paragraph-s;
        margin: spacing(2) 0 spacing(4);
      }
    }
  }

  &__teaser-middle {
    display: flex;
    align-items: flex-start;
    @media #{$mq-3} {
      width: grid-spacing(3, 6, 5, 1);
    }
    @media #{$mq-2} {
      width: grid-spacing(2, 12, 11);
    }
    @media #{$mq-1} {
      width: grid-spacing(1, 12, 11);
    }

    &--image {
      object-fit: contain;
    }
  }

  &__teaser-bottom {
    @media #{$mq-45} {
      display: grid;
      padding: spacing(6);
      grid-row: span 3;
      grid-template-columns: subgrid;
      grid-template-rows: subgrid;
    }
    @media #{$mq-123} {
      display: flex;
      flex: 1;
      flex-flow: column;
    }
    @media #{$mq-2} {
      width: 100%;
      padding-bottom: spacing(9);
    }
    @media #{$mq-1} {
      width: 100%;
      padding-bottom: spacing(6);
    }

    &--title {
      @media #{$mq-45} {
        @include heading-xxl-extended-bold;
        padding-bottom: spacing(2);
      }
      @media #{$mq-3} {
        padding: 0 spacing(12) spacing(2) spacing(6);
        @include heading-xl-extended-bold;
      }
      @media #{$mq-2} {
        padding: spacing(4) 0 spacing(2) 0;
        @include heading-l-extended-bold;
      }
      @media #{$mq-1} {
        @include heading-m-extended-bold;
        padding: spacing(4) 0 spacing(2) 0;
      }
    }

    &--description {
      @include paragraph-s;
      padding: 0;

      @media #{$mq-3} {
        padding: 0 spacing(12) spacing(6) spacing(6);
      }
    }
  }
}
