.cmp-global-search-sections {
  @media #{$mq-123} {
    padding-left: 0;
  }

  @media #{$mq-4} {
    padding-left: grid-spacing(4, 1, 1);
  }

  @media #{$mq-5} {
    padding-left: grid-spacing(5, 1, 1);
  }
}
