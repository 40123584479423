.cmp-react-accordion {
  opacity: 0;
  pointer-events: none;
  transition: max-height, max-width 0.3s ease-out 0.1s, opacity 0.2s linear;

  &--is-open {
    opacity: 1;
    pointer-events: all;
    transition: max-height, max-width 0.3s ease-out, opacity 0.2s linear 0.1s;
  }

  &--is-horizontal > div {
    /*
      this fix min-width being inherited and preventing the size of the children to change
      this would cause the resizeobserver not able to fire as the size wouldn't change
    */
    width: fit-content;
  }
}
