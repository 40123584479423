.cmp-contact {
  @include grid-layout;
  @include with-max-width-center;
  overflow-x: hidden;
  @media #{$mq-1} {
    margin-right: calc(var(--page-container-side-margin-s) * -1);
    margin-left: calc(var(--page-container-side-margin-s) * -1);
  }

  @media #{$mq-2} {
    margin-right: calc(var(--page-container-side-margin-m) * -1);
    margin-left: calc(var(--page-container-side-margin-m) * -1);
  }

  @media #{$mq-3} {
    margin-right: calc(var(--page-container-side-margin-l) * -1);
    margin-left: calc(var(--page-container-side-margin-l) * -1);
  }

  @media #{$mq-4} {
    margin-right: calc(var(--page-container-side-margin-xl) * -1);
    margin-left: calc(var(--page-container-side-margin-xl) * -1);
  }

  @media #{$mq-5} {
    margin-right: calc(var(--page-container-side-margin-x-large) * -1);
    margin-left: calc(var(--page-container-side-margin-x-large) * -1);
  }

  &__first-header-wrapper {
    position: relative;

    @media #{$mq-12} {
      left: spacing(-1);
      width: max-content;
    }

    @media #{$mq-345} {
      left: spacing(-9);
      display: flex;
      margin-bottom: (calc(spacing(20) * -1));
      grid-column: #{column-index(0)} / #{column-index(6, 1)};
      grid-row: 1;
    }

    @media #{$mq-3} {
      left: spacing(-2);
    }

    @media #{$mq-45} {
      left: spacing(-9);
    }
  }

  &__second-header-wrapper {
    position: relative;

    @media #{$mq-12} {
      left: spacing(-1);
      width: max-content;
      grid-row: 2 / 3;
    }

    @media #{$mq-345} {
      display: flex;
      justify-content: flex-end;
      grid-column: #{column-index(7)} / #{column-index(13)};
      grid-row: 2;
      text-align: right;
      transform: translateY(80px);
    }

    @media #{$mq-3} {
      right: spacing(-2);
      transform: translateY(30px);
    }

    @media #{$mq-45} {
      right: spacing(-9);
    }
  }

  &__header {
    color: var(--contact-header-color);
    @include display-xxxxl-expanded-bold-large;
  }

  &__first-card {
    background-color: var(--contact-first-card-background-color);
    grid-row: 2 / 5;

    @media #{$mq-12} {
      grid-column: #{column-index(1)} / #{column-index(11, 1)};
    }

    @media #{$mq-345} {
      grid-column: #{column-index(1)} / #{column-index(6, 1, 1)};
    }

    .cmp-contact__call-to-action {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: var(--contact-cta-first-card-text-color);
    }

    .cmp-contact__phone,
    .cmp-contact__email {
      @include link(var(--contact-cta-fisrt-card-text-color), false);
    }

    .cmp-contact__button {
      @include button(
        var(--contact-first-cta-button-background-color),
        var(--contact-first-cta-button-font-color),
        var(--contact-first-cta-button-border-color),
        var(--contact-first-cta-button-on-hover-focus-background-color),
        var(--contact-first-cta-button-on-hover-focus-font-color),
        var(--contact-first-cta-button-on-hover-focus-border-color)
      );
    }
  }

  &__second-card {
    margin-bottom: auto;
    background: var(--contact-second-card-background-color-default);

    @media #{$mq-12} {
      grid-column: #{column-index(2)} / #{column-index(12, 1)};
      grid-row: 5 / 6;
    }

    @media #{$mq-345} {
      grid-column: #{column-index(6, 1, 1)} / #{column-index(12, 1)};
      grid-row: 3 / 6;
    }

    .cmp-contact__call-to-action {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: var(--contact-cta-second-card-text-color);
    }

    .cmp-contact__phone,
    .cmp-contact__email {
      @include link(var(--contact-cta-second-card-text-color), false);
    }

    .cmp-contact__link {
      @include link(var(--contact-cta-second-card-text-color));
    }

    .cmp-contact__button {
      @include button(
        var(--contact-second-cta-button-background-color),
        var(--contact-second-cta-button-font-color),
        var(--contact-second-cta-button-border-color),
        var(--contact-second-cta-button-on-hover-focus-background-color),
        var(--contact-second-cta-button-on-hover-focus-font-color),
        var(--contact-second-cta-button-on-hover-focus-border-color)
      );
    }
  }

  &__title {
    @include display-xxxxl-expanded-bold-large-title;
  }

  &__description {
    @include paragraph-lead-m;
    display: flex;
    overflow: hidden;
    margin-bottom: spacing(6);
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    text-overflow: ellipsis;
    white-space: pre-line;
  }

  &__content {
    @media #{$mq-12} {
      padding: spacing(14) spacing(8) spacing(10);
    }

    @media #{$mq-345} {
      padding: spacing(32) spacing(20) spacing(24);
    }
  }

  &__phone,
  &__email {
    @include heading-base-extra-bold;
    display: flex;
    align-items: center;
  }

  &__phone::before {
    margin-right: spacing(2);
    content: '\ec0c';
    font-family: var(--remixicon-font);
    font-size: spacing(6);
    vertical-align: middle;
  }

  &__email::before {
    margin-right: spacing(2);
    content: '\eef6';
    font-family: var(--remixicon-font);
    font-size: spacing(6);
    vertical-align: middle;
  }

  &__link {
    @include heading-base-extra-bold;
    margin: spacing(8) 0;
  }

  &__button {
    display: inline-block;
    margin-top: spacing(8);
    cursor: pointer;
    @include heading-base-extended-bold;
  }

  &--full-width {
    .cmp-contact__first-card {
      grid-row: 2 / 5;

      @media #{$mq-12} {
        grid-column: #{column-index(1)} / #{column-index(12, 1)};
      }

      @media #{$mq-345} {
        grid-column: #{column-index(2)} / #{column-index(11, 1)};
      }
    }

    .cmp-contact__first-header-wrapper {
      @media #{$mq-345} {
        grid-column: #{column-index(0)} / #{column-index(8, 1)};
      }
    }

    .cmp-contact__second-header-wrapper {
      @media #{$mq-345} {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0;
        grid-column: #{column-index(6)} / #{column-index(12, 1, 1)};
        grid-row: 5;
        transform: translateY(-80px);
      }
      @media #{$mq-3} {
        transform: translateY(-40px);
      }
    }
  }

  &--header-small {
    .cmp-contact__header {
      @include display-xxxxl-expanded-bold-small;
    }

    .cmp-contact__first-header-wrapper {
      @media #{$mq-45} {
        left: spacing(-6);
      }
    }

    .cmp-contact__second-header-wrapper {
      @media #{$mq-45} {
        right: spacing(-6);
      }
    }
  }

  &--first-card-call-to-action-link {
    .cmp-contact__first-card {
      .cmp-contact__button {
        @include link(var(--contact-cta-first-card-text-color), true, var(--contact-cta-first-card-text-color));
        @include heading-base-extra-bold;
        margin: spacing(8) 0;
      }
    }
  }

  &--second-card-call-to-action-link {
    .cmp-contact__second-card {
      .cmp-contact__button {
        @include link(var(--contact-cta-second-card-text-color), true, var(--contact-cta-second-card-text-color));
        @include heading-base-extra-bold;
        margin: spacing(8) 0;
      }
    }
  }

  &--background-burgundy {
    .cmp-contact__second-card {
      background-color: var(--contact-second-card-background-color-burgundy);
    }
  }

  &--background-lavender {
    .cmp-contact__second-card {
      background-color: var(--contact-second-card-background-color-lavender);
    }
  }

  &--background-light-green {
    .cmp-contact__second-card {
      background-color: var(--contact-second-card-background-color-light-green);
    }
  }

  &--background-wheat {
    .cmp-contact__second-card {
      background-color: var(--contact-second-card-background-color-wheat);
    }
  }
}
