.cmp-side-by-side-images {
  @include remove-side-margins;
  position: relative;
  overflow: auto;
  grid-template-rows: spacing(20) auto spacing(20);
  scroll-behavior: smooth;
  @media #{$mq-12} {
    display: grid;
    grid-template-rows: spacing(10) auto spacing(10);
    @include grid-layout;
  }
  @media #{$mq-345} {
    @include grid-layout;
  }

  &__left-side {
    grid-column: #{column-index(2)} / #{column-index(6, 1, 1)};
    grid-row: 1 / 2;
    @media #{$mq-12} {
      grid-column: #{column-index(0)} / #{column-index(8, 7, 0)};

      .cmp-image__title {
        opacity: 1;
        transition: opacity 300ms ease-in-out;
      }
    }
    @media #{$mq-2} {
      .cmp-image__title {
        width: grid-spacing(2, 10, 11);
        padding-left: var(--page-container-side-margin-m) !important;
      }
    }
    @media #{$mq-1} {
      .cmp-image__title {
        width: grid-spacing(1, 10, 11);
        padding-left: var(--page-container-side-margin-s) !important;
      }
    }
  }

  &__right-side {
    right: inherit;
    grid-column: #{column-index(6, 1, 1)} / #{column-index(11, 1, 0)};
    grid-row: 2 / 3;
    @media #{$mq-12} {
      width: grid-spacing(2, 11, 10, 1);
      grid-column-start: #{column-index(11, 1)};

      .cmp-image__title {
        opacity: 0;
        transition: opacity 300ms ease-in-out;
      }
    }

    @media #{$mq-2} {
      .cmp-image__title {
        width: grid-spacing(2, 11, 10);
        padding-left: grid-spacing(2, 1, 1) !important;
      }
    }
    @media #{$mq-1} {
      .cmp-image__title {
        width: grid-spacing(1, 11, 10);
        padding-left: grid-spacing(1, 1, 1) !important;
      }
    }
  }

  .cmp-image {
    display: block;

    &__image {
      aspect-ratio: 1/1;
      object-fit: cover;
    }

    &__title {
      @include caption;
      display: block;
      @media #{$mq-12} {
        padding: spacing(2);
      }
    }
  }

  &--is-scrolled-right {
    .cmp-side-by-side-images__right-side {
      @media #{$mq-12} {
        .cmp-image__title {
          opacity: 1;
          transition: opacity 300ms ease-in-out;
        }
      }
    }

    .cmp-side-by-side-images__left-side {
      @media #{$mq-12} {
        .cmp-image__title {
          opacity: 0;
          transition: opacity 300ms ease-in-out;
        }
      }
    }
  }
}

.cmp-image-side-by-side--is-flipped {
  .cmp-side-by-side-images__left-side {
    grid-row: 2 / 3;
  }

  .cmp-side-by-side-images__right-side {
    grid-row: 1 / 2;

    .cmp-image__title {
      padding-left: spacing(4);
    }
  }
}
