@use 'sass:map';

@mixin button(
  $bgColor: var(--button-background-color),
  $fontColor: var(--button-font-color),
  $borderColor: var(--button-border-color),
  $hoverBgColor: var(--button-on-hover-focus-background-color),
  $hoverFontColor: var(--button-on-hover-focus-font-color),
  $hoverBorderColor: var(--button-on-hover-focus-border-color)
) {
  display: inline-block;
  box-sizing: border-box;
  padding: spacing(4) spacing(7);
  border-width: 2px;
  border-style: solid;
  border-color: $borderColor;
  background-color: $bgColor;
  border-radius: 0;
  color: $fontColor;
  text-decoration: none;
  transition: all ease-in-out 0.25s;

  @media #{$mq-345} {
    @include heading-base-extended-bold;
  }
  @media #{$mq-12} {
    @include heading-s-extended-bold;
  }

  &:hover,
  &:focus {
    border-color: $hoverBorderColor;
    background-color: $hoverBgColor;
    color: $hoverFontColor;
  }
}

@mixin button-disabled($bgColor: var(--grayscale-frk-cool-grey-5), $fontColor: var(--button-font-color)) {
  background-color: $bgColor;
  cursor: not-allowed;

  &:hover,
  &:focus {
    background-color: $bgColor;
    color: $fontColor;
  }
}
