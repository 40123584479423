.cmp-suggested-products-carousel-list {
  @include remove-side-margins;
  @include with-max-width-center;
  background: linear-gradient(
    180deg,
    rgb(242 242 242 / 0%) 0%,
    #f2f2f2 11.46%,
    #f2f2f2 82.29%,
    rgb(242 242 242 / 0%) 100%
  );
}

.cmp-suggested-products-carousel {
  // @include remove-side-margins;
  overflow: hidden;

  &__container {
    @include with-left-and-right-spacing;
  }

  &__title {
    //  @include with-left-and-right-spacing;
    @include display-l-expanded-bold;
    @media #{$mq-5} {
      margin-left: grid-spacing(5, 1, 1);
    }
    @media #{$mq-4} {
      margin-left: grid-spacing(4, 1, 1);
    }
    @media #{$mq-3} {
      margin-bottom: spacing(26);
      margin-left: grid-spacing(3, 0, 1);
    }
    @media #{$mq-45} {
      margin-bottom: spacing(20);
    }
    @media #{$mq-12} {
      margin-bottom: spacing(10);
    }
  }

  &__layout {
    position: relative;
    overflow: visible;
    max-width: 100%;
  }

  &__dots {
    display: flex;
    justify-content: center;
    margin-top: spacing(6);
    margin-bottom: spacing(30);
    gap: spacing(2);

    @media #{$mq-345} {
      margin-top: spacing(10);
      margin-bottom: spacing(20);
      gap: spacing(3);
    }
  }

  &__dot {
    width: spacing(2);
    height: spacing(2);
    padding: 0;
    border: 1px solid var(--products-carousel-dot-color);
    background-color: transparent;

    @media #{$mq-345} {
      width: spacing(4);
      height: spacing(4);
    }

    &--is-active {
      background-color: var(--products-carousel-dot-color);
    }

    &:hover,
    &:focus {
      background-color: var(--products-carousel-dot-color);
      cursor: pointer;
    }
  }

  &__scroller {
    @include button(
      var(--product-details-page-carousel-scroller-button-background-color),
      var(--product-details-page-carousel-scroller-button-text-color),
      var(--product-details-page-carousel-scroller-button-border-color),
      var(--product-details-page-carousel-scroller-button-hover-background-color),
      var(--product-details-page-carousel-scroller-button-hover-text-color),
      var(--product-details-page-carousel-scroller-button-hover-border-color)
    );
    position: absolute;
    top: 50%;
    display: flex;
    width: spacing(8);
    height: spacing(8);
    align-items: center;
    justify-content: center;
    padding: 0;
    cursor: pointer;

    @media #{$mq-345} {
      width: spacing(12);
      height: spacing(12);
    }

    &--right {
      right: 0;
      margin-right: spacing(4, true);
      @media #{$mq-345} {
        margin-right: spacing(6, true);
      }
    }

    &--left {
      left: 0;
      margin-left: spacing(4, true);
      @media #{$mq-345} {
        margin-left: spacing(6, true);
      }
    }
  }

  &__list {
    display: flex;
    overflow: visible;
    flex-wrap: nowrap;
    padding: 0;
    margin: 0;
    transition: margin-left 0.6s ease-in-out;

    @media #{$mq-1} {
      gap: grid-spacing(1, 0, 1);
    }
    @media #{$mq-2} {
      gap: grid-spacing(2, 0, 1);
    }
    @media #{$mq-3} {
      gap: grid-spacing(3, 0, 1);
    }
    @media #{$mq-4} {
      gap: grid-spacing(4, 0, 1);
    }
    @media #{$mq-5} {
      gap: grid-spacing(5, 0, 1);
    }

    .cmp-product-list-item {
      @media #{$mq-1} {
        min-width: grid-spacing(1, 6, 5);
      }
      @media #{$mq-2} {
        min-width: grid-spacing(2, 4, 3);
      }
      @media #{$mq-3} {
        min-width: grid-spacing(3, 3, 2);
      }
      @media #{$mq-4} {
        min-width: grid-spacing(4, 3, 2);
      }
      @media #{$mq-5} {
        min-width: grid-spacing(5, 3, 2);
      }
    }

    &--is-dragged {
      transition: none;
    }
  }
}
