.cmp-footer {
  position: relative;
  @media #{$mq-123} {
    background-color: var(--footer-background-color);
    color: var(--footer-text-color);
  }

  &__scroll-to-top-wrapper {
    position: relative;
    width: grid-spacing(4, 12, 11);
    @include with-max-width-center;
    margin: 0 auto;
    @media #{$mq-5} {
      right: grid-spacing(5, 0, 1);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 12, 11);
    }
    @media #{$mq-2} {
      width: grid-spacing(2, 12, 11);
    }
    @media #{$mq-1} {
      width: grid-spacing(1, 12, 11);
    }
  }

  &__scroll-to-top-button {
    @include button(
      var(--footer-scroll-to-top-button-background-color),
      var(--footer-scroll-to-top-button-text-color),
      var(--footer-scroll-to-top-button-border-color),
      var(--footer-scroll-to-top-button-hover-background-color),
      var(--footer-scroll-to-top-button-hover-text-color),
      var(--footer-scroll-to-top-button-hover-border-color)
    );
    position: absolute;
    z-index: 2;
    top: -50%;
    right: 0;
    display: flex;
    width: spacing(12);
    height: spacing(12);
    align-items: center;
    justify-content: center;
    padding: inherit;
    cursor: pointer;
    transform: translateY(-50%);
  }

  &__wrapper {
    display: grid;
    @include with-left-and-right-spacing;
    @include with-max-width-center;
    padding-top: spacing(10);
    padding-bottom: spacing(10);
    @media #{$mq-45} {
      background-color: var(--footer-background-color);
      color: var(--footer-text-color);
      grid-template:
        'footer-logo-text footer-logo-text  footer-logo-text'
        'footer-social footer-social footer-social'
        'footer-nav footer-nav footer-nav'
        'footer-links footer-links footer-links';
    }
    @media #{$mq-3} {
      grid-template:
        'footer-logo-text footer-logo-text  footer-logo-text'
        'footer-social footer-social footer-social'
        'footer-nav footer-nav footer-nav'
        'footer-links footer-links footer-links';
    }
    @media #{$mq-12} {
      grid-template:
        'footer-nav'
        'footer-logo-text'
        'footer-social'
        'footer-newsletter'
        'footer-links';
    }
  }

  &__logo-and-text {
    flex-flow: column;
    align-items: center;
    justify-content: center;
    order: 1;
    grid-area: footer-logo-text;
    @media #{$mq-5} {
      width: grid-spacing(5, 6, 5);
      margin-left: grid-spacing(5, 3, 3);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 6, 5);
      margin-left: grid-spacing(4, 3, 3);
    }
    @media #{$mq-345} {
      margin-bottom: spacing(8);
    }
    @media #{$mq-12} {
      margin-bottom: spacing(10);
    }

    .cmp-logo-and-text__logo {
      width: $logo-width-desktop;
      height: $logo-height-desktop;
    }
  }

  &__navigation {
    width: 100%;
    order: 2;
    grid-area: footer-nav;
    @media #{$mq-5} {
      margin-bottom: spacing(12);
    }
    @media #{$mq-4} {
      margin-bottom: spacing(12);
    }
    @media #{$mq-3} {
      margin-bottom: spacing(18);
    }
    @media #{$mq-12} {
      display: none;
      margin-bottom: spacing(17);
    }

    &--column {
      @media #{$mq-45} {
        width: grid-spacing(5, 2, 1);

        &:first-child {
          margin-left: grid-spacing(5, 2, 2);
        }

        &:not(:last-child) {
          margin-right: grid-spacing(5, 0, 1);
        }
      }
      @media #{$mq-4} {
        width: grid-spacing(4, 2, 1);

        &:first-child {
          margin-left: grid-spacing(4, 2, 2);
        }

        &:not(:last-child) {
          margin-right: grid-spacing(4, 0, 1);
        }
      }
      @media #{$mq-3} {
        width: grid-spacing(3, 3, 2);

        &:not(:last-child) {
          margin-right: grid-spacing(3, 0, 1);
        }
      }

      .cmp-title__text {
        @include pre-title-s;
        padding-bottom: spacing(5);
        color: var(--footer-navigation-title-text-color);
      }

      .cmp-list {
        padding: 0;
        margin: 0;

        &__item {
          padding-bottom: spacing(5);

          &:last-child {
            padding-bottom: 0;
          }

          &-link {
            @include paragraph-s-bold;
            color: var(--footer-navigation-link-color);
            text-decoration: none;

            &:hover,
            &:focus {
              text-decoration: underline;
            }
          }
        }
      }
    }

    &--mobile {
      width: 100%;
      margin-bottom: spacing(12);

      @media #{$mq-345} {
        display: none;
      }

      .cmp-accordion {
        &__button {
          width: 100%;
          color: var(--footer-navigation-accordion-button-color);
          text-transform: uppercase;
        }

        &__title {
          color: var(--footer-navigation-accordion-title-color);
          @include pre-title-l;
          letter-spacing: 0.09em;
        }

        &__item {
          &:not(:last-of-type) {
            border-bottom: 1px solid var(--footer-navigation-accordion-item-border-color);
          }
        }

        &__icon {
          color: var(--footer-navigation-accordion-icon-color);
          font-size: spacing(4);
        }
      }

      .cmp-list {
        padding: 0;
        margin-top: 0;

        &__item {
          padding-bottom: spacing(5);

          &:last-of-type {
            padding-bottom: spacing(7);
          }

          &-link {
            color: var(--footer-navigation-accordion-list-item-link-color);
            text-decoration: none;
            @include paragraph-s-bold;
          }
        }
      }
    }
  }

  &__logo-and-text,
  &__navigation {
    @media #{$mq-345} {
      display: flex;
    }
  }

  &__socialicons {
    display: flex;
    justify-content: center;
    order: 3;
    grid-area: footer-social;
    @media #{$mq-5} {
      width: grid-spacing(5, 2, 1);
      margin-left: grid-spacing(5, 5, 5);
    }

    @media #{$mq-4} {
      width: grid-spacing(4, 4, 3);
      margin-left: grid-spacing(4, 4, 4);
    }

    @media #{$mq-345} {
      margin-bottom: spacing(14);
    }

    @media #{$mq-3} {
      width: grid-spacing(3, 6, 5);
      margin-left: grid-spacing(3, 3, 3);
    }
    @media #{$mq-12} {
      justify-content: flex-start;
      margin-bottom: spacing(10);
    }

    .socialicons {
      &__title {
        @include pre-title-s;
        margin-top: 0;
        margin-bottom: spacing(3);
        color: var(--footer-social-icons-title);
        @media #{$mq-345} {
          text-align: center;
        }
      }

      &__icons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: spacing(6);
        @media #{$mq-345} {
          justify-content: center;
        }
      }

      &__icon {
        display: inline-block;
        width: spacing(6);
        height: spacing(6);
        color: var(--footer-social-icons-color);
        font-family: var(--remixicon-font);
        text-decoration: none;

        &::before {
          font-size: $icon-height-medium;
        }

        &--facebook {
          &::before {
            content: '\ecbb';
          }
        }

        &--linkedin {
          &::before {
            content: '\eeb5';
          }
        }

        &--instagram {
          &::before {
            content: '\ee66';
          }
        }

        &--pinterest {
          &::before {
            content: '\f000';
          }
        }

        &--x {
          &::before {
            content: '\f3e7';
          }
        }

        &--twitter {
          &::before {
            content: '\f23b';
          }
        }

        &--wechat {
          &::before {
            content: '\f2b6';
          }
        }

        &--youtube {
          &::before {
            content: '\f2d5';
          }
        }
      }
    }
  }

  &__links {
    display: flex;
    justify-content: center;
    order: 5;
    grid-area: footer-links;
    @media #{$mq-5} {
      width: grid-spacing(5, 12, 11);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 12, 11);
    }

    @media #{$mq-3} {
      width: grid-spacing(3, 12, 11);
    }
    @media #{$mq-12} {
      flex-flow: column;
      justify-content: flex-start;
      order: 6;
    }

    .cmp-footer__copyright {
      @include paragraph-s;
      margin-right: spacing(8);
      margin-bottom: spacing(4);
      color: var(--footer-copyright-text-color);
    }

    .cmp-footer__legal-links {
      .cmp-list {
        display: inline-block;
        padding: 0;
        margin: 0;

        &__item {
          display: inline-block;

          &:not(:last-child) {
            margin-right: spacing(8);
          }

          @media #{$mq-12} {
            &:not(:last-child) {
              margin-right: spacing(6);
            }
            margin-bottom: spacing(4);
          }

          &-title {
            @include paragraph-s-bold;
            color: var(--footer-legal-links-item-text-color);
          }

          &-link {
            text-decoration: none;

            &:hover,
            &:focus {
              color: var(--footer-legal-links-item-link-hover-text-color);
              text-decoration: underline;
            }
          }
        }
      }

      .cmp-title__text {
        @include paragraph-lead-m;
      }
    }
  }

  @media print {
    .wishlist-print & {
      display: none;
    }
  }
}

.roblin-theme {
  .cmp-footer {
    &__logo-and-text {
      @media #{$mq-345} {
        margin-left: 0;
        justify-self: center;
      }

      .cmp-logo-and-text__logo {
        width: fit-content;
        height: $logo-height-desktop;
      }
    }
  }
}
