.cmp-product-hero {
  @include grid-layout;
  @include with-max-width-center;
  @include with-left-and-right-spacing;

  @media #{$mq-345} {
    padding-top: $header-height-desktop + $error-banner-height / 2;
    grid-template-rows: spacing(20) 1fr spacing(20);
  }
  @media #{$mq-12} {
    padding-top: $header-height-mobile + $error-banner-height / 2;
    grid-template-rows: auto auto;
    grid-template-rows: 1fr $product-hero-overlap-height auto;
  }

  &__gallery {
    position: relative;
    background-color: var(--product-details-page-gallery-background-color);
    @media #{$mq-345} {
      grid-column: #{column-index(0)} / #{column-index(6, 1)};
      grid-row: 2 / 3;
    }
    @media #{$mq-12} {
      grid-column: #{column-index(0)} / #{column-index(13)};
      grid-row: 1 / 3;
    }
  }

  &__information {
    padding-top: spacing(20);
    background-color: var(--product-details-page-information-background-color);
    color: var(--product-details-page-information-text-color);

    @media #{$mq-345} {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: spacing(20);
      grid-column: #{column-index(6)} / #{column-index(13)};
      grid-row: 1 / 4;
    }

    @media #{$mq-12} {
      @include remove-side-margins;
      @include with-max-width-center;
      @include with-left-and-right-spacing;
      padding-bottom: spacing(10);
      grid-column: #{column-index(0)} / #{column-index(13)};
      grid-row: 2 / 4;
    }

    &--hasWishlist {
      justify-content: start;
      padding-top: 0;
      @media #{$mq-12} {
        .cmp-product-hero__category {
          margin-top: spacing(4);
        }
      }

      .cmp-product-hero__product-price-container {
        margin-top: auto;
      }
    }
  }

  &__category {
    padding-bottom: spacing(2);
    @media #{$mq-5} {
      width: grid-spacing(5, 5, 4);
      margin-left: grid-spacing(5, 1, 1);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 5, 4);
      margin-left: grid-spacing(4, 1, 1);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 5, 4);
      margin-left: grid-spacing(3, 1, 1);
    }
    @media #{$mq-345} {
      @include paragraph-lead-m-bold;
    }
    @media #{$mq-12} {
      @include paragraph-s-bold;
      margin-top: spacing(8);
    }
  }

  &__title {
    @media #{$mq-5} {
      width: grid-spacing(5, 5, 4);
      margin-left: grid-spacing(5, 1, 1);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 5, 4);
      margin-left: grid-spacing(4, 1, 1);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 5, 4);
      margin-left: grid-spacing(3, 1, 1);
    }
    @media #{$mq-345} {
      padding-bottom: spacing(6);
      @include heading-xxl-extended-bold;
    }
    @media #{$mq-12} {
      @include heading-m-extended-bold;
      margin-bottom: spacing(4);
    }
  }

  &__article-energy {
    @media #{$mq-5} {
      margin-left: grid-spacing(5, 1, 1);
    }
    @media #{$mq-4} {
      margin-left: grid-spacing(4, 1, 1);
    }
    @media #{$mq-3} {
      margin-left: grid-spacing(3, 1, 1);
    }
    @media #{$mq-345} {
      display: flex;
    }
  }

  &__article-label,
  &__energy-label {
    @include paragraph-s;
    margin-bottom: spacing(2);
  }

  &__article-number {
    @include paragraph-s-bold;
  }

  &__energy-icon {
    width: auto;
    height: spacing(9);
  }

  &__article-wrapper {
    @media #{$mq-12} {
      margin-bottom: spacing(4);
    }
  }

  &__separator {
    width: 1px;
    height: inherit;
    margin-right: spacing(10);
    margin-left: spacing(10);
    background-color: var(--product-details-page-separator-color);
    @media #{$mq-12} {
      display: none;
    }
  }

  &__product-out-of-stock {
    display: flex;
    align-items: center;
    padding: spacing(1) spacing(4);
    margin: spacing(6) spacing(10) 0 0;
    background: var(--notification-warning);
    border-radius: spacing(2);
    color: var(--primary-frk-black);
    gap: spacing(4);
    @include paragraph-base;
    @media #{$mq-5} {
      margin-left: grid-spacing(5, 1, 1);
    }
    @media #{$mq-4} {
      margin-left: grid-spacing(4, 1, 1);
    }
    @media #{$mq-3} {
      margin-left: grid-spacing(3, 1, 1);
    }
    @media #{$mq-12} {
      margin: spacing(4) 0 0 0;
    }

    &--hasIcon {
      display: flex;
      font-size: spacing(8);
    }
  }

  &--is-without-price {
    @media #{$mq-345} {
      grid-template-rows: spacing(20) auto 1fr spacing(20);
    }

    .cmp-product-hero__gallery {
      @media #{$mq-345} {
        grid-row: 2 / 4;
      }
    }

    .cmp-product-hero__information {
      @media #{$mq-345} {
        grid-row: 1/3;
      }
    }
  }

  &__description {
    margin-top: spacing(10);

    @media #{$mq-5} {
      width: grid-spacing(5, 7, 6);
      margin-left: grid-spacing(5, 1, 1);
      @include paragraph-base;
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 7, 6);
      margin-left: grid-spacing(4, 1, 1);
      @include paragraph-base;
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 8, 7);
      margin-left: grid-spacing(3, 1, 1);
    }
    @media #{$mq-123} {
      @include paragraph-s;
    }
  }

  &--hasErrors {
    @media #{$mq-345} {
      padding-top: $header-height-desktop - $error-banner-height;
    }
    @media #{$mq-12} {
      padding-top: $header-height-mobile - $error-banner-height;
    }
  }
}
