.cmp-quote {
  @include with-max-width-center;
  position: relative;
  padding: spacing(26) 0 spacing(2) 0;
  text-align: left;
  @media #{$mq-3} {
    width: grid-spacing(3, 9, 8);
    margin-left: grid-spacing(3, 1, 1);
  }
  @media #{$mq-4} {
    width: grid-spacing(4, 8, 7);
    margin-left: grid-spacing(4, 1, 1);
  }

  @media #{$mq-5} {
    width: grid-spacing(5, 8, 7);
    margin-left: grid-spacing(5, 1, 1);
  }

  &__blockquote {
    margin: 0;

    &-text {
      color: var(--emotional-quote-text-color);
      @media #{$mq-12} {
        @include heading-l-extended-bold;
        margin-bottom: spacing(4);
      }
      @media #{$mq-3} {
        @include heading-xl-extended-bold;
      }
      @media #{$mq-45} {
        @include heading-xxl-extended-bold;
      }
      @media #{$mq-345} {
        margin-bottom: spacing(6);
      }
    }
  }

  &__name {
    margin-bottom: spacing(1);
    color: var(--emotional-quote-name-text-color);

    @media #{$mq-12} {
      @include heading-s-extra-bold;
    }

    @media #{$mq-345} {
      @include heading-base-extra-bold;
    }
  }

  &__title {
    color: var(--emotional-quote-title-text-color);
    @media #{$mq-12} {
      @include paragraph-s;
    }

    @media #{$mq-345} {
      @include paragraph-base;
    }
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: spacing(20);
    height: spacing(20);
    background: url('/etc.clientlibs/franke-aem/clientlibs/clientlib-site/resources/images/quote.svg') no-repeat center;
    content: '';
  }
}
