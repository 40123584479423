.cmp-toast {
  position: fixed;
  right: spacing(11);
  bottom: spacing(10);
  display: flex;
  display: none;
  align-items: center;
  padding: spacing(5) spacing(4);
  animation: 2.6s linear toast-fade-out; /* 2.6 seconds as with 2.5secs and dom updates timings it would flicker  */
  border-radius: spacing(2);

  &--success {
    background-color: var(--notification-success);
  }

  @media #{$mq-5} {
    right: grid-spacing(5, 0, 0, 1);
    min-width: grid-spacing(5, 5, 4);
  }
  @media #{$mq-4} {
    right: grid-spacing(4, 0, 0, 1);
    min-width: grid-spacing(4, 5, 4);
  }
  @media #{$mq-3} {
    right: grid-spacing(3, 0, 0, 1);
    min-width: grid-spacing(3, 5, 4);
  }
  @media #{$mq-2} {
    right: grid-spacing(2, 0, 0, 1);
    min-width: grid-spacing(2, 5, 4);
  }
  @media #{$mq-1} {
    right: grid-spacing(1, 0, 0, 1);
    bottom: spacing(6);
    left: grid-spacing(1, 0, 0, 1);
    padding: spacing(1);
    @include paragraph-s-bold;
  }

  &--is-visible {
    display: flex;
  }

  &__icon {
    margin-right: spacing(4);

    @media #{$mq-1} {
      margin-right: spacing(2);
    }
  }
}

@keyframes toast-fade-out {
  0% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
