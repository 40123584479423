.cmp-product-information {
  margin-bottom: spacing(34);
  @media #{$mq-12} {
    margin-bottom: spacing(20);
  }

  &__title {
    @include heading-xxl-extended-bold;
    margin-bottom: spacing(20);
    @media #{$mq-12} {
      @include heading-m-extended-bold;
      margin-bottom: spacing(10);
    }
    @media #{$mq-1} {
      width: 100%;
    }
    @media #{$mq-2} {
      width: 100%;
    }

    @media #{$mq-4} {
      margin-left: grid-spacing(4, 1, 1);
    }
    @media #{$mq-5} {
      margin-left: grid-spacing(5, 1, 1);
    }
  }

  &-energy-class {
    display: flex;
    align-items: center;
    @media #{$mq-12} {
      justify-content: space-between;
    }

    &__image-container {
      display: inline-block;
      width: grid-spacing(3, 2, 2);

      @media #{$mq-12} {
        width: auto;
      }
    }

    &__image {
      width: spacing(20);
      height: spacing(20);
      aspect-ratio: 1/1;
      object-fit: contain;
    }

    &__text {
      @include paragraph-base;
      display: inline-block;
      padding-left: spacing(4);
      @media #{$mq-12} {
        width: auto;
        padding-left: 0;
        @include paragraph-s;
      }
    }
  }

  .cmp-product-information__cta {
    display: block;
    margin-top: spacing(14);
    text-align: center;

    &-button {
      &--more,
      &--less {
        cursor: pointer;
        @include button(
          var(--product-functionalities-button-background-color),
          var(--product-functionalities-button-text-color),
          var(--product-functionalities-button-border-color),
          var(--product-functionalities-button-hover-background-color),
          var(--product-functionalities-button-hover-text-color),
          var(--product-functionalities-button-hover-border-color)
        );
      }

      &--hide {
        display: none;
      }
    }
  }

  &-table {
    overflow: auto;
    margin-top: spacing(3);
    margin-left: spacing(6);

    @media #{$mq-1} {
      margin-left: grid-spacing(1, 1, 1);
    }

    @media #{$mq-2} {
      margin-left: grid-spacing(2, 1, 1);
    }

    @media #{$mq-3} {
      width: grid-spacing(3, 10, 9);
      margin-left: grid-spacing(3, 1, 1);
    }

    @media #{$mq-4} {
      width: grid-spacing(4, 8, 7);
      margin-left: grid-spacing(4, 2, 2);
    }

    @media #{$mq-5} {
      width: grid-spacing(5, 8, 7);
      margin-left: grid-spacing(5, 2, 2);
    }

    &:not(:first-of-type) {
      @media #{$mq-1} {
        margin-right: calc(var(--page-container-side-margin-s) * -1);
        margin-left: 0;
      }
    }

    &__section-title {
      @include heading-base-extended-bold;
      margin: spacing(10) 0 spacing(6) 0;
    }

    &__section-list {
      padding: 0;
      margin: 0;

      &__item {
        padding: spacing(4) 0;
        @include paragraph-base-bold;

        &:not(:last-of-type) {
          border-bottom: 1px solid var(--product-information-table-item-border);
        }

        @media #{$mq-12} {
          display: flex;
          justify-content: space-between;
          @include paragraph-s;
        }

        &__name {
          display: inline-block;
          width: grid-spacing(3, 2, 2);
          @media #{$mq-12} {
            width: auto;
          }
        }

        &__value {
          display: inline-block;
          padding-left: spacing(4);
          @media #{$mq-12} {
            width: auto;
            padding-left: 0;
          }
        }
      }
    }

    &--hidden {
      :not(p):nth-child(n + 6) {
        display: none;
      }
    }

    &__performances {
      width: 100%;
      border-collapse: collapse;

      &-row {
        border: 1px solid var(--grayscale-frk-cool-grey-5);

        &:first-child {
          .cmp-product-information-table__performances-cell {
            @include heading-s-extended-bold;
          }
          background: var(--grayscale-frk-cool-grey-1);
        }
      }

      &-cell {
        padding: spacing(4) spacing(2);
        border: 1px solid var(--grayscale-frk-cool-grey-5);
        @include paragraph-base;
      }
    }
  }

  .cmp-download-list__item {
    width: 100%;
    padding-left: spacing(6);
    margin: 0;
  }

  .cmp-accordion {
    padding: 0;
    @media #{$mq-1} {
      width: 100%;
    }
    @media #{$mq-2} {
      width: 100%;
    }
    @media #{$mq-3} {
      margin-left: grid-spacing(3, 1, 1);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 8, 7);
      margin-left: grid-spacing(4, 2, 2);
    }
    @media #{$mq-5} {
      width: grid-spacing(5, 8, 7);
      margin-left: grid-spacing(5, 2, 2);
    }
  }
}

.cmp-product-information-download {
  &__title {
    @include heading-xxl-extended-bold;
    margin-bottom: spacing(20);
    @media #{$mq-12} {
      @include heading-m-extended-bold;
      margin-top: spacing(20);
      margin-bottom: spacing(10);
    }
    @media #{$mq-345} {
      margin-top: spacing(40);
    }
    @media #{$mq-1} {
      width: 100%;
      margin-left: grid-spacing(1, 0, 0);
    }
    @media #{$mq-2} {
      width: 100%;
      margin-left: grid-spacing(2, 0, 0);
    }
    @media #{$mq-3} {
      margin-left: grid-spacing(3, 0, 0);
    }
    @media #{$mq-4} {
      margin-left: grid-spacing(4, 1, 1);
    }
    @media #{$mq-5} {
      margin-left: grid-spacing(5, 1, 1);
    }
  }
}
