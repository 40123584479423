/* stylelint-disable no-descending-specificity */
.cmp-teaser {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column-reverse;

  &__image {
    grid-row: 1;

    .cmp-image {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: inherit;
      padding: 0;
      margin: 0;
      aspect-ratio: 4/3;

      &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.15s linear;
      }
    }
  }

  &__content {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding: spacing(6);
    background-color: var(--teaser-background-color);
    color: var(--teaser-text-color);
    overflow-wrap: anywhere;
  }

  &__pretitle {
    @include paragraph-base-bold;
  }

  &__title {
    @media #{$mq-12} {
      @include heading-base-extended-bold;
    }
    @media #{$mq-3} {
      @include heading-m-extended-bold;
    }
    @media #{$mq-45} {
      @include heading-l-extended-bold;
    }
    cursor: text;

    a {
      color: inherit;
      pointer-events: none;
      text-decoration: inherit;
    }
  }

  &__description {
    @media #{$mq-123} {
      @include paragraph-base;
    }
    @media #{$mq-45} {
      @include paragraph-lead-m;
    }

    .cmp-teaser__paragraph--lead {
      @media #{$mq-123} {
        @include paragraph-lead-m;
      }
      @media #{$mq-45} {
        @include paragraph-lead-l;
      }
    }

    ul {
      padding-left: 0;
      margin: spacing(6) 0;

      li {
        @include paragraph-base;
        position: relative;
        padding-left: spacing(4);
      }

      li::before {
        position: absolute;
        top: spacing(2);
        left: 0;
        width: spacing(2.5);
        height: spacing(2.5);
        background-color: var(--teaser-bullet-color);
        content: '';
      }
    }

    .cmp-teaser__download--link {
      @include heading-base-extra-bold;
      @include link;
      padding-bottom: spacing(6);
      @media #{$mq-45} {
        padding-bottom: spacing(12);
      }

      &::after {
        margin-left: spacing(1);
        content: '\ec5a';
        font-family: var(--remixicon-font);
        font-size: spacing(5);
        vertical-align: middle;
      }
    }
  }

  &__action-container {
    padding-bottom: spacing(6);
    margin-top: spacing(4);
  }

  &__action-link {
    @include heading-m-extended-bold;
    @include button(
      var(--button-background-color),
      var(--button-font-color),
      var(--button-border-color),
      var(--button-on-hover-focus-background-color),
      var(--button-on-hover-focus-font-color),
      var(--button-on-hover-focus-border-color)
    );

    .cmp-teaser--cta-link & {
      @include heading-base-extra-bold;
      @include link;
    }
  }

  &__link {
    text-decoration: none;

    &:hover,
    &:focus {
      .cmp-teaser__image {
        .cmp-image__image {
          transform: scale(1.1);
        }
      }

      .cmp-teaser__action-link {
        transform: translateX(5px);

        &::after {
          opacity: 0;
        }

        &::before {
          opacity: 1;
        }
      }

      .cmp-teaser__title {
        cursor: pointer;
      }
    }
  }
}
/* stylelint-disable no-duplicate-selectors */
.cmp-teaser {
  .cmp-teaser--cta-link & {
    &__action-link {
      @include link;
    }
  }

  .cmp-teaser--cta-button & {
    &__action-link {
      @include button;
    }
  }
}
