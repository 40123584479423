.category__root {
}

.category__root--staged {
}

.category__header {
}

.category__image {
}

.category__staged {
}

.category__title {
}

/* preconfigured productlist styles in page template policies */
.category__style_grid .productcollection__items {
}

.category__style_list .productcollection__items {
  display: block;
  width: 30%;
  margin: auto;
}
