.anchornavigation {
  &--generic {
    max-width: 100vw;

    .cmp-anchor-navigation {
      justify-content: center;
      padding: spacing(3);
      border-top: 1px solid var(--anchor-navigation-border-color);
      border-bottom: 1px solid var(--anchor-navigation-border-color);
      margin: spacing(12) 0;
      background: var(--primary-frk-white);
      gap: spacing(4);

      @media #{$mq-12} {
        scrollbar-width: none;
      }

      &__link {
        padding: spacing(3) 0;
        color: var(--grayscale-frk-cool-gray-11);
        text-decoration: none;
        white-space: nowrap;
        @include anchor-navigation-link-generic;

        &--active {
          border-bottom: spacing(1) solid var(--anchor-navigation-active-color);
          color: var(--primary-frk-black);
        }
      }

      &--overflowing {
        justify-content: flex-start;
        overflow-x: scroll;
        overflow-y: hidden;
      }

      &--sticky {
        right: 0;
        width: 100%;
      }
    }
  }

  &--water-hub {
    width: 100%;
    max-width: var(--page-max-width);
    margin: 0 auto;

    .cmp-anchor-navigation {
      width: fit-content;
      height: auto;
      padding: spacing(3) spacing(6);
      margin: spacing(10) 0 0 auto;
      background: var(--gradations-frk-lavender-50);
      gap: spacing(1) spacing(6);

      @media #{$mq-123} {
        display: flex;
        width: 100%;
        flex-flow: wrap;
        justify-content: center;
        margin: 0;
      }

      &__link-wrapper {
        display: inline-flex;
      }

      &__link {
        @include anchor-navigation-link-waterhub;
        border-bottom: 2px solid transparent;
        color: var(--secondary-frk-blueberry);
        text-align: center;
        text-decoration: none;
        @media #{$mq-345} {
          &:hover,
          &:focus {
            border-bottom: 2px solid var(--secondary-frk-blueberry);
            cursor: pointer;
          }
        }
      }

      &__link--active {
        border-bottom: 2px solid var(--secondary-frk-blueberry);
      }
    }
  }

  .cmp-anchor-navigation {
    z-index: 3;
    display: flex;

    &--sticky {
      position: fixed;
      top: 0;
      margin: 0 0 0 auto;
    }

    &--transition {
      transition: top 0.4s ease-in-out;
    }
  }
}
