* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  background-color: var(--html-body-background-color);
  font-family: var(--typography-font);
}

body.no-scroll {
  overflow-x: hidden;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

img {
  width: 100%;
  height: auto;
}

li {
  list-style: none;
}

span,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

ol {
  padding-inline-start: 0;
}
