.cmp-animated-teaser {
  position: relative;
  display: flex;
  margin: 0 auto;
  @media #{$mq-12} {
    flex-flow: column;
  }
  @media #{$mq-345} {
    overflow: hidden;
  }

  &__image {
    @media #{$mq-345} {
      width: 50%;
    }
    @media #{$mq-12} {
      width: 100%;
    }

    &--animated {
      top: 0;
      left: 100%;
      aspect-ratio: 1;
      clip-path: inset(0% 30% 0% 30%);
      object-fit: cover;
      @media #{$mq-345} {
        position: absolute;
        transition: left 2s ease-in-out;
      }
      @media #{$mq-12} {
        width: 100%;
      }
      @media #{$mq-2} {
        margin-top: spacing(12);
      }
      @media #{$mq-1} {
        margin-top: spacing(10);
      }
    }

    &--background {
      aspect-ratio: 1;
      transition: filter 2s ease-in-out;
    }
  }

  &__content {
    @media #{$mq-345} {
      display: flex;
      width: 50%;
      align-items: center;
      padding: 0 spacing(6);
      gap: spacing(6);
      transform: translateX(0);
      transition: transform 2s ease-in-out, filter 2s ease-in-out;
    }
    @media #{$mq-12} {
      top: 100%;
      background: var(--primary-frk-white);
      transition: top 2s ease-in-out;
    }
  }

  &__title {
    @include animated-teaser-title;
    word-break: break-word;
  }

  &__link {
    @include heading-base-extended-bold;
    @include link(var(--primary-frk-vibrant-red));
  }

  &__text {
    @include paragraph-lead-xl;
    margin: spacing(4) 0;
    word-break: break-word;
    @media #{$mq-123} {
      @include paragraph-lead-m;
    }
  }

  &--has-animation {
    .cmp-animated-teaser__image {
      &--background {
        filter: opacity(0);
      }

      &--has-scale {
        animation: reveal-image 1s ease-out 1s forwards;
      }
    }

    .cmp-animated-teaser__content {
      @media #{$mq-345} {
        transform: translateX(-100%);
      }
      @media #{$mq-12} {
        position: absolute;
        top: 0;
      }
    }
  }

  &--reversed {
    .cmp-animated-teaser {
      @media #{$mq-345} {
        flex-flow: row-reverse;
      }

      &__image {
        &--animated {
          left: -100%;
        }
      }

      &--has-animation {
        .cmp-animated-teaser__content {
          @media #{$mq-345} {
            transform: translateX(100%);
          }
        }
      }
    }
  }

  @keyframes reveal-image {
    to {
      clip-path: inset(0% 0% 0% 0%);
    }
  }
}
