.cmp-floater-bar {
  &__container {
    position: fixed;
    z-index: 1;
    right: 0;
    bottom: spacing(16);
    padding: 0 spacing(2);
    background-color: var(--floater-bar-container-background-color);
  }

  &__actions {
    overflow: hidden;
  }

  &__item,
  &__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: spacing(3) 0;
    color: var(--floater-bar-item-color);
    cursor: pointer;

    @media #{$mq-3},
    #{$mq-45} {
      font-size: $icon-width-medium;
      line-height: $icon-height-medium;
    }

    @media #{$mq-12} {
      font-size: $icon-width-small;
      line-height: $icon-height-small;
    }
  }

  &__item {
    &:not(:first-child) {
      border-top: 1px solid var(--floater-bar-item-border-color);
    }
    text-decoration: none;

    .cmp-button-link {
      aspect-ratio: 1 / 1;
    }

    .cmp-button-link__icon {
      margin-right: 0;
    }
  }

  &__toggle {
    border-top: 1px solid var(--floater-bar-toggle-border-color);

    &-icon {
      aspect-ratio: 1 / 1;
      transition: all 0.2s ease-in-out;
    }

    &-closed {
      .cmp-floater-bar__toggle-icon {
        transform: rotate(45deg);
      }
    }
  }
}

.cmp-react-accordion {
  .cmp-floater-bar__item {
    border-top: 1px solid var(--floater-bar-item-border-color);
  }
}
