.cmp-multi-select-listbox {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media #{$mq-345} {
    justify-content: space-between;
  }

  > :first-child {
    /* remove the label's box for good layouting */
    display: none;
  }

  &__item--info-button {
    position: relative;
    padding: 0;
    border: none;
    margin: 0;
    background-color: var(--product-explorer-multi-select-listbox-info-button-background-color);
    color: var(--product-explorer-multi-select-listbox-info-button-text-color);
    cursor: pointer;
    font-size: 24px;
  }
}

.cmp-listbox {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;

  @media #{$mq-345} {
    max-height: spacing(70);
    overflow-y: scroll;
  }
}

.multi-select-listbox-option {
  cursor: pointer;

  @media #{$mq-12} {
    @include paragraph-s;
    padding: spacing(2) spacing(8) spacing(2) spacing(14);
  }

  @media #{$mq-345} {
    @include paragraph-base;
    padding: spacing(3) spacing(4);
  }

  &--selected {
    .cmp-multi-select-listbox__item-selection {
      font-size: 24px;
      visibility: visible;
    }
  }
}

.cmp-multi-select-listbox__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include paragraph-s;
}

.cmp-multi-select-listbox__item-description {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
}

.cmp-multi-select-listbox__item-selection {
  margin-left: spacing(2);
  visibility: hidden;
}

.cmp-multi-select-listbox__image {
  width: auto;
  height: spacing(6);
  margin-right: spacing(2);
}

.cmp-multi-select-listbox__action-container {
  display: flex;
  justify-content: space-between;

  @media #{$mq-12} {
    margin: spacing(10) spacing(8);
  }
}

.cmp-multi-select-listbox__action {
  @include heading-base-extended-bold;
  width: 50%;
  padding: spacing(3) spacing(4);
  border: $form-border-width solid var(--product-explorer-multi-select-listbox-action-border-color);
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  text-align: center;

  @media #{$mq-345} {
    border: transparent;
    border-top: $form-border-width solid var(--product-explorer-multi-select-listbox-action-border-color);
  }

  &--apply {
    background: var(--product-explorer-multi-select-listbox-action-apply-background-color);
    color: var(--product-explorer-multi-select-listbox-action-apply-text-color);
  }

  &--reset {
    background: var(--product-explorer-multi-select-listbox-action-reset-background-color);
    color: var(--product-explorer-multi-select-listbox-action-reset-text-color);
  }
}
