.cmp-hotspots-slider__container {
  @media #{$mq-12} {
    @include remove-side-margins;
  }

  .cmp-hotspot-grid {
    position: relative;
    padding: 0;
    margin-top: spacing(6);
    transform-style: preserve-3d;
    vertical-align: top;
    @media #{$mq-5} {
      padding: 0 grid-spacing(5, 2, 2);
    }
    @media #{$mq-4} {
      padding: 0 grid-spacing(4, 2, 2);
    }
    @media #{$mq-3} {
      padding: 0 grid-spacing(3, 1, 1);
    }

    &::before {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: repeating-linear-gradient(0deg, transparent, #ccc 0, transparent 3px, transparent 10%),
        repeating-linear-gradient(-90deg, transparent, #ccc 0, transparent 3px, transparent 10%);
      content: '';
      pointer-events: none;
      @media #{$mq-5} {
        right: grid-spacing(5, 2, 2);
        left: grid-spacing(5, 2, 2);
      }
      @media #{$mq-4} {
        right: grid-spacing(4, 2, 2);
        left: grid-spacing(4, 2, 2);
      }
      @media #{$mq-3} {
        right: grid-spacing(3, 1, 1);
        left: grid-spacing(3, 1, 1);
      }
    }

    &--top {
      position: relative;
      width: 100%;
      margin-left: -(spacing(3));

      .cmp-hotspot-grid__percentage {
        margin-top: -(spacing(5));
      }
    }

    &--left {
      height: 100%;
      margin-left: -(spacing(8));

      .cmp-hotspot-grid__percentage {
        margin-top: -(spacing(2));
      }
    }

    &__percentage {
      position: absolute;
    }
  }
}

.cmp-hotspots-slider {
  position: relative;
  @media #{$mq-5} {
    width: grid-spacing(5, 8, 7);

    &:not(&--editMode) {
      margin: 0 grid-spacing(5, 2, 2);
    }
  }
  @media #{$mq-4} {
    width: grid-spacing(4, 8, 7);

    &:not(&--editMode) {
      margin: 0 grid-spacing(4, 2, 2);
    }
  }
  @media #{$mq-3} {
    width: grid-spacing(3, 10, 9);

    &:not(&--editMode) {
      margin: 0 grid-spacing(3, 1, 1);
    }
  }
  @media #{$mq-12} {
    width: 100%;
    margin: 0;
  }

  &__slider {
    &:focus-visible {
      outline: 1px solid var(--primary-frk-vibrant-red);
    }

    &:focus-within {
      outline: none; /* stylelint-disable-line a11y/no-outline-none */
    }
  }

  .cmp-hotspots-slide__image .cmp-responsive-image__image {
    display: block;
  }

  .cmp-hotspots-slide__hotspot-container {
    position: absolute;

    &--is-active {
      .cmp-hotspots-slide__hotspot-dot {
        width: spacing(4.5);
        height: spacing(4.5);

        @media #{$mq-345} {
          width: spacing(9);
          height: spacing(9);
        }
      }
    }
  }

  .cmp-hotspots-slide__hotspot {
    display: flex;
    width: spacing(6);
    height: spacing(6);
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    background-color: var(--transparency-frk-white-50-trans);
    border-radius: 50%;
    cursor: pointer;

    @media #{$mq-345} {
      width: spacing(12);
      height: spacing(12);
    }

    @media #{$mq-12} {
      cursor: initial;
    }

    &::after {
      width: spacing(4.5);
      height: spacing(4.5);
      border: 1px solid var(--primary-frk-vibrant-red);
      background-color: var(--transparency-frk-white-50-trans);
      border-radius: 50%;
      content: '';

      @media #{$mq-345} {
        width: spacing(9);
        height: spacing(9);
        border-width: 2px;
      }
    }

    &-dot {
      position: absolute;
      display: flex;
      width: spacing(1);
      height: spacing(1);
      align-items: center;
      justify-content: center;
      background-color: var(--primary-frk-vibrant-red);
      border-radius: 50%;
      transition: all 200ms ease-in-out;

      @media #{$mq-345} {
        width: spacing(2.5);
        height: spacing(2.5);
      }
    }
  }

  .cmp-responsive-image__outer-wrapper.cmp-hotspots-slide__image {
    aspect-ratio: 16/9;
    @media #{$mq-5} {
      width: grid-spacing(5, 8, 7);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 8, 7);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 10, 9);
    }
    @media #{$mq-2} {
      width: grid-spacing(2, 12, 11, 2);
    }
    @media #{$mq-1} {
      width: grid-spacing(1, 12, 11, 2);
    }
  }

  .cmp-hotspots-slider__button {
    position: absolute;
    top: calc(50% - spacing(3));
    display: flex;
    width: spacing(6);
    height: spacing(6);
    align-items: center;
    justify-content: center;
    border: none;
    background-color: var(--hotspots-slider-button-background);
    color: var(--hotspots-slider-button-font-color);
    cursor: pointer;
    @include paragraph-s-bold;

    @media #{$mq-345} {
      top: calc(50% - spacing(6));
      width: spacing(12);
      height: spacing(12);
      @include heading-m-extended-bold;
    }
  }

  .cmp-hotspots-slider__button--left {
    left: 0;
  }

  .cmp-hotspots-slider__button--right {
    right: 0;
  }
}

.cmp-hotspots-slider__hotspots-thumbnails {
  position: relative;
  display: flex;
  min-height: spacing(6);
  flex-grow: 0;
  align-items: flex-start;
  justify-content: center;
  margin: -(spacing(6)) auto 0 auto;
  transition: min-height 200ms cubic-bezier(0, 1, 0, 1);

  @media #{$mq-5} {
    width: grid-spacing(5, 3, 2);
    gap: spacing(6);
  }
  @media #{$mq-4} {
    width: grid-spacing(4, 3, 2);
    gap: spacing(6);
  }
  @media #{$mq-3} {
    width: grid-spacing(3, 4, 3);
    gap: grid-spacing(3, 0, 1);
  }
  @media #{$mq-2} {
    width: grid-spacing(2, 4, 3, 1);
    gap: grid-spacing(2, 0, 1);
  }
  @media #{$mq-1} {
    width: grid-spacing(1, 12, 11);
    gap: grid-spacing(1, 0, 1);
    grid-row: 2;
  }

  @media #{$mq-12} {
    margin: spacing(2) auto 0 auto;
  }
}

.cmp-hotspots-slider__thumbnail {
  padding: 0;
  border: solid transparent 2px;
  aspect-ratio: 1;
  background-color: var(--primary-frk-white);
  box-shadow: none;
  cursor: pointer;

  @media #{$mq-5} {
    width: calc((grid-spacing(5, 3, 2) - 3 * spacing(6)) / 4);
    height: calc((grid-spacing(5, 3, 2) - 3 * spacing(6)) / 4);
  }
  @media #{$mq-4} {
    width: calc((grid-spacing(4, 3, 2) - 3 * spacing(6)) / 4);
    height: calc((grid-spacing(4, 3, 2) - 3 * spacing(6)) / 4);
  }
  @media #{$mq-3} {
    width: calc((grid-spacing(3, 4, 3) - 3 * grid-spacing(3, 0, 1)) / 4);
    height: calc((grid-spacing(3, 4, 3) - 3 * grid-spacing(3, 0, 1)) / 4);
  }
  @media #{$mq-2} {
    width: grid-spacing(2, 1, 0);
    height: grid-spacing(2, 1, 0);
  }
  @media #{$mq-1} {
    width: grid-spacing(1, 2, 1);
    height: grid-spacing(1, 2, 1);
  }

  &--is-hidden {
    visibility: hidden;
  }

  &--is-selected {
    border-color: var(--hotspots-slider-thumbnail-selected-outline);
  }
}

.cmp-hotspots-slide {
  position: relative;
  aspect-ratio: 16 / 9;
}
