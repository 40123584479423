.white-theme {
  --basic-hero-background-color: var(--primary-frk-white);
  --basic-hero-text-color: var(--primary-frk-black);
  --basic-hero-title-color: var(--primary-frk-vibrant-red);
  --breadcrumbs-background-color: var(--primary-frk-white);
  --breadcrumbs-home-icon-color: var(--primary-frk-black);
  --breadcrumbs-icon-color: var(--transparency-frk-black-50-trans);
  --breadcrumbs-text-color: var(--primary-frk-black);
  --bullet-color: var(--primary-frk-vibrant-red);
  --bullet-color-in-basic-hero: var(--primary-frk-black);
  --button-background-color: var(--primary-frk-vibrant-red);
  --button-border-color: transparent;
  --button-font-color: var(--primary-frk-white);
  --button-on-hover-focus-background-color: var(--gradations-frk-vibrant-red-20);
  --button-on-hover-focus-border-color: transparent;
  --button-on-hover-focus-font-color: var(--primary-frk-white);
  --card-teaser-background-color: var(--primary-frk-white);
  --card-teaser-button-background-color: var(--primary-frk-vibrant-red);
  --card-teaser-button-border-color: var(--primary-frk-vibrant-red);
  --card-teaser-button-font-color: var(--primary-frk-white);
  --card-teaser-button-on-hover-focus-background-color: var(--gradations-frk-vibrant-red-20);
  --card-teaser-button-on-hover-focus-border-color: var(--gradations-frk-vibrant-red-20);
  --card-teaser-button-on-hover-focus-font-color: var(--primary-frk-white);
  --card-teaser-description-font-color: var(--primary-frk-black);
  --card-teaser-pretitle-font-color: var(--primary-frk-black);
  --card-teaser-title-font-color: var(--primary-frk-black);
  --header-background-color: var(--primary-frk-white);
  --header-font-color: var(--primary-frk-black);
  --header-divider-color: var(--transparency-frk-black-50-trans);

  // hotspots
  --hotspots-slider-button-background: var(--primary-frk-vibrant-red);
  --hotspots-slider-button-font-color: var(--primary-frk-white);
  --hotspots-slider-text-background: var(--primary-frk-white);
  --hotspots-slider-text-color: var(--primary-frk-black);
  --hotspots-slider-thumbnail-selected-outline: var(--primary-frk-black);

  // Hero
  --hero-gradient-desktop: linear-gradient(90deg, rgb(255 255 255 / 25%) 0%, rgb(255 255 255 / 0%) 100%);
  --hero-gradient-mobile: linear-gradient(0deg, rgb(255 255 255 / 25%) 0%, rgb(255 255 255 / 0%) 100%);
  --link-font-color: var(--primary-frk-vibrant-red);
  --language-switcher-in-nav-color: var(--grayscale-frk-cool-grey-5);
  --slider-hero-background-color: var(--primary-frk-white);
  --slider-hero-button-background-color: var(--primary-frk-vibrant-red);
  --slider-hero-button-border-color: transparent;
  --slider-hero-button-font-color: var(--primary-frk-white);
  --slider-hero-button-hover-background-color: var(--gradations-frk-vibrant-red-20);
  --slider-hero-button-hover-border-color: transparent;
  --slider-hero-button-hover-font-color: var(--primary-frk-white);
  --slider-hero-text-color: var(--primary-frk-black);
  --slider-hero-timer-background-color: var(--grayscale-frk-black-5);
  --slider-hero-timer-progress-color: var(--primary-frk-vibrant-red);
  --storytelling-teaser-background: var(--primary-frk-white);
  --storytelling-teaser-description-color: var(--primary-frk-black);
  --storytelling-teaser-pre-title-color: var(--primary-frk-black);
  --storytelling-teaser-subtitle-color: var(--primary-frk-black);
  --storytelling-teaser-title-color: var(--primary-frk-vibrant-red);
  --storytelling-teaser-description-color-invert: var(--primary-frk-black);
  --teaser-background-color: var(--primary-frk-white);
  --teaser-bullet-color: var(--primary-frk-vibrant-red);
  --teaser-text-color: var(--primary-frk-black);
  --text-emotional-background-color: var(--primary-frk-white);
  --text-emotional-description-color: var(--primary-frk-black);
  --text-emotional-title-color: var(--primary-frk-vibrant-red);
  --product-wishlist-counter: var(--grayscale-frk-cool-grey-5);

  // Entry Teasers
  --entry-teaser-background-color: var(--primary-frk-white);
  --entry-teaser-text-color: var(--primary-frk-black);
  --entry-teaser-active-color: var(--primary-frk-vibrant-red);
  --entry-teaser-button-background-color: var(--primary-frk-vibrant-red);
  --entry-teaser-button-text-color: var(--primary-frk-white);
  --entry-teaser-button-hover-background-color: var(--gradations-frk-vibrant-red-20);
}
