.cmp-header {
  @include with-max-width-center;
  @include with-left-and-right-spacing;
  position: fixed;
  z-index: 4;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  height: 100%;
  @media print {
    .wishlist-print & {
      position: relative;
    }

    .wishlist-print--shared & {
      display: none;
    }
  }

  // align-items: center;
  justify-content: space-between;
  background-color: var(--header-background-color);
  color: var(--header-font-color);
  transition: background-color 0.1s linear, top 0.4s ease-in-out, color 0.1s linear;

  @media #{$mq-123} {
    height: $header-height-mobile;
    padding-top: spacing(7);
    padding-bottom: spacing(2);

    // TODO: this should be set by a class on body. Eg. page template
    margin-bottom: -$header-height-mobile;
  }

  @media #{$mq-45} {
    height: $header-height-desktop;
    padding-top: spacing(6.5);
    padding-bottom: spacing(4);

    // TODO: this should be set by a class on body. Eg. page template
    margin-bottom: -$header-height-desktop;
  }

  .cmp-separator {
    width: 1px;
    height: spacing(9);
    background-color: var(--header-divider-color);
  }

  &--is-opened,
  &--is-scrolled {
    color: var(--header-font-color-on-hover);

    .cmp-separator {
      background-color: var(--transparency-frk-black-50-trans);
    }
  }

  &--is-scrolled {
    background-color: var(--header-background-color-on-scroll);

    .logo--white & {
      .cmp-logo__foreground {
        filter: none;
      }

      .cmp-logo__background {
        filter: grayscale(100%) brightness(0) invert(1);
      }
    }
  }

  &--is-opened {
    z-index: 4;
    background-color: var(--header-background-color-on-open);

    .cmp-header__menu-item--hide-on-open {
      opacity: 0;
    }

    @media print {
      position: relative;
      display: flex;
      flex-flow: column;

      .cmp-header__menu-item--close {
        display: none;
      }
    }
  }

  &__menu-item--hide-on-open {
    opacity: 1;
    transition: opacity 0.15s ease-in-out;
  }

  &--hide {
    @media #{$mq-123} {
      top: -$header-height-mobile;
    }

    @media #{$mq-45} {
      top: -$header-height-desktop;
    }
  }

  &__menu-items {
    display: flex;
    align-items: center;
    padding: 0;
    @media #{$mq-12} {
      margin-top: spacing(1);
    }
  }

  &__menu-item {
    @include paragraph-base-bold;
    z-index: 1; // so when navigation teaser comes in, text stays on top
    cursor: pointer;
    list-style: none;
    text-decoration: none;

    &:not(:first-of-type) {
      @media #{$mq-45} {
        margin-left: spacing(2.5);
      }
      @media #{$mq-123} {
        margin-left: spacing(5);
      }
    }

    &:first-of-type {
      ~ .cmp-header__menu-item--is-language-switcher {
        @media #{$mq-45} {
          margin-left: spacing(8);
        }
      }
    }

    &--is-language-switcher {
      display: flex;

      > .cmp-header__menu-item:not(:first-of-type) {
        margin-left: spacing(3);
      }
    }

    &--hide-on-mobile {
      @media #{$mq-123} {
        display: none;
      }
    }

    &--hide-on-desktop {
      @media #{$mq-45} {
        display: none;
      }
    }

    &--search,
    &--wishlist {
      padding-left: spacing(9);

      @media #{$mq-123} {
        padding: 0;

        .cmp-button-link__menu-item-content {
          display: none;
        }
      }
    }

    &--wishlist {
      .cmp-button-link__menu-item-content {
        color: var(--product-wishlist-counter);
      }
    }

    &--wishlistBlink {
      position: relative;

      &::after {
        position: absolute;
        top: 50%;
        left: 15%;
        width: spacing(23);
        height: spacing(28);
        animation: blink-animation 2s ease-in-out;
        background-color: var(--secondary-frk-champagne);
        content: '';
        transform: translateY(-50%);
        @media #{$mq-3} {
          left: -50%;
          width: spacing(15);
          height: spacing(27);
        }
        @media #{$mq-12} {
          left: -44%;
          width: spacing(15);
          height: spacing(23);
        }
      }

      .cmp-button-link {
        position: inherit;
        z-index: 1;
      }
      @keyframes blink-animation {
        0% {
          opacity: 0;
        }

        50% {
          opacity: 1;
        }

        100% {
          opacity: 0;
        }
      }
    }
  }

  &--is-white {
    .cmp-header {
      background-color: var(--header-default-background-color) !important;
      color: var(--header-default-font-color);
    }

    .cmp-separator {
      background-color: var(--transparency-frk-black-50-trans);
    }
  }
}

.cmp-header.cmp-header--invert-font-color {
  @media #{$mq-45} {
    color: var(--primary-frk-white);
  }
}

.cmp-header--is-opened.cmp-header-country-language-switcher--is-opened,
.cmp-header--is-opened.cmp-header-search--is-opened,
.cmp-header--is-opened.cmp-product-wishlist--is-opened {
  .cmp-header__menu-item {
    .cmp-button-link__menu-item-content {
      display: none;
    }
  }

  @media #{$mq-45} {
    color: var(--header-font-color-opened);
  }
}
