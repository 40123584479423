@mixin with-grid() {
  display: grid;
  @media #{$mq-1} {
    gap: grid-spacing(1, 0, 1);
  }
  @media #{$mq-2} {
    gap: grid-spacing(2, 0, 1);
  }
  @media #{$mq-3} {
    gap: grid-spacing(3, 0, 1);
  }
  @media #{$mq-4} {
    gap: grid-spacing(4, 0, 1);
  }
  @media #{$mq-5} {
    gap: grid-spacing(5, 0, 1);
  }
}

.cmp-teaser-container {
  @include with-grid;

  &--one-column,
  &--one-column-image-on-left-side,
  &--one-column-alternate {
    display: grid;

    // Fix misuse of one-column with more than 1 element
    flex-direction: column;

    .cmp-teaser {
      display: grid;
      align-items: center;
      justify-content: center;

      @media #{$mq-12} {
        grid-template-columns: repeat(1, 1fr);
      }
      @media #{$mq-345} {
        grid-template-columns: repeat(2, 1fr);
      }

      &:not(:last-of-type) {
        align-content: start;
        margin-bottom: spacing(10);
      }

      &__content {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        @media #{$mq-12} {
          padding-top: spacing(6);
        }
        @media #{$mq-345} {
          grid-column: 1;
          grid-row: 1;
        }
        @media #{$mq-3} {
          width: grid-spacing(3, 6, 6);
          padding: 0 grid-spacing(3, 1, 1);
        }
        @media #{$mq-4} {
          width: grid-spacing(4, 6, 6);
          padding: 0 grid-spacing(4, 1, 1);
        }
        @media #{$mq-5} {
          width: grid-spacing(5, 6, 6);
          padding: 0 grid-spacing(5, 1, 1);
        }
      }

      &__image {
        @media #{$mq-345} {
          grid-column: 2;
        }
      }
    }
  }

  &--one-column-alternate {
    .cmp-teaser:nth-child(odd) {
      .cmp-teaser__content {
        grid-column: 2;
        @media #{$mq-12} {
          grid-column: 1;
        }
      }

      .cmp-teaser__image {
        grid-column: 1;
      }
    }
  }

  &--one-column-image-on-left-side {
    .cmp-teaser {
      &__content {
        @media #{$mq-345} {
          grid-column: 2;
        }
      }

      &__image {
        @media #{$mq-345} {
          grid-column: 1;
        }
      }
    }
  }

  &--two-columns {
    @media #{$mq-345} {
      grid-template-columns: repeat(2, 1fr);
    }

    .cmp-image {
      aspect-ratio: 16/9;
      @media #{$mq-123} {
        aspect-ratio: 4/3;
      }
    }
  }

  &--three-columns {
    @media #{$mq-3} {
      grid-template-columns: repeat(2, 1fr);
    }
    @media #{$mq-45} {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &--one-column,
  &--one-column-alternate,
  &--three-columns,
  &--one-column-image-on-left-side {
    .cmp-image {
      aspect-ratio: 4/3;
    }
  }

  &--four-columns {
    .cmp-image {
      aspect-ratio: 4/3;
    }
    @media #{$mq-3} {
      grid-template-columns: repeat(2, 1fr);
    }
    @media #{$mq-45} {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
