.cmp-icon-grid {
  &__content {
    position: relative;
    @media #{$mq-3} {
      margin-right: grid-spacing(3, 1);
      margin-left: grid-spacing(3, 1);
    }
    @media #{$mq-4} {
      margin-right: grid-spacing(4, 1, 1);
      margin-left: grid-spacing(4, 1, 1);
    }
    @media #{$mq-5} {
      margin-right: grid-spacing(5, 1, 1);
      margin-left: grid-spacing(5, 1, 1);
    }
  }

  &__border-overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    box-shadow: 0 0 0 1px var(--icon-grid-border-overlay-color) inset, 0 0 0 1px var(--icon-grid-border-overlay-color);
    pointer-events: none;
  }

  &__item {
    flex-grow: 1;
    box-shadow: 0 0 0 0.5px var(--icon-grid-item-box-shadow-color);
    @media #{$mq-12} {
      &:last-child {
        padding-bottom: 0;
      }
    }
    @media #{$mq-1} {
      padding-top: spacing(10);
      padding-bottom: spacing(10);
    }
    @media #{$mq-2} {
      padding-top: spacing(10);
      padding-bottom: spacing(10);
    }
    @media #{$mq-3} {
      padding: grid-spacing(3, 0, 1) spacing(10);
    }
    @media #{$mq-4} {
      padding: grid-spacing(4, 0, 1) spacing(10);
    }
    @media #{$mq-5} {
      padding: grid-spacing(5, 0, 1) spacing(10);
    }

    &-title {
      @include heading-m-extra-bold;
    }

    &-description {
      @include paragraph-base;
      margin-top: spacing(2);
      word-break: break-word;
    }

    &-image {
      width: 80px;
      height: 80px;
      margin-bottom: spacing(6);
    }
  }

  &__cta-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: spacing(20);
  }

  &__cta {
    @include button(
      var(--icon-grid-button-background-color),
      var(--icon-grid-button-font-color),
      var(--icon-grid-button-border-color),
      var(--icon-grid-button-on-hover-focus-background-color),
      var(--icon-grid-button-on-hover-focus-font-color),
      var(--icon-grid-button-on-hover-focus-border-color)
    );
  }

  &__column {
    display: flex;
    @media #{$mq-12} {
      flex-direction: column;
    }
    @media #{$mq-345} {
      flex-wrap: wrap;
    }

    &--one-column {
      // This is to make sure any misuse of the one-column will be handled. Eg. more than 1 element
      flex-direction: column;

      .cmp-icon-grid__item {
        @media #{$mq-345} {
          padding-left: 0;
        }
      }
    }

    &--two-columns {
      .cmp-icon-grid__item {
        @media #{$mq-345} {
          flex-basis: 50%;
        }
        @media #{$mq-345} {
          &:nth-child(odd) {
            padding-left: 0;
          }

          &:nth-child(even) {
            padding-right: 0;
          }
        }
      }
    }

    &--three-columns {
      .cmp-icon-grid__item {
        @media #{$mq-3} {
          &:nth-child(odd) {
            padding-left: 0;
          }

          &:nth-child(even) {
            padding-right: 0;
          }
        }
        @media #{$mq-45} {
          &:nth-child(3n + 1) {
            padding-left: 0;
          }

          &:nth-child(3n),
          &:last-child {
            padding-right: 0;
          }
        }
        @media #{$mq-45} {
          flex-basis: 33.3333%;
        }
        @media #{$mq-3} {
          flex-basis: 50%;
        }
      }
    }
  }
}
