.entrytabsteaser {
  @media #{$mq-123} {
    &::after {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--entry-teaser-background-color);
      content: '';
    }
  }
}

.cmp-tabs-teaser {
  position: relative;
  display: grid;
  margin: 0 auto;
  background-color: var(--entry-teaser-background-color);
  @media #{$mq-5} {
    width: grid-spacing(5, 12, 11);
    padding: spacing(30) 0;
    gap: spacing(10);
  }
  @media #{$mq-4} {
    width: grid-spacing(4, 12, 11);
    padding: spacing(30) 0;
    gap: spacing(10);
  }
  @media #{$mq-3} {
    width: grid-spacing(3, 12, 11);
    padding: spacing(14) 0;
    gap: spacing(8);
  }
  @media #{$mq-2} {
    width: grid-spacing(2, 12, 11);
  }
  @media #{$mq-1} {
    width: grid-spacing(1, 12, 11);
  }
  @media #{$mq-12} {
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw;
    box-sizing: border-box;
    padding: spacing(14) 0;
    gap: spacing(8);
    transform: translateX(-50%);
  }

  &__title {
    @include entry-tabs-teasers-title;
    color: var(--entry-teaser-text-color);
    text-align: center;
    @media #{$mq-5} {
      width: grid-spacing(5, 6, 5);
      margin-left: grid-spacing(5, 3, 3);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 6, 5);
      margin-left: grid-spacing(4, 3, 3);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 8, 7);
      margin: 0 auto;
    }
    @media #{$mq-2} {
      width: grid-spacing(2, 12, 11);
      margin: 0 auto;
    }
    @media #{$mq-1} {
      width: grid-spacing(1, 12, 11);
      margin: 0 auto;
    }
  }

  &__image {
    overflow: hidden;
    aspect-ratio: 8 / 3;
    @media #{$mq-5} {
      width: grid-spacing(5, 8, 7);
      margin-left: grid-spacing(5, 2, 2);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 8, 7);
      margin-left: grid-spacing(4, 2, 2);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 10, 9);
      margin: 0 auto;
    }
    @media #{$mq-2} {
      width: grid-spacing(2, 12, 11);
      margin: 0 auto;
    }
    @media #{$mq-1} {
      width: grid-spacing(1, 12, 11);
      margin: 0 auto;
    }
  }

  &__tabs {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    padding: 0 spacing(12);
    margin: 0 auto;
    gap: spacing(6) spacing(10);
    @media #{$mq-5} {
      width: grid-spacing(5, 12, 11);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 12, 11);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 12, 11);
    }
    @media #{$mq-2} {
      width: grid-spacing(2, 12, 11);
    }
    @media #{$mq-1} {
      width: grid-spacing(1, 12, 11);
      padding: 0;
      gap: spacing(2) spacing(4);
    }

    &__tab {
      display: flex;
      align-items: center;
      padding-bottom: spacing(4);
      border-bottom: 2px solid transparent;
      gap: spacing(2);
      @media #{$mq-1} {
        flex: 45%;
      }

      &-title {
        @include paragraph-base;
        color: var(--entry-teaser-text-color);
      }

      &-icon {
        width: spacing(8);
        height: spacing(8);
      }

      &:hover,
      &:focus {
        border-bottom: 2px solid var(--entry-teaser-active-color);
        cursor: pointer;
      }

      &--is-active {
        border-bottom: 2px solid var(--entry-teaser-active-color);
      }
    }
  }

  &__content {
    &__tab {
      display: none;

      &-link {
        @include link;
        @include heading-base-extended-bold;
        display: flex;
        justify-content: center;
        color: var(--entry-teaser-text-color);
        gap: spacing(1);
        text-decoration: none;
      }

      &--is-active {
        display: block;
      }
    }

    &__button {
      @include heading-base-extended-bold;
      display: flex;
      padding: spacing(5) spacing(8);
      border: none;
      margin: 0 auto;
      background: var(--entry-teaser-button-background-color);
      color: var(--entry-teaser-button-text-color);
      text-decoration: none;

      &:hover,
      &:focus {
        background: var(--entry-teaser-button-hover-background-color);
      }
    }
  }
}
