.cmp-modal {
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--transparency-frk-black-80-trans);

  &--is-closed {
    display: none;
  }

  &__close-button {
    @include heading-base-extra-bold;
    position: absolute;
    top: spacing(5);
    padding: 0;
    border: 0;
    margin: 0;
    background: transparent;
    color: var(--primary-frk-black);
    cursor: pointer;

    @media #{$mq-1} {
      right: grid-spacing(1, 1, 1);
    }
    @media #{$mq-2} {
      right: grid-spacing(2, 1, 1);
    }
    @media #{$mq-3} {
      right: grid-spacing(3, 1, 1);
    }
    @media #{$mq-45} {
      right: spacing(8);
    }

    &::after {
      content: '\eb99';
      font-family: var(--remixicon-font);
      font-size: spacing(4);
      vertical-align: middle;
    }

    &--is-hidden {
      z-index: -1;
      opacity: 0;
    }
  }

  &__wrapper {
    @include with-max-width-center;
    @include with-left-and-right-spacing;
    max-height: 100%;
    overflow-y: auto;
  }

  &__inner-content {
    display: flex;
    min-height: 300px;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--primary-frk-black);
    margin-top: spacing(14);
    background: var(--primary-frk-white);
    transform: translateY(0);

    &:not(&--no-animation) {
      animation: slidein 0.5s;
      animation-timing-function: linear;
    }

    @media #{$mq-45} {
      flex-direction: row;
      padding: spacing(8);
      margin-top: spacing(40);
    }
  }

  &--is-full-size {
    top: 0;
    bottom: 0;
    overflow: auto;
    height: inherit;
    overscroll-behavior: contain;

    .cmp-modal__wrapper {
      height: 100%;
      padding-right: 0;
      padding-bottom: env(safe-area-inset-bottom);
      padding-left: 0;
      overflow-y: auto;
    }

    .cmp-modal__inner-content {
      height: 100%;
      padding: 0;
      border: none;
      margin-top: 0;
    }
  }
}

@keyframes slidein {
  from {
    margin-top: 0;
  }

  to {
    margin-top: spacing(40);
  }
}
