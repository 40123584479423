.cmp-tag-system {
  @media #{$mq-5} {
    width: grid-spacing(5, 8, 7);
    margin-left: grid-spacing(5, 2, 2);
  }
  @media #{$mq-4} {
    width: grid-spacing(4, 8, 7);
    margin-left: grid-spacing(4, 2, 2);
  }
  @media #{$mq-3} {
    width: grid-spacing(3, 8, 7);
    margin-left: grid-spacing(3, 2, 2);
  }
  @media #{$mq-345} {
    margin-bottom: spacing(20);
  }
  @media #{$mq-12} {
    width: 100%;
    margin-bottom: spacing(14);
    margin-left: 0;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin: 0;
  }

  &__tag {
    display: inline-flex;
    margin-bottom: spacing(3);

    &:not(:last-of-type) {
      margin-right: spacing(3);
    }
  }

  &__button {
    cursor: pointer;
    @include button(
      var(--grayscale-frk-black-5),
      var(--primary-frk-black),
      var(--grayscale-frk-black-5),
      var(--primary-frk-black),
      var(--primary-frk-white),
      var(--primary-frk-black)
    );
    @media #{$mq-345} {
      padding: spacing(2) spacing(4);
      border-radius: spacing(25);
    }

    @media #{$mq-12} {
      padding: spacing(1) spacing(2);
      border-radius: spacing(25);
      font-size: 14px;
    }

    &--is-active {
      @include button(
        var(--primary-frk-black),
        var(--primary-frk-white),
        var(--primary-frk-black),
        var(--primary-frk-black),
        var(--primary-frk-white),
        var(--primary-frk-black)
      );
      padding: spacing(2) spacing(4);
      @media #{$mq-345} {
        border-radius: spacing(25);
      }

      @media #{$mq-12} {
        border-radius: spacing(25);
      }
    }
    @media #{$mq-345} {
      @include paragraph-base-bold;
    }
    @media #{$mq-12} {
      @include paragraph-s-bold;
    }
  }
}
