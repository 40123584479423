.cmp-section {
  height: 100vh;
  @include fade-in(800ms);
  background-color: var(--primary-frk-black);
  @media #{$mq-12} {
    &:last-of-type {
      display: none;
    }
  }

  > .cmp-countdown-page__sections-wrapper {
    position: relative;
    height: 100%;
  }
}
