.cmp-form-content {
  &__slide-area {
    display: flex;
    margin-top: spacing(10);
    margin-bottom: spacing(10);

    @media #{$mq-12} {
      display: block;
    }
    @media #{$mq-1} {
      margin-bottom: spacing(7);
    }
  }

  &__title {
    @include heading-xxl-extended-bold;
    margin-bottom: spacing(10);
  }

  &__success-message {
    @include paragraph-lead-m;
  }

  &__teaser-image-container {
    position: relative;
    @media #{$mq-1} {
      width: grid-spacing(1, 10, 9);
      margin: spacing(16) grid-spacing(1, 1, 1) spacing(4) grid-spacing(1, 1, 1);
    }
    @media #{$mq-2} {
      width: grid-spacing(2, 10, 9);
      margin-right: grid-spacing(2, 1, 1);
      margin-left: grid-spacing(2, 1, 1);
    }
    @media #{$mq-3} {
      width: calc(grid-spacing(3, 6, 5) - spacing(8));
      margin-right: grid-spacing(3, 1, 2);
      margin-left: spacing(8);
    }
    @media #{$mq-4} {
      width: calc(grid-spacing(4, 6, 5) - spacing(8));
      margin-right: grid-spacing(4, 1, 2);
      margin-left: spacing(8);
    }
    @media #{$mq-5} {
      width: calc(grid-spacing(5, 6, 5) - spacing(8));
      margin-right: grid-spacing(5, 1, 2);
      margin-left: spacing(8);
    }
  }

  &__teaser-image {
    width: 100%;
    aspect-ratio: 4/3;
  }

  &__teaser-image-container,
  &__form-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__form-area {
    @media #{$mq-1} {
      width: grid-spacing(1, 10, 9);
      margin-right: grid-spacing(1, 1, 1);
      margin-left: grid-spacing(1, 1, 1);
    }
    @media #{$mq-2} {
      width: grid-spacing(2, 10, 9);
      margin-right: grid-spacing(2, 1, 1);
      margin-left: grid-spacing(2, 1, 1);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 6, 5);
      margin-left: grid-spacing(3, 1, 1);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 6, 5);
      margin-left: grid-spacing(4, 1, 1);
    }
    @media #{$mq-5} {
      width: grid-spacing(5, 6, 5);
      margin-left: grid-spacing(5, 1, 1);
    }
  }

  .cmp-form-content__form {
    width: 100%;
    @media #{$mq-1} {
      input[type='submit'] {
        margin-top: spacing(6);
      }
    }
    @media #{$mq-3} {
      margin-left: grid-spacing(3, 1, 1);
    }
    @media #{$mq-4} {
      margin-left: grid-spacing(4, 1, 1);
    }
    @media #{$mq-5} {
      margin-left: grid-spacing(5, 1, 1);
    }
  }

  &__teaser-image-container + &__form-area {
    @media #{$mq-3} {
      width: grid-spacing(3, 4, 3);
      margin-left: 0;
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 4, 3);
      margin-left: 0;
    }
    @media #{$mq-5} {
      width: grid-spacing(5, 4, 3);
      margin-left: 0;
    }

    .cmp-form-content__form {
      margin-left: 0;
    }

    .cmp-form-options__field--drop-down {
      @media #{$mq-5} {
        width: grid-spacing(5, 4, 3);
      }

      @media #{$mq-4} {
        width: grid-spacing(4, 4, 3);
      }
      @media #{$mq-3} {
        width: grid-spacing(3, 4, 3);
      }
      @media #{$mq-2} {
        width: grid-spacing(2, 10, 9);
      }
      @media #{$mq-1} {
        width: grid-spacing(1, 10, 9);
      }
    }

    .cmp-form-button {
      align-self: flex-start;
    }
  }
}

.cmp-form-content__slide-area:not(.cmp-modal .cmp-form-content__slide-area) {
  margin-top: spacing(30);
  margin-bottom: spacing(30);

  @media #{$mq-12} {
    margin-top: spacing(15);
    margin-bottom: spacing(15);
  }
  @media #{$mq-3} {
    margin-top: spacing(33);
    margin-bottom: spacing(33);
  }

  .cmp-form-options__field--drop-down {
    width: 100%;
  }
}
