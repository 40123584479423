// https://gist.github.com/JoeCianflone/2dc5bccfb98ae6a01a2b
@function get-value($n) {
  $everything-okay: true;

  @if type-of($n) != 'number' {
    @error "[get-value]: provided argument is missing or wrong type. Argument: '#{$n}', Type: '#{type-of($n)}'.";

    $everything-okay: false;
  }

  @if $everything-okay {
    @return calc($n / ($n * 0 + 1));
  }
}

@function get-line-height($font-size, $line-height) {
  $everything-okay: true;

  @if type-of($font-size) != 'number' {
    @error "[get-line-height]: font-size is missing or wrong type. Argument: '#{$font-size}', Type: '#{type-of($font-size)}'.";

    $everything-okay: false;
  }

  @if type-of($line-height) != 'number' {
    @error "[get-line-height]: line-height is missing or wrong type. Argument: '#{$line-height}, Type: '#{type-of($line-height)}'.";

    $everything-okay: false;
  }

  @if $everything-okay {
    @return get-value(calc($line-height / $font-size));
  }
}

@mixin get-typography($font-size, $line-height, $important: false) {
  @if $important {
    font-size: $font-size !important;
    line-height: get-line-height($font-size, $line-height) !important;
  } @else {
    font-size: $font-size;
    line-height: get-line-height($font-size, $line-height);
  }
}

@mixin with-max-width-center() {
  max-width: var(--page-max-width);
  margin: 0 auto;
}

@mixin with-left-and-right-spacing() {
  @media #{$mq-1} {
    padding-right: var(--page-container-side-margin-s);
    padding-left: var(--page-container-side-margin-s);
  }
  @media #{$mq-2} {
    padding-right: var(--page-container-side-margin-m);
    padding-left: var(--page-container-side-margin-m);
  }
  @media #{$mq-3} {
    padding-right: var(--page-container-side-margin-l);
    padding-left: var(--page-container-side-margin-l);
  }
  @media #{$mq-4} {
    padding-right: var(--page-container-side-margin-xl);
    padding-left: var(--page-container-side-margin-xl);
  }
  @media #{$mq-5} {
    padding-right: var(--page-container-side-margin-x-large);
    padding-left: var(--page-container-side-margin-x-large);
  }
}

@mixin remove-side-margins() {
  @media #{$mq-1} {
    margin-right: calc(var(--page-container-side-margin-s) * -1);
    margin-left: calc(var(--page-container-side-margin-s) * -1);
  }
  @media #{$mq-2} {
    margin-right: calc(var(--page-container-side-margin-m) * -1);
    margin-left: calc(var(--page-container-side-margin-m) * -1);
  }
  @media #{$mq-3} {
    margin-right: calc(var(--page-container-side-margin-l) * -1);
    margin-left: calc(var(--page-container-side-margin-l) * -1);
  }
  @media #{$mq-4} {
    margin-right: calc(var(--page-container-side-margin-xl) * -1);
    margin-left: calc(var(--page-container-side-margin-xl) * -1);
  }
  @media #{$mq-5} {
    margin-right: calc(var(--page-container-side-margin-x-large) * -1);
    margin-left: calc(var(--page-container-side-margin-x-large) * -1);
  }
}
