.cmp-article-single-page-hero {
  aspect-ratio: 16/9;
  @include grid-layout;
  @include remove-side-margins;
  @media #{$mq-345} {
    padding-top: $header-height-desktop;
    grid-template-rows: spacing(20) auto auto;
  }
  @media #{$mq-12} {
    padding-top: $header-height-mobile;
    grid-template-rows: auto auto auto;
  }

  &__image {
    z-index: 1;
    aspect-ratio: 16/9;
    @media #{$mq-345} {
      grid-column: #{column-index(3)} / #{column-index(11, 1)};
      grid-row: 1/3;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media #{$mq-12} {
      grid-column: #{column-index(0)} / #{column-index(13)};
      grid-row: 1;
    }
  }

  &__background {
    background-color: var(--article-single-page-hero-background-color);
    color: var(--article-single-page-hero-text-color);
    @media #{$mq-345} {
      grid-column: #{column-index(2)} / #{column-index(9, 1)};
      grid-row: 2/4;
    }
    @media #{$mq-12} {
      grid-column: #{column-index(0)} / #{column-index(13)};
      grid-row: 1/3;
    }
  }

  &__content {
    @media #{$mq-345} {
      grid-column: #{column-index(2)} / #{column-index(9, 1)};
      grid-row: 3;
    }

    @media #{$mq-12} {
      grid-column: #{column-index(0)} / #{column-index(13)};
      grid-row: 2;
    }
  }

  &__meta {
    @include paragraph-s;

    @media #{$mq-5} {
      margin: spacing(11) grid-spacing(5, 1, 1) spacing(6) grid-spacing(5, 1, 1);
    }
    @media #{$mq-4} {
      margin: spacing(11) grid-spacing(4, 1, 1) spacing(6) grid-spacing(4, 1, 1);
    }
    @media #{$mq-3} {
      margin: spacing(9) grid-spacing(3, 1, 1) spacing(6) grid-spacing(3, 1, 1);
    }

    @media #{$mq-12} {
      margin: spacing(9) 0 spacing(3) 0;
      @include with-left-and-right-spacing;
    }
  }

  &__date {
    padding-right: spacing(3);
    border-right: 1px solid var(--article-single-page-hero-border-color);

    @media #{$mq-1} {
      padding-right: spacing(2);
    }
  }

  &__time {
    display: inline-flex;
    align-items: center;
    padding-left: spacing(3);
    border-left: 1px solid var(--article-single-page-hero-border-color);

    @media #{$mq-1} {
      padding-left: spacing(2);
    }

    > i {
      margin-left: spacing(1);
    }
  }

  &__title {
    @include display-l-expanded-bold;
    margin-bottom: spacing(5);
    @media #{$mq-5} {
      margin-right: grid-spacing(5, 1, 1);
      margin-left: grid-spacing(5, 1, 1);
    }
    @media #{$mq-4} {
      margin-right: grid-spacing(4, 1, 1);
      margin-left: grid-spacing(4, 1, 1);
    }
    @media #{$mq-3} {
      margin-right: grid-spacing(3, 1, 1);
      margin-left: grid-spacing(3, 1, 1);
    }
    @media #{$mq-12} {
      margin-top: 0;
      margin-left: 0;
      @include with-left-and-right-spacing;
      @include display-xl-expanded-bold;
    }
  }

  &__description {
    @include paragraph-lead-m;
    padding-bottom: spacing(14);
    @media #{$mq-5} {
      margin-right: grid-spacing(5, 1, 1);
      margin-left: grid-spacing(5, 1, 1);
    }
    @media #{$mq-4} {
      margin-right: grid-spacing(4, 1, 1);
      margin-left: grid-spacing(4, 1, 1);
    }
    @media #{$mq-3} {
      margin-right: grid-spacing(3, 1, 1);
      margin-left: grid-spacing(3, 1, 1);
    }
    @media #{$mq-12} {
      margin-top: 0;
      margin-left: 0;
      @include with-left-and-right-spacing;
    }
  }
}
