.cmp-breadcrumb {
  position: fixed;
  display: block;
  width: var(--page-max-width);
  max-width: 100vw;
  @media #{$mq-1} {
    padding-right: var(--page-container-side-margin-s);
  }
  @media #{$mq-2} {
    padding-right: var(--page-container-side-margin-m);
  }
  @media #{$mq-3} {
    padding-right: var(--page-container-side-margin-l);
  }
  @media #{$mq-4} {
    padding-right: var(--page-container-side-margin-xl);
  }
  @media #{$mq-5} {
    padding-right: var(--page-container-side-margin-x-large);
  }

  /* this is intended to be used by the coffee machine finder to ad
  just the background accordingly */
  &--champagne {
    --breadcrumbs-background-color: var(--gradations-frk-champagne-50);
  }

  @media #{$mq-123} {
    margin-top: spacing(18);
  }
  @media #{$mq-45} {
    margin-top: spacing(22.5);
  }

  .cmp-breadcrumb__list {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-bottom: 0;
    margin: 0;
    margin-bottom: 0;
    overflow-x: auto;
    @media #{$mq-1} {
      padding-right: var(--page-container-side-margin-s);
    }
    @media #{$mq-2} {
      padding-right: var(--page-container-side-margin-m);
    }
    @media #{$mq-3} {
      padding-right: var(--page-container-side-margin-l);
    }
    @media #{$mq-4} {
      padding-right: var(--page-container-side-margin-xl);
    }
    @media #{$mq-5} {
      padding-right: var(--page-container-side-margin-x-large);
    }
    @media #{$mq-12} {
      margin-top: 0;

      &::-webkit-scrollbar {
        display: none;
      }

      &--collapsed {
        flex-wrap: nowrap;

        .cmp-breadcrumb__item {
          overflow: hidden;

          &:not(:first-of-type):not(:last-of-type) {
            display: none;
          }

          &--toggle {
            display: flex;
            align-items: center;
            color: var(--breadcrumbs-text-color);

            &:hover,
            &:focus {
              cursor: pointer;
            }

            &::after {
              margin: 0 spacing(3);
              color: var(--breadcrumbs-icon-color);
              content: '\ea54';
              font-family: var(--remixicon-font);
              font-size: spacing(4);
            }
          }
        }

        .cmp-breadcrumb__item-title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .cmp-breadcrumb__item {
      @media #{$mq-45} {
        @include pre-title-l;
      }
      @media #{$mq-123} {
        @include caption-bold;
      }
      display: inline-flex;
      align-items: center;
      color: var(--breadcrumbs-text-color);
      text-decoration: none;
      white-space: nowrap;

      &:first-child span {
        position: relative;
        width: spacing(6);
        height: spacing(6);

        // used for hiding the text on the first item
        font-size: 0;

        &::before {
          display: inline-block;
          color: var(--breadcrumbs-home-icon-color);
          content: '\ee2b';
          font-family: var(--remixicon-font);
          font-size: 24px;
          font-weight: 600;
          line-height: 34px;
        }
      }

      &-link {
        color: var(--breadcrumbs-text-color);
        text-decoration: none;
      }

      &:not(:last-child)::after {
        margin: 0 spacing(3);
        color: var(--breadcrumbs-icon-color);
        content: '\ea54';
        font-family: var(--remixicon-font);
        font-size: spacing(4);
      }
    }
  }

  .cmp-header--is-scrolled &,
  .cmp-header--is-white & {
    .cmp-breadcrumb {
      &__item {
        color: var(--header-font-color-on-hover);

        &:first-child span {
          &::before {
            color: var(--header-font-color-on-hover);
          }
        }

        &-link {
          color: var(--header-font-color-on-hover);
        }
      }

      &__list {
        .cmp-breadcrumb__item:not(:last-child)::after {
          color: var(--header-font-color-on-hover);
        }

        &--collapsed .cmp-breadcrumb__item--toggle::after {
          color: var(--header-font-color-on-hover);
        }

        &--collapsed .cmp-breadcrumb__item--toggle {
          color: var(--header-font-color-on-hover);
        }
      }
    }
  }
}
