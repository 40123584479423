.cmp-tabs {
  position: relative;

  @media #{$mq-123} {
    @include remove-side-margins;
  }

  @media #{$mq-4} {
    margin: 0 grid-spacing(4, 1, 1);
  }

  @media #{$mq-5} {
    margin: 0 grid-spacing(5, 1, 1);
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    background: linear-gradient(270deg, #fff 38.46%, rgb(255 255 255 / 10%) 100%);
    content: '';

    @media #{$mq-123} {
      width: spacing(7);
      height: spacing(14);
    }

    @media #{$mq-45} {
      width: spacing(16);
      height: spacing(16);
    }
  }
}

.cmp-tabs__tablist {
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid var(--tabs-tablist-border-color);
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  overflow-x: scroll;
  scrollbar-width: none; /* for Firefox */

  @media #{$mq-1} {
    padding: spacing(2.5) grid-spacing(1, 1, 1);
  }

  @media #{$mq-2} {
    padding: spacing(2.5) grid-spacing(2, 1, 1);
  }

  @media #{$mq-3} {
    padding: spacing(2.5) grid-spacing(3, 1, 1);
  }

  @media #{$mq-45} {
    padding: spacing(4) 0;
  }
}

.cmp-tabs__tablist::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.cmp-tabs__tab {
  padding: spacing(2) spacing(4);
  margin-right: spacing(1);
  cursor: pointer;
  white-space: nowrap;

  @media #{$mq-123} {
    @include paragraph-s-bold;
  }
  @media #{$mq-45} {
    @include paragraph-base-bold;
  }
}

.cmp-tabs__tab:hover,
.cmp-tabs__tab:focus {
  background: var(--tabs-on-hover-focus-background-color);
}

.cmp-tabs__tab--active {
  border: 0;
  background: var(--tabs-active-background-color);
  color: var(--tabs-active-text-color);

  &:hover,
  &:focus {
    background: var(--tabs-active-on-hover-focus-background-color);
  }
}

.cmp-tabs__tabpanel {
  @media #{$mq-1} {
    padding: spacing(2.5) grid-spacing(1, 1, 1);
  }

  @media #{$mq-2} {
    padding: spacing(2.5) grid-spacing(2, 1, 1);
  }

  @media #{$mq-3} {
    padding: spacing(2.5) grid-spacing(3, 1, 1);
  }

  @media #{$mq-45} {
    padding: spacing(4) 0;
  }
}

.cmp-tabs__tabpanel--active {
}
