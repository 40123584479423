.cmp-accordion {
  @media #{$mq-4} {
    padding: 0 grid-spacing(4, 1, 1);
  }
  @media #{$mq-5} {
    padding: 0 grid-spacing(5, 1, 1);
  }

  &__item {
    &:not(:last-of-type) {
      border-bottom: 1px solid var(--accordion-item-border-color);
    }

    &:first-of-type {
      .cmp-accordion__button {
        padding-top: spacing(3);
        padding-bottom: spacing(5);
      }
    }
  }

  &__title {
    margin-right: spacing(7);
    transition: 0.2s;
    word-break: break-all;

    @media #{$mq-12} {
      @include heading-base-extra-bold;
    }
    @media #{$mq-345} {
      @include heading-m-extra-bold;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: spacing(5) 0;
    border: var(--accordion-button-border-color);
    background: var(--accordion-button-background-color);
    color: var(--accordion-button-text-color);
    cursor: pointer;
    @include pre-title-l;
  }

  &__icon {
    color: var(--accordion-icon-color);
    font-family: var(--remixicon-font);
    font-size: $icon-height-medium;
    transition: 0.2s;

    &::after {
      content: '\ea13';
    }
  }

  &__button--expanded {
    .cmp-accordion__title {
      color: var(--accordion-button-title-text-color);
    }

    .cmp-accordion__icon {
      transform: rotate(45deg);
    }
  }

  &__panel {
    .bulletlist {
      padding-top: spacing(1);
      padding-left: spacing(8);

      @media #{$mq-12} {
        padding-left: spacing(5);
      }

      .cmp-bullet-list {
        margin: 0;

        &__item {
          @media #{$mq-12} {
            font-size: spacing(4);
          }

          &::before {
            padding-left: 0;
            color: var(--accordion-bullet-color);
          }
        }
      }
    }

    .downloadlist {
      padding-left: spacing(8);

      .cmp-download-list__item {
        margin: 0;

        &:last-of-type {
          border-bottom: none;
        }
      }
      @media #{$mq-12} {
        padding-left: spacing(3);
      }
    }

    .cmp-link-list-container {
      display: block;
      padding-left: spacing(8);
      margin: 0;
      @media #{$mq-12} {
        padding-left: spacing(5);
      }
    }

    .image {
      padding: spacing(1) 0 spacing(6) 0;
      @media #{$mq-12} {
        padding-left: spacing(5);
      }
    }

    .cmp-video {
      padding-top: spacing(1);
      @media #{$mq-12} {
        padding-left: spacing(5);
      }
    }

    .cmp-text {
      display: block;
      padding-right: spacing(12);
      margin: 0;
      @media #{$mq-12} {
        padding-right: spacing(6);
      }
    }
  }
}
