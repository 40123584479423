.coffee-machine-finder {
  @include with-max-width-center;
  margin-top: spacing(10);

  @media #{$mq-345} {
    padding-top: $header-height-desktop;
    margin-bottom: spacing(25);
  }

  @media #{$mq-12} {
    padding-top: $header-height-mobile;
    margin-bottom: spacing(12);
  }
}

.coffee-machine-finder__stepper {
  display: flex;
  justify-content: center;
}

.coffee-machine-finder__step-icon {
  text-align: center;

  @media #{$mq-12} {
    &:not(:last-of-type) {
      margin-right: spacing(5);
    }
  }

  &--img {
    width: spacing(8);
    height: spacing(8);
  }

  &--title {
    display: block;
    margin-top: spacing(3);
    color: var(--grayscale-frk-cool-grey-8);
    @include heading-s-extra-bold;
    @media #{$mq-12} {
      display: none;
    }
  }

  &-container {
    width: spacing(12);
    height: spacing(12);
    padding: spacing(2);
  }

  &--past {
    cursor: pointer;

    .coffee-machine-finder__step-icon-container {
      background-color: var(--primary-frk-black);

      .coffee-machine-finder__step-icon--image img {
        filter: brightness(0) invert(1);
      }
    }

    .coffee-machine-finder__step-icon--title {
      color: var(--primary-frk-black);
    }
  }

  &--current {
    .coffee-machine-finder__step-icon-container {
      background-color: var(--primary-frk-vibrant-red);

      .coffee-machine-finder__step-icon--image {
        filter: brightness(0) invert(1);
      }
    }

    .coffee-machine-finder__step-icon--title {
      color: var(--primary-frk-vibrant-red);
      @media #{$mq-12} {
        display: block;
      }
    }
  }
}

.coffee-machine-finder__step-line {
  display: flex;
  height: 48px;
  align-items: center;
  margin: spacing(2);

  > div {
    width: 48px;
    height: 1px;
    background-color: var(--transparency-frk-black-50-trans);
  }

  &--past {
    > div {
      height: 3px;
      background-color: var(--primary-frk-black);
    }
  }

  &--current {
    > div:first-child {
      height: 3px;
      background-color: var(--primary-frk-vibrant-red);
    }
  }
  @media #{$mq-12} {
    display: none;
  }
}

.coffee-machine-finder__slide {
  display: none;

  &--current {
    display: block;
  }

  &-steps-number {
    margin-top: spacing(12);
    margin-bottom: spacing(2);
    color: var(--secondary-frk-dark-coffee);
    text-align: center;
    @include paragraph-s-bold;
  }

  &-question {
    text-align: center;
    @include heading-l-extended-bold;

    + .coffee-machine-finder__option-list {
      margin-top: spacing(12);
    }
  }

  &-subline {
    @include paragraph-base-bold;
    margin-top: spacing(1);
    text-align: center;
    @media #{$mq-12} {
      margin-top: spacing(2);

      + .coffee-machine-finder__option-list {
        margin-top: spacing(4);
      }
    }
  }

  &--is-rating {
    .coffee-machine-finder__option-image img {
      display: block;
      width: spacing(18);
      height: auto;
      margin: spacing(13) auto;
      filter: contrast(1) brightness(0.1);
    }

    .coffee-machine-finder__option {
      &--is-disabled {
        .coffee-machine-finder__option-image img {
          filter: brightness(0) saturate(100%) opacity(0.15);
        }
      }
    }
  }
}

.coffee-machine-finder__option-list {
  display: flex;
  justify-content: center;
  margin-top: spacing(12);
  margin-bottom: spacing(12);
  @media #{$mq-12} {
    flex-direction: column;
  }
}

.coffee-machine-finder__option {
  padding: spacing(6);
  border: 2px solid var(--grayscale-frk-cool-grey-1);
  cursor: pointer;

  @media #{$mq-12} {
    flex-basis: 100%;
    margin: spacing(3) 0;
  }
  @media #{$mq-345} {
    margin: spacing(3);

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
  @media #{$mq-3} {
    flex-basis: grid-spacing(3, 3, 2, 0);
  }
  @media #{$mq-4} {
    flex-basis: grid-spacing(4, 3, 2, 0);
  }
  @media #{$mq-5} {
    flex-basis: grid-spacing(5, 3, 2, 0);
  }

  &:not(&--selected) {
    &:focus {
      border-color: var(--grayscale-frk-cool-grey-1);
    }

    &:hover {
      border-color: var(--primary-frk-vibrant-red);
    }
  }

  &--selected {
    position: relative;
    border-color: var(--primary-frk-vibrant-red);

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      width: spacing(12);
      height: spacing(12);
      align-items: center;
      justify-content: center;
      background-color: var(--primary-frk-vibrant-red);
      color: var(--primary-frk-white);
      content: '\eb7a';
      font-family: var(--remixicon-font);
      font-size: spacing(8);
    }
  }

  &--is-disabled {
    border-color: var(--grayscale-frk-cool-grey-1);
    color: var(--grayscale-frk-cool-grey-1);
    pointer-events: none;

    .coffee-machine-finder__option-image img {
      filter: brightness(0) saturate(100%) opacity(0.15);
    }
  }

  &-title {
    @include heading-l-extended-bold;
  }

  &-subtitle {
    @include paragraph-base;
  }

  &-description {
    @include paragraph-s;
    margin-top: spacing(6);
  }
}

.coffee-machine-finder__actions {
  display: flex;
  justify-content: center;

  @media #{$mq-12} {
    margin-bottom: spacing(12);
  }

  @media #{$mq-345} {
    margin-bottom: spacing(24);
  }
}

.coffee-machine-finder__actions__next {
  @include button;
  @include heading-s-extended-bold;
  cursor: pointer;

  &--is-disabled {
    @include button-disabled;
  }
}

.coffee-machine-finder__actions__previous {
  @include link;
  display: flex;
  align-items: center;
  @include heading-s-extended-bold;
  @include link(var(--primary-frk-vibrant-red));
  cursor: pointer;

  &::before {
    content: '\ea60';
    opacity: 1;
  }

  &::after {
    content: '\ea60';
    opacity: 0;
  }

  &:hover,
  &:focus {
    transform: translateX(-30px);

    &::after {
      opacity: 1;
    }

    &::before {
      opacity: 0;
    }
  }
}

.coffee-machine-finder__result-tabs {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  margin: spacing(8) 0 spacing(13);
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  overflow-x: scroll;
  scrollbar-width: none; /* for Firefox */

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  &::before,
  &::after {
    margin: auto;
    content: '';
  }

  .coffee-machine-finder__result-tab {
    padding: spacing(2) spacing(4);
    border-width: 0;
    margin: 0 spacing(7);
    background-color: var(--gradations-frk-champagne-50);
    color: var(--primary-frk-black);
    transition: all 200ms ease-in-out;
    white-space: nowrap;
    @include heading-base-extra-bold;
    @media #{$mq-12} {
      margin: 0 spacing(1);
    }

    &:hover,
    &:focus {
      background-color: var(--primary-frk-black);
      color: var(--primary-frk-white);
      cursor: pointer;
    }

    &--selected {
      background-color: var(--primary-frk-black);
      color: var(--primary-frk-white);
    }
  }
}

.coffee-machine-finder__results {
  @include grid-layout;
  padding-bottom: spacing(30);
  grid-template-rows: auto auto auto auto auto auto auto auto auto;

  &-intro {
    width: 100%;
    padding-top: spacing(50);
    grid-column: #{column-index(0)} / #{column-index(13)};
    grid-row: 1 / 2;
    justify-self: center;
    text-align: center;
  }

  &-background {
    background-color: var(--gradations-frk-champagne-50);
    grid-column: #{column-index(0)} / #{column-index(13)};
    grid-row: 1 / 3;

    @media #{$mq-1} {
      margin: 0 calc(var(--page-container-side-margin-s) * -1);
    }
    @media #{$mq-2} {
      margin: 0 calc(var(--page-container-side-margin-m) * -1);
    }
    @media #{$mq-3} {
      margin: 0 calc(var(--page-container-side-margin-l) * -1);
    }
    @media #{$mq-4} {
      margin: 0 calc(var(--page-container-side-margin-xl) * -1);
    }
    @media #{$mq-5} {
      margin: 0 calc(var(--page-container-side-margin-x-large) * -1);
    }
  }

  &-text {
    text-align: center;
    @include paragraph-base-bold;
  }

  &-title {
    @include heading-xxl-extended-bold;
  }

  &-description {
    @include paragraph-lead-m;
    padding: spacing(4) 0 spacing(7);
  }

  &-title,
  &-description {
    @include with-max-width-center;

    @media #{$mq-3} {
      width: grid-spacing(3, 6, 5);
    }

    @media #{$mq-4} {
      width: grid-spacing(4, 6, 5);
    }

    @media #{$mq-5} {
      width: grid-spacing(5, 6, 5);
    }
  }

  &-button {
    @include heading-base-extended-bold;
    padding: spacing(4) spacing(7);
    margin-bottom: spacing(20);
    cursor: pointer;
    text-align: center;
    @include button(
      var(--form-button-background-color),
      var(--form-button-text-color),
      var(--form-button-border-color),
      var(--form-button-background-hover-color),
      var(--form-button-text-hover-color),
      var(--form-button-border-hover-color)
    );
  }

  hr {
    border: 0;
    border-top: 1px solid var(--gradations-frk-rosemary-50);
    grid-column: #{column-index(2)} / #{column-index(11, 1)};
    @media #{$mq-12} {
      grid-column: #{column-index(1)} / #{column-index(12, 1)};
    }
  }
}

.coffee-machine {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  grid-column: #{column-index(2)} / #{column-index(11, 1)};
  grid-row: 4 / 5;
  @media #{$mq-12} {
    flex-direction: column;
    align-items: flex-start;
    margin-top: spacing(12);
    grid-column: #{column-index(1)} / #{column-index(12, 1)};
  }
  @media #{$mq-345} {
    margin-top: spacing(16);
  }

  &__image {
    aspect-ratio: 552 / 452;
    grid-column: #{column-index(4)} / #{column-index(9, 1)};
    grid-row: 2 / 4;

    @media #{$mq-12} {
      grid-column: #{column-index(1)} / #{column-index(12, 1)};
    }
  }

  &__name {
    @include display-xxl-expanded-bold;
  }

  &__description {
    @include paragraph-lead-l;
    color: var(--grayscale-frk-cool-gray-11);
  }

  &__divider {
    margin: spacing(12) 0;
    grid-row: 5 / 6;
  }

  &__product-cta {
    grid-column: #{column-index(2)} / #{column-index(12, 1)};
    grid-row: 8 / 9;
    @media #{$mq-12} {
      display: flex;
      width: fit-content;
      flex-direction: column;
      grid-column: #{column-index(1)} / #{column-index(12, 1)};
    }
  }

  &__product-details {
    @include button;
    @include heading-s-extended-bold;
    @media #{$mq-345} {
      margin-right: spacing(19);
    }
    @media #{$mq-123} {
      margin-bottom: spacing(8);
    }
  }

  &__download {
    @include button(
      var(--primary-frk-white),
      var(--primary-frk-black),
      var(--primary-frk-black),
      var(--primary-frk-black),
      var(--primary-frk-white),
      var(--primary-frk-white)
    );
    @include heading-s-extended-bold;
    cursor: pointer;
    @media #{$mq-12} {
      margin-top: spacing(12);
    }
  }

  &__reset-finder {
    @include link;
    @include heading-s-extended-bold;
    color: var(--primary-frk-vibrant-red);
    cursor: pointer;
    grid-column: #{column-index(4)} / #{column-index(5, 1)};
    grid-row: 8 / 9;
    @media #{$mq-123} {
      grid-column: #{column-index(0)} / #{column-index(12, 1)};
      grid-row: 9 / 10;
    }

    &::after {
      content: '\ea58';
    }
  }
}

.coffee-machine__image-spacer {
  aspect-ratio: 552/112;
  grid-column: 8/22;
  grid-row: 3 / 4;
}

.coffee-machine__feature-container {
  display: flex;
  justify-content: space-between;
  grid-column: #{column-index(0)} / #{column-index(13, 0)};
  grid-row: 6 / 8;
  @include grid-layout;
  @media #{$mq-12} {
    grid-row: 6 / 7;
    grid-template-rows: auto auto auto auto auto;
  }
}

.coffee-machine__product-feature {
  margin-bottom: spacing(11);

  &__name {
    @include heading-m-extended-bold;
    margin-bottom: spacing(1);
  }

  &__item {
    @include paragraph-base;
    color: var(--grayscale-frk-cool-gray-11);
  }
  @media #{$mq-12} {
    margin-bottom: spacing(6);
  }
}

.coffee-machine__product-feature:nth-of-type(1),
.coffee-machine__product-feature:nth-of-type(4) {
  grid-column: #{column-index(2)} / #{column-index(4, 1)};
}

.coffee-machine__product-feature:nth-of-type(2),
.coffee-machine__product-feature:nth-of-type(5) {
  grid-column: #{column-index(5)} / #{column-index(7, 1)};
}

.coffee-machine__product-feature:nth-of-type(3) {
  grid-column: #{column-index(8)} / #{column-index(11, 1)};
  grid-row: 6 / 8;
}

.coffee-machine__product-feature:nth-of-type(1),
.coffee-machine__product-feature:nth-of-type(2) {
  grid-row: 6 / 7;
}

.coffee-machine__product-feature:nth-of-type(4),
.coffee-machine__product-feature:nth-of-type(5) {
  grid-row: 7 / 8;
}

@media #{$mq-12} {
  .coffee-machine__product-feature:nth-of-type(1) {
    grid-column: #{column-index(1)} / #{column-index(12, 1)};
    grid-row: 1 /2;
  }

  .coffee-machine__product-feature:nth-of-type(2) {
    grid-column: #{column-index(1)} / #{column-index(12, 1)};
    grid-row: 2 / 3;
  }

  .coffee-machine__product-feature:nth-of-type(3) {
    grid-column: #{column-index(1)} / #{column-index(12, 1)};
    grid-row: 3 / 4;
  }

  .coffee-machine__product-feature:nth-of-type(4) {
    grid-column: #{column-index(1)} / #{column-index(12, 1)};
    grid-row: 4 / 5;
  }

  .coffee-machine__product-feature:nth-of-type(5) {
    grid-column: #{column-index(1)} / #{column-index(12, 1)};
    grid-row: 5 / 6;
  }
}
