.cmp-container__spacing {
  @media #{$mq-12} {
    margin-top: spacing(10);
    margin-bottom: spacing(10);
  }

  @media #{$mq-3} {
    margin-top: spacing(15);
    margin-bottom: spacing(15);
  }

  @media #{$mq-45} {
    margin-top: spacing(20);
    margin-bottom: spacing(20);
  }

  &.cmp-container__spacing--has-no-margin-top {
    margin-top: 0;
  }

  &.cmp-container__spacing--has-no-margin-bottom {
    margin-bottom: 0;
  }

  & > .cmp-container {
    display: flex;
    flex-direction: column;
    row-gap: spacing(10);

    @media #{$mq-12} {
      row-gap: spacing(5);
    }

    @media #{$mq-3} {
      row-gap: spacing(7);
    }

    @media #{$mq-45} {
      row-gap: spacing(10);
    }
  }
}

/* any special situation should be put here */
