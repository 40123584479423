.cmp-countdown-page-headline {
  margin-top: spacing(24);
  color: var(--countdown-headlines-color);
  @include display-xl-expanded-bold-countdown-headlines;
  @media #{$mq-3} {
    margin-top: spacing(43);
  }

  &__left {
    overflow: hidden;
    width: grid-spacing(5, 11, 10);
    margin-left: grid-spacing(5, 1, 1);
    animation: 800ms slide-right ease-in-out;
    @media #{$mq-4} {
      width: grid-spacing(4, 11, 10);
      margin-left: grid-spacing(4, 1, 1);
    }
    @media #{$mq-123} {
      width: 100%;
      margin-left: 0;
    }
  }

  &__right {
    overflow: hidden;
    width: grid-spacing(5, 11, 10);
    margin-right: grid-spacing(5, 1, 1);
    animation: 800ms slide-left ease-in-out;
    text-align: right;
    @media #{$mq-4} {
      width: grid-spacing(4, 11, 10);
      margin-right: grid-spacing(4, 1, 1);
    }
    @media #{$mq-123} {
      width: 100%;
      margin-right: 0;
    }
  }
}

@keyframes slide-right {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-left {
  0% {
    transform: translateX(200%);
  }

  100% {
    transform: translateX(0%);
  }
}
