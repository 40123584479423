.cmp-form-button {
  @include heading-base-extended-bold;
  padding: spacing(4) spacing(7);
  margin-top: spacing(14);
  cursor: pointer;
  @include button(
    var(--form-button-background-color),
    var(--form-button-text-color),
    var(--form-button-border-color),
    var(--form-button-background-hover-color),
    var(--form-button-text-hover-color),
    var(--form-button-border-hover-color)
  );

  &:disabled {
    @include button(
      var(--form-button-disabled-background-color),
      var(--form-button-disabled-text-color),
      var(--form-button-disabled-border-color),
      var(--form-button-disabled-background-color),
      var(--form-button-disabled-text-color),
      var(--form-button-disabled-border-color)
    );
    cursor: not-allowed;
  }
}
