.cmp-thank-you {
  position: relative;
  padding-top: spacing(37);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--countdown-thank-you-page-color);
  @include remove-side-margins;

  @media #{$mq-12} {
    padding-top: spacing(24);
  }

  &__image {
    position: absolute;
    top: 5%;
    right: 0;
    aspect-ratio: 16 / 9;
    object-fit: contain;
    @media #{$mq-3} {
      top: 20%;
    }
    @media #{$mq-12} {
      top: 32%;
    }
  }

  &__wrapper {
    @include with-max-width-center;
    @include with-left-and-right-spacing;
    position: relative;
  }

  &__heading {
    @include with-max-width-center;

    // @include with-left-and-right-spacing;
    margin-bottom: spacing(10);
    margin-left: grid-spacing(5, 1, 1);

    @media #{$mq-4} {
      margin-left: grid-spacing(4, 1, 1);
    }
    @media #{$mq-3} {
      margin-left: grid-spacing(3, 1, 1);
    }
    @media #{$mq-12} {
      margin-bottom: spacing(12);
      margin-left: 0;
    }

    &--is-large {
      .cmp-thank-you__heading {
        @include display-l-expanded-bold;
      }

      & ~ .cardteaser.teaser {
        position: relative;
      }

      .cmp-thank-you__image {
        top: 15%;
        width: 80%;
        @media #{$mq-12} {
          top: 20%;
          width: 101%;
        }
      }
    }

    &--is-medium {
      .cmp-thank-you {
        padding: spacing(37) 0 spacing(38) 0;

        @media #{$mq-12} {
          padding: spacing(24) 0 0 0;
        }

        &__heading {
          @include heading-xxl-extended-bold;
        }
      }
    }
  }

  &__text {
    width: grid-spacing(5, 5, 4);
    margin-left: grid-spacing(5, 2, 2);

    @media #{$mq-4} {
      width: grid-spacing(4, 5, 4);
      margin-left: grid-spacing(4, 2, 2);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 7, 6);
      margin-left: grid-spacing(3, 2, 2);
    }
    @media #{$mq-12} {
      width: 100%;
      margin-left: 0;
    }
  }

  &__title {
    @include heading-l-extended-bold;
    margin-bottom: spacing(4);
    @media #{$mq-12} {
      @include heading-m-extended-bold;
    }
  }

  &__lead-text {
    @include paragraph-lead-m;
    margin-bottom: spacing(4);
  }

  &__description {
    @include paragraph-base;
    margin-bottom: spacing(10);
  }

  &__buttons-wrapper {
    display: flex;
    @media #{$mq-12} {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__button {
    @include link(
      var(--countdown-thank-you-page-link-color),
      true,
      var(--countdown-thank-you-page-link-color),
      true,
      true
    );
    display: flex;
    align-items: center;
    @include heading-base-extra-bold;
  }

  &__button-download {
    @include button(
      var(--countdown-thank-you-page-download-button-background-color),
      var(--countdown-thank-you-page-download-button-text-color),
      var(--countdown-thank-you-page-download-button-border-color),
      var(--countdown-thank-you-page-download-button-hover-background-color),
      var(--countdown-thank-you-page-download-button-hover-text-color),
      var(--countdown-thank-you-page-download-button-hover-border-color)
    );
    margin-left: spacing(10);
    @media #{$mq-12} {
      margin-top: spacing(10);
      margin-left: 0;
    }
  }
}

div[data-component-name='teaser-countdown-form-container'] {
  position: relative; // make it into a new rendering context so it doesn't go under the background image
}
