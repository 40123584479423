.cmp-hero-basic {
  @include with-max-width-center;
  width: 100%;

  .no-breadcrumbs & {
    @media #{$mq-123} {
      padding-top: spacing(19);
    }
    @media #{$mq-45} {
      padding-top: spacing(29);
    }
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: spacing(26);
    background-color: var(--basic-hero-background-color);
    @include with-left-and-right-spacing;

    [data-component='Video'] {
      width: 100%;
      @media #{$mq-12} {
        width: 100vw;
      }
    }
    @media #{$mq-12} {
      padding-top: $header-height-mobile;
    }

    &:only-child {
      padding-bottom: spacing(22);
      @media #{$mq-12} {
        padding-bottom: spacing(10);
      }

      .cmp-hero-basic__description {
        padding-bottom: spacing(4);
        margin-bottom: 0;
      }
    }
  }

  .cmp-video {
    margin-bottom: 0;
  }

  &__pretitle,
  &__title,
  &__description {
    width: 100%;
    margin-bottom: spacing(4);
    font-family: var(--typography-font);
    text-align: left;
  }

  &__pretitle,
  &__description {
    color: var(--basic-hero-text-color);
  }

  &__title {
    color: var(--basic-hero-title-color);
  }

  &__pretitle {
    padding-top: spacing(26);

    @media #{$mq-12} {
      padding-top: spacing(12);
    }
  }

  &__image,
  .cmp-video {
    width: 100%;
    margin-top: spacing(11);
  }

  &__image .cmp-image,
  .cmp-video .cmp-video__container {
    position: absolute;
    aspect-ratio: 16 / 9;
    @media #{$mq-12} {
      position: relative;
    }
  }

  .cmp-video--has-mobile-video .cmp-video__container {
    @media #{$mq-12} {
      position: relative;
      right: 0;
      left: 0;
      width: 100vw;
      height: auto;
      margin-right: 0;
      margin-left: 0;
      aspect-ratio: initial;
    }
  }

  &__image {
    .cmp-image {
      grid-template-columns: inherit;
      @media #{$mq-12} {
        @include remove-side-margins;
      }

      &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__spacer {
    box-sizing: content-box;
  }

  @media #{$mq-1} {
    &__container {
      padding-right: grid-spacing(1, 0, 0, 1);
      padding-left: grid-spacing(1, 0, 0, 1);
    }

    &__image,
    .cmp-video {
      aspect-ratio: 16/0;

      &--has-mobile-video {
        @media #{$mq-12} {
          width: 100vw;
          height: auto;
          aspect-ratio: initial;
        }
      }
    }

    &__image .cmp-image,
    .cmp-video .cmp-video__container {
      right: grid-spacing(1, 0, 0);
      left: grid-spacing(1, 0, 0);
    }

    &__title {
      @include heading-xl-extended-bold;
    }

    &__pretitle {
      @include paragraph-s-bold;
    }

    &__description {
      @include paragraph-lead-m;
    }
  }

  @media #{$mq-2} {
    &__container {
      padding-right: grid-spacing(2, 0, 0, 1);
      padding-left: grid-spacing(2, 0, 0, 1);
    }

    &__image,
    .cmp-video {
      aspect-ratio: 16/0;

      &--has-mobile-video {
        @media #{$mq-12} {
          width: 100%;
          height: auto;
          aspect-ratio: initial;
        }
      }
    }

    &__image .cmp-image,
    .cmp-video .cmp-video__container {
      right: grid-spacing(2, 0, 0);
      left: grid-spacing(2, 0, 0);
    }

    &__title {
      @include heading-xl-extended-bold;
    }

    &__pretitle {
      @include paragraph-s-bold;
    }

    &__description {
      @include paragraph-lead-m;
    }
  }

  @media #{$mq-3} {
    &__container {
      padding-right: grid-spacing(3, 0, 0, 1);
      padding-left: grid-spacing(3, 0, 0, 1);
    }

    &__spacer {
      padding-right: grid-spacing(3, 0, 0, 1);
      padding-left: grid-spacing(3, 0, 0, 1);
      aspect-ratio: 16 / 6;
    }

    &__image,
    .cmp-video {
      aspect-ratio: 16/3;
    }

    &__image .cmp-image,
    .cmp-video .cmp-video__container {
      right: grid-spacing(3, 0, 0, 1);
      left: grid-spacing(3, 0, 0, 1);
    }

    &__title {
      @include display-xl-expanded-bold;
    }

    &__pretitle {
      @include paragraph-lead-m-bold;
    }

    &__description {
      @include paragraph-lead-m;
    }
  }

  @media #{$mq-4} {
    &__container {
      padding-right: grid-spacing(4, 1, 1, 1);
      padding-left: grid-spacing(4, 1, 1, 1);
    }

    &__spacer {
      padding-right: grid-spacing(4, 1, 1, 1);
      padding-left: grid-spacing(4, 1, 1, 1);
      aspect-ratio: 16 / 6;
    }

    &__image,
    .cmp-video {
      aspect-ratio: 16/3;
      @media #{$mq-12} {
        width: 100%;
        height: auto;
        aspect-ratio: initial;
      }

      &--has-mobile-video {
        @media #{$mq-12} {
          width: 100%;
          height: auto;
          aspect-ratio: initial;
        }
      }
    }

    &__image .cmp-image,
    .cmp-video .cmp-video__container {
      right: grid-spacing(4, 1, 1, 1);
      left: grid-spacing(4, 1, 1, 1);
    }

    &__title {
      @include display-xl-expanded-bold;
    }

    &__pretitle {
      @include paragraph-lead-m-bold;
    }

    &__description {
      @include paragraph-lead-l;
    }
  }

  @media #{$mq-5} {
    &__container {
      padding-right: grid-spacing(5, 1, 1, 1);
      padding-left: grid-spacing(5, 1, 1, 1);
    }

    &__spacer {
      padding-right: grid-spacing(5, 1, 1, 1);
      padding-left: grid-spacing(5, 1, 1, 1);
      aspect-ratio: 16 / 6;
    }

    &__image,
    .cmp-video {
      aspect-ratio: 16/3;

      &--has-mobile-video {
        @media #{$mq-12} {
          aspect-ratio: 9/16;
        }
      }
    }

    &__image .cmp-image,
    .cmp-video .cmp-video__container {
      right: grid-spacing(5, 1, 1, 1);
      left: grid-spacing(5, 1, 1, 1);
    }

    &__title {
      @include display-xl-expanded-bold;
    }

    &__pretitle {
      @include paragraph-lead-m-bold;
    }

    &__description {
      @include paragraph-lead-l;
    }
  }

  &--without-side-margins {
    @include remove-side-margins;
    width: auto;
  }

  &__bullet-list {
    align-self: flex-start;
    @media #{$mq-345} {
      @include paragraph-lead-l;
    }

    @media #{$mq-12} {
      @include paragraph-lead-m;
    }

    ul {
      @include bullets(var(--bullet-color-in-basic-hero), 'square', true);

      li {
        margin-bottom: spacing(2);
        color: var(--basic-hero-text-color);
        @media #{$mq-345} {
          @include paragraph-lead-l;
        }

        @media #{$mq-12} {
          @include paragraph-lead-m;
        }

        &::before {
          padding-left: 0;
        }
      }
    }
  }

  @media print {
    .wishlist-print & {
      display: none;
    }
  }
}
