.cmp-icon-grid__item--is-animated {
  .cmp-icon-grid__item-title {
    margin-top: spacing(6);
  }

  .cmp-icon-grid__item-title,
  .cmp-icon-grid__item-description {
    animation-fill-mode: forwards;
    animation-name: fade-in;
    animation-timing-function: ease-out;
    opacity: 0;
    transform: translateY(30px);
  }
}
@keyframes fade-in {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
