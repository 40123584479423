// teaser slider progress bar height
$progress-bar-height: 2px;

.cmp-teaser-slider-featured-article {
  display: grid;
  grid-template-columns: auto 1fr;
  opacity: 0.5;

  @media #{$mq-345} {
    &:nth-of-type(1) {
      grid-row: 1;
    }

    &:nth-of-type(2) {
      grid-row: 3;
    }

    &:nth-of-type(3) {
      grid-row: 5;
    }

    &:nth-of-type(4) {
      grid-row: 7;
    }
  }
  @media #{$mq-12} {
    margin-right: grid-spacing(1, 2, 1);

    &:last-child {
      margin-right: 0;
    }
  }

  &__thumbnail {
    width: spacing(22);
    height: spacing(22);
    margin-right: spacing(6);
    aspect-ratio: 1/1;
    object-fit: cover;
    @media #{$mq-12} {
      display: none;
    }
  }

  &__meta {
    display: flex;
    align-items: center;
    margin-bottom: spacing(3);
    @media #{$mq-12} {
      padding-top: spacing(1);
    }
  }

  &__date {
    padding-right: spacing(3);
    border-right: 1px solid var(--teaser-slider-border-color);

    @media #{$mq-1} {
      padding-right: spacing(2);
    }
  }

  &__title {
    @include heading-s-extended-bold;
    display: box;
    overflow: hidden;
    margin-bottom: spacing(1);
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    @media #{$mq-5} {
      width: calc(grid-spacing(5, 4, 3) - spacing(22) - spacing(6));
    }
    @media #{$mq-4} {
      width: calc(grid-spacing(4, 4, 3) - spacing(22) - spacing(6));
    }
    @media #{$mq-3} {
      width: calc(grid-spacing(3, 4, 3) - spacing(22) - spacing(6));
    }
  }

  &__cta {
    @include link;
  }

  &__content {
    grid-column: 2;
    user-select: none;

    @media #{$mq-1} {
      min-width: grid-spacing(1, 8, 7);
      max-width: grid-spacing(1, 8, 7);
    }
  }

  &__progress {
    width: 100%;
    height: $progress-bar-height;
    background-color: var(--primary-frk-black);
    visibility: hidden;
  }

  &__timer {
    position: relative;
    overflow: hidden;
    height: $progress-bar-height;
    margin-top: spacing(4);
    background-color: var(--grayscale-frk-cool-grey-1);
    grid-column: 1/3;
    grid-row: 2;
    opacity: 0;
    transition: opacity 0.5s;
  }

  &--is-selected {
    opacity: 1;

    .cmp-teaser-slider-featured-article__timer {
      opacity: 1;
    }

    .cmp-teaser-slider-featured-article__progress {
      animation: progress-bar 4s linear;
      visibility: visible;
    }
  }

  &:hover,
  &:focus {
    .cmp-teaser-slider-featured-article__progress {
      animation: reset-progress-bar 0.4s linear;
      animation-play-state: paused;
    }
  }
}

@keyframes progress-bar {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@keyframes reset-progress-bar {
  100% {
    width: 0%;
  }
}
