.cmp-page-counter {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: spacing(30) 0;
  list-style-type: none;
  @media #{$mq-12} {
    margin: spacing(16) 0 spacing(20) 0;
  }

  &__item {
    @include paragraph-s-bold;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding: spacing(1);
    margin: auto spacing(3);
    color: var(--product-explorer-page-counter-item-color);
    text-align: center;

    &:hover,
    &:focus {
      cursor: pointer;
    }

    &--selected {
      background-color: var(--product-explorer-page-counter-selected-item-background-color);
      color: var(--product-explorer-page-counter-selected-item-text-color);

      .cmp-page-counter__page-link {
        color: var(--product-explorer-page-counter-selected-item-text-color);
      }
    }

    &--arrow-left,
    &--arrow-right {
      color: var(--product-explorer-page-counter-item-color);
      text-decoration: none;
    }

    &--arrow-left::before {
      content: '\ea64';
      font-family: var(--remixicon-font);
      font-size: spacing(6);
    }

    &--arrow-right::before {
      content: '\ea6e';
      font-family: var(--remixicon-font);
      font-size: spacing(6);
    }

    &--dots:hover,
    &--dots:focus {
      cursor: default;
    }

    &--disabled {
      pointer-events: none;

      &:hover,
      &:focus {
        background-color: transparent;
        cursor: default;
      }

      .cmp-page-counter__item--arrow-right::before,
      .cmp-page-counter__item--arrow-left::before {
        color: var(--product-explorer-page-counter-arrow-icon-color);
      }
    }
  }

  &__page-link {
    color: var(--product-explorer-page-counter-item-color);
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
