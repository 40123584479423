.cmp-blog-card {
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: var(--blog-card-background-color);
  color: var(--blog-card-text-color);
  color: inherit;
  text-decoration: none;

  &__layout {
    display: grid;

    @media #{$mq-5} {
      column-gap: grid-spacing(5, 0, 1);
    }
    @media #{$mq-45} {
      grid-template-columns: repeat(2, 1fr);
      row-gap: spacing(20);
    }

    @media #{$mq-4} {
      column-gap: grid-spacing(4, 0, 1);
    }
    @media #{$mq-3} {
      column-gap: grid-spacing(3, 0, 1);
      row-gap: spacing(10);
    }
    @media #{$mq-12} {
      column-gap: 0;
      grid-template-columns: repeat(1, 1fr);
      row-gap: spacing(10);
    }
  }

  &__image {
    img {
      aspect-ratio: 16/9;
      object-fit: cover;
    }
  }

  &__date {
    @include paragraph-s;
    padding-right: spacing(3);
    border-right: 1px solid var(--blog-card-tag-border-color);
    color: inherit;
    text-decoration: none;

    @media #{$mq-1} {
      padding-right: spacing(2);
    }
  }

  &__content {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

    @media #{$mq-345} {
      padding: spacing(9) spacing(6) spacing(8) spacing(6);
    }

    @media #{$mq-12} {
      padding: spacing(7) spacing(6) spacing(6) spacing(6);
    }
  }

  &__title {
    @include heading-l-extended-bold;
    margin-top: spacing(5);
    margin-bottom: spacing(1);
    @media #{$mq-3} {
      @include heading-m-extended-bold;
    }
    @media #{$mq-12} {
      @include heading-base-extended-bold;
    }
  }

  &__description {
    @include paragraph-lead-m;
    margin-bottom: spacing(4);
    @media #{$mq-3} {
      @include paragraph-base;
    }
    @media #{$mq-12} {
      @include paragraph-s;
    }
  }

  &__link {
    @include link(var(--blog-card-link-color));
    @include heading-base-extra-bold;
  }

  &__no-results {
    @media #{$mq-5} {
      width: grid-spacing(5, 6, 5);
      margin-left: grid-spacing(5, 3, 3);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 6, 5);
      margin-left: grid-spacing(4, 3, 3);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 6, 5);
      margin-left: grid-spacing(3, 3, 3);
    }
  }
}

.cmp-blog-tag {
  @include paragraph-s-bold;
  display: inline;
  padding-right: spacing(3);
  padding-left: spacing(3);
  line-height: 2; // to avoid overlapping tags when line breaks

  @media #{$mq-1} {
    padding-right: spacing(2);
    padding-left: spacing(2);
  }

  &__list {
    display: inline;
    padding: 0;
    margin: 0;
  }

  &__list-item {
    display: inline-block; // makes a single tag being unbreakable
    padding: spacing(1) spacing(3);
    border: 1px solid var(--blog-card-tag-border-color);
    border-radius: spacing(25);
    line-height: 1.0357; // restores line-height to normal

    &:not(:last-child) {
      margin-right: spacing(1);
    }
  }
}
