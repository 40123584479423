.cmp-nav-language-switcher {
  @include paragraph-base;
  color: var(--language-switcher-in-nav-color);
  text-decoration: none;

  .cmp-header--is-scrolled &,
  .cmp-header--is-opened & {
    color: var(--language-switcher-in-nav-color);

    .cmp-nav-language-switcher--is-active {
      color: var(--primary-frk-black);
    }
  }

  &__wrapper {
    @media #{$mq-123} {
      display: flex;
      justify-content: space-between;
    }
  }

  &__list {
    display: flex;
  }

  &--is-active {
    .cmp-header--is-scrolled &,
    .cmp-header--is-opened & {
      color: var(--primary-frk-black);
    }
    color: var(--language-switcher-in-nav-active-color);
    @include paragraph-base-bold;
  }
}
