.cmp-navigation-subitem {
  display: flex;
  align-items: center;

  &__item {
    @include paragraph-lead-l;
    margin-bottom: spacing(4);
    cursor: pointer;
    transition: all 200ms ease-in-out;
    @include link(var(--primary-frk-black), false, var(--primary-frk-vibrant-red), false);

    @media #{$mq-12} {
      padding-bottom: spacing(5);
      margin-bottom: 0;
      @include paragraph-lead-m;
    }

    .cmp-navigation-accordion__icon {
      line-height: normal;
    }

    &--is-active {
      color: var(--navigation-subitem-color-is-active);
    }

    &:hover,
    &:focus,
    &:active {
      color: var(--navigation-subitem-color-is-active);
    }
  }
}
