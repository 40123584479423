@mixin fade-in($duration) {
  animation: $duration ease-in-out fade-in-animate;
  @keyframes fade-in-animate {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
