.cmp-navigation-product-item {
  $item-height-desktop: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media #{$mq-123} {
    width: 100%;
    box-shadow: none;
  }
  @media #{$mq-45} {
    height: $item-height-desktop;
    border-bottom: 1px solid var(--navigation-product-item-border-color);

    &:nth-child(odd) {
      border-right: 1px solid var(--navigation-product-item-border-color);
    }
  }
  @media #{$mq-4} {
    width: grid-spacing(4, 3, 2);
  }
  @media #{$mq-5} {
    width: grid-spacing(5, 3, 2);
  }

  &__link {
    display: flex;
    width: inherit;
    align-items: center;
    justify-content: space-between;
    color: var(--navigation-product-item-text-color);
    text-decoration: none;
    @media #{$mq-123} {
      padding: spacing(4) 0;
    }
    @media #{$mq-45} {
      padding: spacing(4) spacing(5);
    }
  }

  &__icon {
    @media #{$mq-123} {
      width: spacing(8);
      height: spacing(8);
    }
    @media #{$mq-45} {
      width: spacing(12);
      height: spacing(12);
    }
  }

  &__title {
    @include heading-s-extended-bold;

    @media #{$mq-1} {
      width: grid-spacing(1, 6, 5);
      padding-left: grid-spacing(1, 1, 1);
    }
    @media #{$mq-2} {
      width: grid-spacing(2, 6, 5);
      padding-left: grid-spacing(2, 1, 1);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 6, 5);
      padding-left: grid-spacing(3, 1, 1);
    }
    @media #{$mq-45} {
      padding-left: spacing(4);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 2, 0);
    }
    @media #{$mq-5} {
      width: grid-spacing(5, 2, 0);
    }
  }

  &__cta-icon {
    color: var(--navigation-product-item-cta-icon-color);
    justify-self: flex-end;
  }

  &__left-side {
    display: flex;
    width: inherit;
    align-items: center;
  }

  @media #{$mq-45} {
    &:hover,
    &:focus {
      background-color: var(--navigation-product-item-hover-background-color);

      .cmp-navigation-product-item__cta-icon {
        color: var(--navigation-product-item-cta-icon-color);
      }
    }
  }
}
