.cmp-legal-document-search {
  @media #{$mq-5} {
    margin-right: grid-spacing(5, 2, 2);
    margin-left: grid-spacing(5, 2, 2);
  }
  @media #{$mq-4} {
    margin-right: grid-spacing(4, 2, 2);
    margin-left: grid-spacing(4, 2, 2);
  }
  @media #{$mq-3} {
    margin-right: grid-spacing(3, 2, 2);
    margin-left: grid-spacing(3, 2, 2);
  }

  &__title {
    @media #{$mq-345} {
      @include heading-xxl-extended-bold;
      margin-bottom: spacing(10);
    }
    @media #{$mq-12} {
      @include heading-m-extended-bold;
      margin-bottom: spacing(6);
    }
  }

  &__subtitle {
    @include paragraph-s-bold;
    margin-bottom: spacing(2);
  }

  &__input {
    display: block;
    padding: spacing(3);
    color: var(--form-text-color);
    @media #{$mq-5} {
      width: grid-spacing(5, 3, 3);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 3, 3);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 3, 3);
    }
    @media #{$mq-345} {
      margin-bottom: spacing(6);
    }
    @media #{$mq-12} {
      width: 100%;
      margin-bottom: spacing(4);
    }

    &::placeholder {
      @include paragraph-base;
      position: absolute;
      top: 50%;
      color: var(--form-text-placeholder-text-color);
      transform: translateY(-50%);
    }
  }

  &__button {
    @include button(
      var(--legal-document-search-button-bg-color),
      var(--legal-document-search-button-text-color),
      var(--legal-document-search-button-border-color),
      var(--legal-document-search-button-hover-bg-color),
      var(--legal-document-search-button-hover-text-color),
      var(--legal-document-search-button-hover-border-color)
    );
    cursor: pointer;
    @media #{$mq-345} {
      margin-bottom: spacing(20);
    }
    @media #{$mq-12} {
      margin-bottom: spacing(10);
    }
  }

  &__document-list {
    padding: 0;
    margin: 0;
  }

  &__document {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: spacing(6) 0;
    border-bottom: 1px solid var(--download-list-item-border-color);
    @media #{$mq-12} {
      flex-direction: column;
      align-items: start;
      padding: spacing(4) 0;
    }
  }

  &__document-name {
    @include heading-m-extra-bold;
    @media #{$mq-12} {
      margin-bottom: spacing(3);
    }
  }

  &__document-link {
    @include heading-base-extra-bold;
    display: flex;
    align-items: center;
    color: var(--legal-document-search-download-color);
    text-decoration: none;

    &:hover,
    &:focus {
      color: var(--legal-document-search-download-hover-color);
    }
  }

  &__link-icon {
    font-size: spacing(5.5);
  }
}
