.cmp-countdown-video {
  position: relative;

  .cmp-video__dam-video {
    position: fixed;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
  }
}
