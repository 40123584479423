.cmp-dropdown {
  position: relative;
  margin-top: spacing(7);
  color: var(--primary-frk-black);
  @include paragraph-base;

  &--has-error,
  &--has-error ::placeholder {
    border-color: var(--system-frk-error);
    color: var(--system-frk-error);
  }

  &:focus-within {
    outline: solid 1px;
  }

  &__select {
    border: none;
  }

  & &__control {
    border: solid 2px var(--primary-frk-black);
    border-radius: 0;

    &--menu-is-open,
    &--is-focused {
      &,
      &:hover,
      &:focus {
        border-width: 2px;
        border-color: var(--primary-frk-black);
        box-shadow: none;
      }
    }
  }

  & &__menu {
    border: solid 2px var(--primary-frk-black);
    border-top: none;
    margin-top: 0;
    background-color: white;
    border-radius: 0;
    box-shadow: none;
  }

  & &__option {
    &--is-selected,
    &--is-focused {
      background-color: var(--grayscale-frk-black-5);
      color: var(--primary-frk-black);
    }
  }

  & &__value-container {
    padding: spacing(2) spacing(3);
  }

  & &__input-container {
    padding: 0;
    margin: 0;
  }

  &__value-container--has-value ~ &__indicators &__dropdown-indicator {
    color: var(--primary-frk-black);
  }

  &__input:placeholder-shown {
    color: var(--grayscale-frk-cool-grey-8);
  }

  &__label {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: calc(spacing(7) * -1);
    @include paragraph-s-bold;
  }

  &__indicator-separator {
    display: none;
  }
}
