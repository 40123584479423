.cmp-product-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-right: 0;
  margin-left: 0;

  @media #{$mq-1} {
    gap: grid-spacing(1, 0, 1);
  }
  @media #{$mq-2} {
    gap: grid-spacing(2, 0, 1);
  }
  @media #{$mq-3} {
    gap: grid-spacing(3, 0, 1);
  }
  @media #{$mq-4} {
    gap: grid-spacing(4, 0, 1);
  }
  @media #{$mq-5} {
    gap: grid-spacing(5, 0, 1);
  }
}
