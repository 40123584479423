.cmp-news-list {
  &-item {
    display: grid;
    padding: spacing(4) 0;
    box-shadow: inset 0 2px 0 -1px var(--newslist-item-box-shadow-color);
    color: var(--newslist-item-text-color);
    grid-template-columns: min-content min-content auto min-content;
    grid-template-rows: min-content min-content 1fr;
    transition: all 0.2s ease-in-out;

    &__link {
      text-decoration: none;

      &:first-child {
        .cmp-news-list-item {
          box-shadow: none;
        }
      }
    }

    &__title {
      align-self: top;
      margin-top: spacing(1);
      grid-column: 2 / 4;
      grid-row: 3;
      @include heading-base-extra-bold;
    }

    &__publication-date {
      align-self: flex-end;
      padding-right: spacing(3);
      grid-column: 2 / 4;
      grid-row: 1;
      white-space: nowrap;
      @include caption;
    }

    &__location {
      align-self: start;
      grid-column: 2 / 4;
      grid-row: 2;
      transition: border-color 0.2s ease-in-out;
      @include caption;
    }

    &__image {
      width: 70px;
      align-self: center;
      margin-right: spacing(6);
      aspect-ratio: 1 / 1;
      grid-column: 1;
      grid-row: 1 / 4;
    }

    &::after {
      align-self: center;
      margin-left: spacing(7);
      content: '\ea6c';
      font-family: var(--remixicon-font);
      grid-column: 4;
      grid-row: 1 / 4;
    }

    &:hover,
    &:focus {
      background-color: var(--newslist-item-hover-background-color);
      color: var(--newslist-item-hover-text-color);

      .cmp-event-list-item__location {
        border-color: var(--newslist-item-hover-location-border-color);
      }
    }

    @media #{$mq-1} {
      border-right: grid-spacing(1, 1, 1) solid transparent;
      border-left: grid-spacing(1, 1, 1) solid transparent;
    }
    @media #{$mq-2} {
      border-right: grid-spacing(2, 1, 1) solid transparent;
      border-left: grid-spacing(2, 1, 1) solid transparent;
    }
    @media #{$mq-3} {
      border-right: grid-spacing(3, 1, 1) solid transparent;
      border-left: grid-spacing(3, 1, 1) solid transparent;
    }
    @media #{$mq-4} {
      border-right: grid-spacing(4, 1, 1) solid transparent;
      border-left: grid-spacing(4, 1, 1) solid transparent;
    }
    @media #{$mq-5} {
      border-right: grid-spacing(5, 1, 1) solid transparent;
      border-left: grid-spacing(5, 1, 1) solid transparent;
    }

    @media #{$mq-345} {
      padding: spacing(12) 0;

      &__title {
        grid-column: 2 / 4;
        grid-row: 2;
        @include heading-l-extra-bold;
      }

      &__publication-date {
        grid-column: 2;
        @include paragraph-s;
      }

      &__location {
        align-self: flex-end;
        padding: 0 spacing(3);
        border-color: var(--newslist-item-location-border-color);
        border-left: 1px solid;
        grid-column: 3;
        grid-row: 1;
        @include paragraph-s;
      }

      &__image {
        width: 90px;
        grid-column: 1;
        grid-row: 1 / 3;
      }

      &::after {
        grid-row: 1 / 3;
      }
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    margin: spacing(10) 0;

    @media #{$mq-345} {
      margin: spacing(20) 0;
    }

    &-link {
      @include button(
        var(--newslist-button-background-color),
        var(--newslist-button-font-color),
        var(--newslist-button-border-color),
        var(--newslist-button-on-hover-focus-background-color),
        var(--newslist-button-on-hover-focus-font-color),
        var(--newslist-button-on-hover-focus-border-color)
      );
    }

    &-label {
      display: flex;
      align-items: center;

      &::before {
        margin-right: spacing(4);
        content: '\ea13';
        font-family: var(--remixicon-font);
        line-height: 100%;
      }
    }
  }
}
