.cmp-countdown-timer {
  &__wrapper {
    display: flex;
    width: grid-spacing(5, 10, 9);
    justify-content: space-between;
    margin-left: grid-spacing(5, 1, 1);
    @media #{$mq-4} {
      width: grid-spacing(4, 10, 9);
      margin-left: grid-spacing(4, 1, 1);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 8, 9);
      margin-left: grid-spacing(3, 2, 1);
    }
    @media #{$mq-2} {
      width: grid-spacing(2, 10, 9);
      margin-left: grid-spacing(2, 1, 1);
    }
    @media #{$mq-1} {
      width: grid-spacing(1, 10, 9);
      margin-left: grid-spacing(1, 1, 1);
    }
  }
}
