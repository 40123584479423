.cmp-text-container {
  @include grid-layout;
  @include remove-side-margins;

  & > * {
    grid-column: #{column-index(1)} / #{column-index(12, 1)};
  }

  @media #{$mq-345} {
    &--one-column-left > * {
      grid-column: #{column-index(2)} / #{column-index(8, 1)};
    }

    &--one-column-center > * {
      grid-column: #{column-index(4)} / #{column-index(10, 1)};
    }

    &--two-columns {
      & > :nth-of-type(even) {
        grid-column: #{column-index(7)} / #{column-index(11, 1)};
      }

      & > :nth-of-type(odd) {
        grid-column: #{column-index(2)} / #{column-index(6, 1)};
      }
    }

    &--three-columns {
      & > :nth-of-type(3n + 1) {
        grid-column: #{column-index(1)} / #{column-index(4, 1)};
      }

      & > :nth-of-type(3n + 2) {
        grid-column: #{column-index(5)} / #{column-index(8, 1)};
      }

      & > :nth-of-type(3n + 3) {
        grid-column: #{column-index(9)} / #{column-index(12, 1)};
      }
    }
  }
}
