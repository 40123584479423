@use 'sass:map';

:root {
  --grid-number-of-columns: 12;
}

@function get-column-width($breakpoint, $side-margin, $gutter-width, $number-columns: 12) {
  $number-of-gutters: calc(#{$number-columns} - 1);
  @return calc(
    (#{$breakpoint} - (2 * #{$side-margin}) - (#{$number-of-gutters} * #{$gutter-width})) / #{$number-columns}
  );
}

$grid-small: (
  column-width: get-column-width(100vw, var(--page-container-side-margin-s), spacing(2), var(--grid-number-of-columns)),
  column-width-percentage:
    get-column-width(100%, var(--page-container-side-margin-s), spacing(2), var(--grid-number-of-columns)),
  gutter-width: spacing(2),
  side-margins: var(--page-container-side-margin-s),
);
$grid-small-m: (
  column-width: get-column-width(100vw, var(--page-container-side-margin-m), spacing(4), var(--grid-number-of-columns)),
  column-width-percentage:
    get-column-width(100%, var(--page-container-side-margin-m), spacing(4), var(--grid-number-of-columns)),
  gutter-width: spacing(4),
  side-margins: var(--page-container-side-margin-m),
);
$grid-medium-l: (
  column-width: get-column-width(100vw, var(--page-container-side-margin-l), spacing(6), var(--grid-number-of-columns)),
  column-width-percentage:
    get-column-width(100%, var(--page-container-side-margin-l), spacing(6), var(--grid-number-of-columns)),
  gutter-width: spacing(6),
  side-margins: var(--page-container-side-margin-l),
);
$grid-large-xl: (
  column-width: get-column-width(100vw, var(--page-container-side-margin-xl), spacing(6), var(--grid-number-of-columns)),
  column-width-percentage:
    get-column-width(100%, var(--page-container-side-margin-xl), spacing(6), var(--grid-number-of-columns)),
  gutter-width: spacing(6),
  side-margins: var(--page-container-side-margin-xl),
);
$grid-x-large: (
  column-width:
    get-column-width(1921px, var(--page-container-side-margin-x-large), spacing(6), var(--grid-number-of-columns)),
  column-width-percentage:
    get-column-width(100%, var(--page-container-side-margin-x-large), spacing(6), var(--grid-number-of-columns)),
  gutter-width: spacing(6),
  side-margins: var(--page-container-side-margin-x-large),
);
$mq-to-grid: (
  1: $grid-small,
  2: $grid-small-m,
  3: $grid-medium-l,
  4: $grid-large-xl,
  5: $grid-x-large,
);

@function grid-spacing($mq: 0, $columns: 0, $gutters: 0, $side-margins: 0) {
  @if $mq > 0 and $mq < 6 {
    @return calc(
      #{$columns} * #{map.get(map.get($mq-to-grid, $mq), column-width)} + #{$gutters} * #{map.get(
          map.get($mq-to-grid, $mq),
          gutter-width
        )} + #{$side-margins} * #{map.get(map.get($mq-to-grid, $mq), side-margins)}
    );
  }

  @return 0;
}

@mixin grid-layout {
  display: grid;

  @for $mq from 1 through 5 {
    @media #{map.get($number-to-mq, $mq)} {
      $col-width: #{map.get(map.get($mq-to-grid, $mq), column-width-percentage)};
      $gutter-width: #{map.get(map.get($mq-to-grid, $mq), gutter-width)};
      $side-padding: #{map.get(map.get($mq-to-grid, $mq), side-margins)};
      grid-template-columns:
        $side-padding repeat(calc(var(--grid-number-of-columns) / 2 - 1), #{$col-width} #{$gutter-width})
        #{$col-width} repeat(2, calc(#{$gutter-width} / 2)) repeat(
          calc(var(--grid-number-of-columns) / 2 - 1),
          #{$col-width} #{$gutter-width}
        )
        #{$col-width} $side-padding;
    }
  }
}

@function column-index($column: 0, $end: 0, $mid-column: 0) {
  @if $column == 0 {
    @return 1;
  }
  @if $column == 13 {
    @return calc((var(--grid-number-of-columns) + 1) * 2 + 1);
  }

  $grid-col: ($column * 2) + $end + $mid-column;
  @if $column <= 6 {
    @return $grid-col;
  }
  @return $grid-col + 1;
}
