.cmp-download-filter {
  @media #{$mq-5} {
    width: grid-spacing(5, 8, 7);
    margin-left: grid-spacing(5, 2, 2);
  }
  @media #{$mq-4} {
    width: grid-spacing(4, 8, 7);
    margin-left: grid-spacing(4, 2, 2);
  }
  @media #{$mq-3} {
    width: grid-spacing(3, 8, 7);
    margin-left: grid-spacing(3, 2, 2);
  }
  @media #{$mq-12} {
    width: 100%;
  }

  &__label {
    @include paragraph-s-bold;
    display: block;
    margin-bottom: spacing(2);
  }

  &__select {
    @include paragraph-base;
    padding: spacing(3);
    border: $form-border-width solid var(--form-dropdown-border-color);
    background-color: var(--form-dropdown-background-color);
    color: var(--form-dropdown-text-color);

    @media #{$mq-12} {
      width: 100%;
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 3, 2);
    }

    @media #{$mq-4} {
      width: grid-spacing(4, 2, 1);
    }

    @media #{$mq-5} {
      width: grid-spacing(5, 2, 1);
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: spacing(20);
    @media #{$mq-12} {
      margin-top: spacing(10);
    }
  }

  &__button {
    @include button(
      var(--primary-frk-white),
      var(--primary-frk-black),
      var(--primary-frk-black),
      var(--primary-frk-black),
      var(--primary-frk-white),
      var(--primary-frk-black)
    );
    cursor: pointer;
  }

  &__list {
    padding: 0;
    margin: 0;
    margin-top: spacing(20);
    @media #{$mq-12} {
      margin-top: spacing(14);
    }
  }
}
