.cmp-navigation-product-list {
  overflow: auto;
  @media #{$mq-12} {
    $back-button-spacing: calc(24px * 2);
    $teaser-height-mobile: 220px;
    height: calc(100vh - ($header-height-mobile + $back-button-spacing + spacing(8) + $teaser-height-mobile));
    padding-top: spacing(8);
    padding-bottom: spacing(8);
  }
  @media #{$mq-3} {
    $back-button-spacing: calc(24px * 2);
    $teaser-height-tablet: 280px;
    height: calc(100vh - ($header-height-mobile + $back-button-spacing + spacing(8) + $teaser-height-tablet));
    padding-top: spacing(8);
    padding-bottom: spacing(8);
  }
  @media #{$mq-4} {
    width: grid-spacing(4, 6, 5);
  }
  @media #{$mq-5} {
    width: grid-spacing(5, 6, 5);
  }
  @media #{$mq-45} {
    $teaser-height-desktop: 280px;
    $diff-parent-padding-top-and-header-height: calc(160px - $header-height-desktop);
    height: calc(
      100vh -
        ($header-height-desktop + spacing(10) + $diff-parent-padding-top-and-header-height + $teaser-height-desktop)
    );
    padding-top: spacing(10);
    padding-bottom: spacing(10);
  }
  @media #{$mq-1} {
    padding-right: var(--page-container-side-margin-s);
    padding-left: var(--page-container-side-margin-s);
  }
  @media #{$mq-2} {
    padding-right: var(--page-container-side-margin-m);
    padding-left: var(--page-container-side-margin-m);
  }
  @media #{$mq-3} {
    padding-right: var(--page-container-side-margin-l);
    padding-left: var(--page-container-side-margin-l);
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }

  .cmp-responsive-image__image {
    object-fit: contain;
  }
}
