.cmp-hero-slider {
  @include with-max-width-center;
  position: relative;
  display: grid;
  align-items: center;
  background-color: var(--slider-hero-background-color);

  @media #{$mq-12} {
    padding-bottom: spacing(8);
  }
  @media #{$mq-3} {
    padding-bottom: spacing(15);
  }
  @media #{$mq-45} {
    padding-bottom: spacing(16);
  }
  @media #{$mq-345} {
    padding-top: $header-height-desktop;
  }
  @media #{$mq-12} {
    padding-top: $header-height-mobile;
  }

  &__content {
    position: relative;
    justify-content: space-between;
    animation: fade-in 0.3s ease-in-out;
    color: var(--slider-hero-text-color);
    grid-column: 1 / 3;

    @media #{$mq-1} {
      margin-top: spacing(16.5, true);
      margin-left: grid-spacing(1, 0, 0, 1);
    }

    @media #{$mq-2} {
      margin-top: spacing(16.5, true);
      margin-left: grid-spacing(2, 0, 0, 1);
    }

    @media #{$mq-3} {
      margin-top: spacing(21.25, true);
      margin-left: grid-spacing(3, 0, 0, 1);
    }

    @media #{$mq-4} {
      margin-left: grid-spacing(4, 1, 1, 1);
      grid-row: 1;
    }

    @media #{$mq-5} {
      margin-left: grid-spacing(5, 1, 1, 1);
      grid-row: 1;
    }
  }

  &__content-pretitle {
    @include paragraph-lead-m-bold;
    grid-column: 1 / 3;
    grid-row: 1;
    @media #{$mq-4} {
      max-width: grid-spacing(4, 5, 4);
    }
    @media #{$mq-5} {
      max-width: grid-spacing(5, 5, 4);
    }
  }

  &__content-title {
    @include display-xl-expanded-bold;
    margin: spacing(5) 0;
    grid-column: 1 / 3;
    grid-row: 1;
    @media #{$mq-4} {
      max-width: grid-spacing(4, 7, 6);
    }
    @media #{$mq-5} {
      max-width: grid-spacing(5, 7, 6);
    }
    @media #{$mq-123} {
      margin: spacing(3) 0;
    }
  }

  &__content-description {
    margin-bottom: spacing(5);
    grid-column: 1 / 3;
    grid-row: 1;
    word-break: break-word;
    @media #{$mq-345} {
      @include paragraph-lead-m;
    }
    @media #{$mq-12} {
      @include paragraph-s;
    }
    @media #{$mq-4} {
      max-width: grid-spacing(4, 6, 5);
    }
    @media #{$mq-5} {
      max-width: grid-spacing(5, 5, 4);
    }
  }

  &__content-cta {
    grid-column: 1 / 3;
    grid-row: 1;
    @include button(
      var(--slider-hero-button-background-color),
      var(--slider-hero-button-font-color),
      var(--slider-hero-button-border-color),
      var(--slider-hero-button-hover-background-color),
      var(--slider-hero-button-hover-font-color),
      var(--slider-hero-button-hover-border-color)
    );
  }

  &__image {
    aspect-ratio: 16 / 9;
    grid-column: 1 / 3;
    grid-row: 1;
    object-fit: cover;
    transition: 0.3s ease-in-out all;

    @media #{$mq-1} {
      margin-left: grid-spacing(1, 0, 0);
    }

    @media #{$mq-2} {
      margin-left: grid-spacing(2, 0, 0);
    }

    @media #{$mq-3} {
      margin-left: grid-spacing(3, 0, 0);
    }

    @media #{$mq-4} {
      margin-left: grid-spacing(4, 4, 4, 1);
    }

    @media #{$mq-5} {
      margin-left: grid-spacing(5, 4, 4, 1);
    }

    .cmp-responsive-image__inner-wrapper {
      position: relative;

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 100%;
        content: '';
        @media #{$mq-45} {
          background: var(--hero-gradient-desktop);
        }
        @media #{$mq-123} {
          background: var(--hero-gradient-mobile);
        }
      }
    }
  }

  &__slider {
    margin-top: spacing(14);
    color: var(--slider-hero-text-color);
    grid-column: 1 / 3;
    outline: none;

    @media #{$mq-1} {
      margin-left: grid-spacing(1, 0, 0, 1);
      grid-row: 3;
    }

    @media #{$mq-2} {
      margin-left: grid-spacing(2, 0, 0, 1);
      grid-row: 3;
    }

    @media #{$mq-3} {
      margin-left: grid-spacing(3, 0, 0, 1);
      grid-row: 3;
    }

    @media #{$mq-4} {
      margin-left: grid-spacing(4, 1, 1, 1);
    }

    @media #{$mq-5} {
      margin-left: grid-spacing(5, 1, 1, 1);
    }
  }
}

.cmp-container--no-breadcrumbs {
  .cmp-hero-slider {
    @media #{$mq-123} {
      padding-top: $header-height-mobile;
    }

    @media #{$mq-45} {
      padding-top: $header-height-desktop;
    }
  }
}

.flickity-viewport {
  overflow: hidden;
  cursor: grab;
}

.flickity-button {
  display: none;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
