.cmp-download-list {
  padding: 0;

  &__item,
  &__item--with-languages {
    display: flex;
    flex-grow: 1;
    border-bottom: 1px solid var(--download-list-item-border-color);
    @media #{$mq-3} {
      margin: 0 grid-spacing(3, 2, 2);
    }

    @media #{$mq-4} {
      margin: 0 grid-spacing(4, 2, 2);
    }

    @media #{$mq-5} {
      margin: 0 grid-spacing(5, 2, 2);
    }
  }

  &__item {
    &--with-languages {
      .cmp-download-list {
        &__content {
          @media #{$mq-123} {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
          }

          &:hover,
          &:focus {
            .cmp-download-list__action-languages {
              display: block;
            }
          }
        }

        &__action {
          position: relative;
          width: 100%;

          &-label {
            @include link(var(--download-list-item-label-link-color), false, inherit);
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: spacing(3);
            pointer-events: none;

            @media #{$mq-123} {
              justify-content: flex-start;
            }
          }

          &-label::after {
            margin-left: spacing(2.5);
            content: '\ea4e';
            font-family: var(--remixicon-font);
            font-size: spacing(6);
          }

          &-languages {
            position: absolute;
            z-index: 1;
            display: none;
            padding: spacing(6) 0;
            background-color: var(--download-list-item-languages-background-color);
            box-shadow: spacing(1) spacing(1) spacing(5) rgb(0 0 0 / 25%);

            @media #{$mq-12} {
              @include remove-side-margins;
              right: 0;
              left: 0;
            }

            @media #{$mq-3} {
              width: grid-spacing(3, 6, 5);
            }

            @media #{$mq-4} {
              width: grid-spacing(4, 4, 3);
            }

            @media #{$mq-5} {
              width: grid-spacing(5, 4, 3);
            }

            @media #{$mq-45} {
              right: 0;
            }
          }

          &-language-link {
            @include paragraph-base;
            @include link(var(--download-list-item-languages-link-color), false, inherit);
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: spacing(2) spacing(6) spacing(4);

            @media #{$mq-1} {
              padding: spacing(2) grid-spacing(1, 1, 1) spacing(4);
            }

            @media #{$mq-2} {
              padding: spacing(2) grid-spacing(2, 1, 1) spacing(4);
            }

            &::before {
              @media #{$mq-12} {
                right: 0;
                left: 0;
                padding: 0;
              }
              position: absolute;
              right: spacing(6);
              bottom: 0;
              left: spacing(6);
              width: auto;
              padding: 0 spacing(6);
              border-bottom: 1px solid var(--download-list-item-languages-link-border-color);
              content: '';
            }
          }

          &-language-link::after {
            color: var(--download-list-item-languages-link-icon-color);
            content: '\ec5a';
            font-family: var(--remixicon-font);
            font-size: spacing(6);
          }

          &-language-link:hover,
          &-language-link:focus {
            background-color: var(--download-list-item-languages-link-hover-color);

            &::after {
              color: var(--download-list-item-languages-link-hover-icon-color);
            }
          }

          &-language-link:last-child::before {
            border-bottom: none;
          }

          &:hover,
          &:focus {
            .cmp-download-list__action-languages {
              display: block;
            }

            .cmp-download-list__action-label {
              color: var(--download-list-item-label-hover-color);
            }

            .cmp-download-list__action-label::after {
              color: var(--download-list-item-label-hover-icon-color);
              content: '\ea78';
            }
          }
        }
      }

      .cmp-download-list__image + .cmp-download-list__content {
        .cmp-download-list__action {
          // the below style will apply to cmp-download-list__image + --no-thumbnails
          &-languages {
            @media #{$mq-1} {
              left: grid-spacing(1, -3, -3);
            }
            @media #{$mq-2} {
              left: grid-spacing(2, -3, -3);
            }
            @media #{$mq-3} {
              left: grid-spacing(3, -1, -1);
            }
          }
        }
      }
    }
    @media #{$mq-345} {
      &:hover,
      &:focus {
        background-color: var(--gradations-frk-champagne-50);
        cursor: pointer;
      }
    }

    &:not(&--with-languages) {
      .cmp-download-list__action-wrapper {
        display: flex;
        width: 100%;
      }
    }
  }

  &__image {
    @media #{$mq-12} {
      margin: spacing(4) spacing(4) spacing(4) 0;
    }

    @media #{$mq-345} {
      margin: spacing(6) 0 spacing(6) spacing(6);
    }

    @media #{$mq-1} {
      width: grid-spacing(1, 3, 2);
    }

    @media #{$mq-2} {
      width: grid-spacing(2, 3, 2);
    }

    @media #{$mq-3} {
      width: grid-spacing(3, 1);
    }

    @media #{$mq-4} {
      width: grid-spacing(4, 1);
    }

    @media #{$mq-5} {
      width: grid-spacing(5, 1);
    }
  }

  &__content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    @media #{$mq-12} {
      padding: spacing(4) 0;
    }

    @media #{$mq-345} {
      padding: spacing(6);
    }
  }

  &__title {
    color: var(--primary-frk-black);
    @media #{$mq-123} {
      @include heading-base-extra-bold;
      padding-right: spacing(3);
      word-break: break-all;
    }

    @media #{$mq-45} {
      @include heading-m-extra-bold;
    }
  }

  &__action {
    &-label {
      @include heading-base-extra-bold;
      color: var(--download-list-item-action-label-color);
    }

    &-label::after {
      @include link(var(--download-list-item-action-label-icon-color), false, inherit);
      content: '\ec5a';
      font-family: var(--remixicon-font);
      font-size: spacing(6);
    }

    &-label:hover::after,
    &-label:focus::after {
      color: var(--download-list-item-action-label-hover-icon-color);
    }
  }

  &--no-thumbnails {
    .cmp-download-list__image {
      display: none;
    }

    .cmp-download-list__action {
      &-languages {
        // we need !important to override the properties set on line 260
        // because we can have .cmp-download-list__image class with --no-thumbnails class applied
        @media #{$mq-123} {
          left: 0 !important;
        }
      }
    }
  }

  &__file-info {
    display: block;
    color: var(--secondary-frk-blueberry);
    @include paragraph-base;
  }

  &__action-wrapper {
    text-decoration: none;

    @media #{$mq-345} {
      &:hover,
      &:focus {
        background-color: var(--gradations-frk-champagne-50);
        cursor: pointer;
      }
    }
  }
}
