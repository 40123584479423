.cmp-image-gallery {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  margin: 0 auto;
  gap: spacing(4);
  @include with-max-width-center;
  @include remove-side-margins;
  @media #{$mq-12} {
    @include with-left-and-right-spacing;
  }
  @media #{$mq-345} {
    padding-bottom: spacing(8);
  }

  &__slides {
    display: grid;
    width: 100%;
    height: auto;
    grid-auto-flow: column;
    transition: transform 0.5s ease;
  }

  &__slide {
    position: relative;
    height: auto;
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    transition: flex 0.5s ease, margin 0.5s ease;
    @media #{$mq-5} {
      width: grid-spacing(5, 5, 4);
      margin-right: grid-spacing(5, 0, 1);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 5, 4);
      margin-right: grid-spacing(4, 0, 1);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 6, 5);
      margin-right: grid-spacing(3, 0, 1);
    }
    @media #{$mq-2} {
      width: grid-spacing(2, 12, 11);
      margin-right: grid-spacing(2, 0, 1);
    }

    @media #{$mq-1} {
      width: grid-spacing(1, 12, 11);
      margin-right: grid-spacing(1, 0, 1);
    }

    &:not(&--active) {
      .cmp-image-gallery__image-container {
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: var(--transparency-frk-white-50-trans);
          content: '';
          opacity: 1;
          transition: opacity 0.5s ease;
        }
      }
    }

    &--active {
      @media #{$mq-5} {
        width: grid-spacing(5, 6, 5);
      }
      @media #{$mq-4} {
        width: grid-spacing(4, 6, 5);
      }
      @media #{$mq-3} {
        width: grid-spacing(3, 8, 7);
      }

      .cmp-image-gallery__image-caption {
        display: block;
      }
    }

    &:first-child {
      @media #{$mq-5} {
        margin-left: grid-spacing(5, 1, 1, 1);
      }
      @media #{$mq-4} {
        margin-left: grid-spacing(4, 1, 1, 1);
      }
      @media #{$mq-3} {
        margin-left: grid-spacing(3, 1, 1, 1);
      }
    }
  }

  &__image-container {
    position: relative;
    width: 100%;
    aspect-ratio: 4 / 3;
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }

  &__image-caption {
    position: absolute;
    top: calc(100% + #{spacing(4)});
    display: none;
    color: var(--primary-frk-black);
    text-align: left;
    @include paragraph-base-bold;
    @media #{$mq-5} {
      width: grid-spacing(5, 5, 4);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 5, 2);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 6, 5);
    }
    @media #{$mq-2} {
      width: grid-spacing(2, 12, 11, 0);
    }
    @media #{$mq-1} {
      width: grid-spacing(1, 12, 11, 0);
    }
    @media #{$mq-12} {
      position: initial;
      margin-top: spacing(2);
      @include paragraph-s;
    }
  }

  &__navigation {
    display: flex;
    justify-content: end;
    gap: spacing(6);
    @media #{$mq-5} {
      width: grid-spacing(5, 6, 5);
      margin-left: grid-spacing(5, 1, 1, 1);
    }

    @media #{$mq-4} {
      width: grid-spacing(4, 6, 5);
      margin-left: grid-spacing(4, 1, 1, 1);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 8, 7);
      margin-left: grid-spacing(3, 1, 1, 1);
    }
    @media #{$mq-2} {
      width: grid-spacing(2, 12, 11);
      margin-right: grid-spacing(2, 0, 1);
      gap: spacing(4);
    }
    @media #{$mq-1} {
      width: grid-spacing(1, 12, 11);
      margin-right: grid-spacing(1, 0, 1);
      gap: spacing(2);
    }
  }

  &__navigation-button {
    z-index: 1;
    display: flex;
    padding: spacing(3);
    border: none;
    background-color: var(--primary-frk-vibrant-red);
    color: var(--primary-frk-white);
    cursor: pointer;
    font-size: spacing(6);

    &[disabled] {
      @include button-disabled;

      &:hover,
      &:focus {
        color: var(--primary-frk-white);
      }
    }
  }
}
