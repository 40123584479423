.cmp-navigation {
  position: fixed;
  right: 0;
  left: 0;
  background-color: var(--navigation-background-color);
  color: var(--navigation-text-color);
  opacity: 0;
  transition: all 0.15s ease-in-out;
  visibility: hidden;

  @media #{$mq-123} {
    top: $header-height-mobile;
    bottom: $header-height-mobile;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media #{$mq-45} {
    top: $header-height-desktop;
    bottom: $header-height-desktop;
  }

  &--is-opened {
    overflow: auto;
    opacity: 1;
    overscroll-behavior: contain;
    visibility: visible;
    @media #{$mq-123} {
      top: $header-height-mobile;
      bottom: 0;
      padding-bottom: env(safe-area-inset-bottom);
    }
    @media #{$mq-45} {
      height: $header-open-height-desktop;
    }
    @media print {
      .wishlist-print & {
        position: relative;
      }
    }

    // FRAN-2436
    @media #{$iphone-mini-13} {
      height: calc(100vh - 170px);
    }
  }

  &__language-switcher {
    @media #{$mq-45} {
      display: none;
    }
    @media #{$mq-1} {
      padding-left: var(--page-container-side-margin-s);
    }
    @media #{$mq-2} {
      padding-left: var(--page-container-side-margin-m);
    }
    @media #{$mq-3} {
      padding-left: var(--page-container-side-margin-l);
    }
  }

  &__container {
    @include with-max-width-center;
    @include with-left-and-right-spacing;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    overscroll-behavior: contain;
    @media #{$mq-123} {
      padding-bottom: spacing(8);
      overflow-y: auto;
    }
  }

  &__items {
    @media #{$mq-4} {
      padding-left: grid-spacing(4, 1, 1);
    }
    @media #{$mq-5} {
      padding-left: grid-spacing(5, 1, 1);
    }
    @media #{$mq-45} {
      height: 100%;
      padding-top: spacing(20);
    }

    ul {
      padding-left: 0;
    }

    &-languageswitcher {
      @media #{$mq-45} {
        display: none;
      }
    }
  }
}
