.cmp-massive-text {
  position: relative;
  display: block;
  overflow: hidden;
  max-width: grid-spacing(5, 8, 7);
  height: 100%;
  margin: 0 auto;
  @media #{$mq-4} {
    max-width: grid-spacing(4, 8, 7);
  }
  @media #{$mq-3} {
    max-width: grid-spacing(3, 10, 9);
  }
  @media #{$mq-2} {
    max-width: grid-spacing(2, 10, 9);
  }
  @media #{$mq-1} {
    max-width: grid-spacing(1, 12, 11);
  }

  &__title {
    @include massive-text-component-title;
    padding-bottom: spacing(4);
    background: var(--primary-frk-white);
    mix-blend-mode: screen;
    text-align: center;
  }

  &__subtitle {
    @include massive-text-component-subtitle;
    padding-right: grid-spacing(5, 1, 1, 0);
    background: var(--primary-frk-white);
    mix-blend-mode: screen;
    @media #{$mq-4} {
      padding-right: grid-spacing(4, 1, 1, 0);
    }
    @media #{$mq-3} {
      padding-right: grid-spacing(3, 1, 1, 0);
    }
    @media #{$mq-2} {
      padding-right: grid-spacing(2, 1, 1, 0);
    }
    @media #{$mq-1} {
      padding-right: 0;
    }
  }

  .cmp-massive-text__video,
  .cmp-massive-text__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: spacing(0.5);
    margin: 0;
    object-fit: cover;
  }
}
