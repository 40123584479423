:root {
  --primary-frk-vibrant-red: #e12e40;
  --primary-frk-black: #010101;
  --primary-frk-white: #fff;
  --primary-mepamsa-dark-green: #397935;
  --primary-mepamsa-dark-green-hover: #275324;
  --primary-roblin-black: #00303c;
  --primary-roblin-black-5: #7e969b;
  --primary-eurodomo-blue: #004890;
  --primary-eurodomo-blue-700: #003060;
  --primary-chainlink-blue: #00a4a7;
  --primary-chainlink-blue-700: #00787a;
  --secondary-frk-burgundy: #501e23;
  --secondary-frk-dark-coffee: #3c281e;
  --secondary-frk-mocha: #826450;
  --secondary-frk-wheat: #e2b27d;
  --secondary-frk-champagne: #f0e1e1;
  --secondary-frk-blueberry: #2d324b;
  --secondary-frk-lavender: #416478;
  --secondary-frk-rosemary: #afc3c3;
  --secondary-frk-basil: #2d462d;
  --secondary-frk-sage: #becdb9;
  --gradations-frk-vibrant-red-20: #b92c36;
  --gradations-frk-vibrant-red-50: #f79ba1;
  --gradations-frk-vibrant-red-10: #fdebec;
  --gradations-frk-burgundy-50: #9c848a;
  --gradations-frk-dark-coffee-50: #9d938e;
  --gradations-frk-mocha-50: #c2b0a8;
  --gradations-frk-wheat-50: #f0d8be;
  --gradations-frk-champagne-50: #f7f0ef;
  --gradations-frk-blueberry-50: #9799a4;
  --gradations-frk-lavender-50: #9fb1bb;
  --gradations-frk-rosemary-50: #d6e1e1;
  --gradations-frk-basil-50: #96a396;
  --gradations-frk-sage-50: #dfe6dc;
  --gradations-frk-wheat-75: #e9c59e;
  --transparency-frk-white-15-trans: rgb(255 255 255 / 15%);
  --transparency-frk-white-50-trans: rgb(255 255 255 / 50%);
  --transparency-frk-white-100-trans: rgb(255 255 255 / 0%);
  --transparency-frk-black-50-trans: rgba(1 1 1 / 50%);
  --transparency-frk-black-80-trans: rgba(1 1 1 / 80%);
  --grayscale-frk-cool-gray-11: #53565a;
  --grayscale-frk-cool-grey-8: #898b8d;
  --grayscale-frk-cool-grey-5: #b2b1b1;
  --grayscale-frk-cool-grey-1: #d9d8d7;
  --grayscale-frk-black-5: #f2f2f2;
  --system-frk-error: #ff7473;
  --system-frk-success: #76e181;
  --notification-error: #f9b9ab;
  --notification-warning: #fdef9e;
  --notification-success: #8cedbd;
}
