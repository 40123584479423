.cmp-product-explorer-filter-list {
  @media #{$mq-12} {
    padding-bottom: spacing(2);
    overflow-x: auto;
  }
  @media #{$mq-12} {
    max-width: 100%;
  }
  @media #{$mq-3} {
    max-width: grid-spacing(3, 10, 9);
  }
  @media #{$mq-4} {
    max-width: grid-spacing(4, 11, 10);
  }
  @media #{$mq-5} {
    max-width: grid-spacing(5, 11, 10);
  }

  &-search__wrapper {
    position: relative;
  }

  &-search__button {
    position: absolute;
    z-index: 2;
    top: 0;
    right: spacing(4);
    width: spacing(6);
    height: spacing(6);
    border: none;
    background: none;
    transform: translateY(50%);
  }

  &-search__icon {
    position: absolute;
    top: 0;
    right: 0;
    font-size: spacing(6);
  }

  &__search {
    @include paragraph-base;
    position: relative;
    padding: spacing(3) spacing(4);
    border: $form-border-width solid var(--form-text-textarea-border-color);
    margin-right: spacing(2);
    margin-bottom: spacing(2);
    background-color: var(--form-text-background-color);

    &:focus,
    &:focus-visible {
      border: $form-border-width solid var(--form-text-textarea-focus-border-color);
      outline: none;
    }

    &:focus::placeholder {
      color: transparent;
    }
  }
}

.cmp-product-explorer-filter-list__list {
  display: flex;

  @media #{$mq-12} {
    flex-wrap: nowrap;
  }

  @media #{$mq-345} {
    flex-wrap: wrap;
  }

  .cmp-multi-select-filter__trigger {
    margin-right: spacing(2);
    margin-bottom: spacing(2);
  }
}
