.cmp-grid {
  position: fixed;
  z-index: 9;
  top: 0;
  right: 0;
  left: 0;
  display: none;
  max-width: var(--page-max-width);
  height: 100vh;
  justify-content: center;
  border-right: 1px solid forestgreen;
  border-left: 1px solid forestgreen;
  margin: 0 auto;
  opacity: 0.2;
  pointer-events: none;

  @media #{$mq-1} {
    padding: 0 var(--page-container-side-margin-s);
  }
  @media #{$mq-2} {
    padding: 0 var(--page-container-side-margin-m);
  }
  @media #{$mq-3} {
    padding: 0 var(--page-container-side-margin-l);
  }
  @media #{$mq-4} {
    padding: 0 var(--page-container-side-margin-xl);
  }
  @media #{$mq-5} {
    padding: 0 var(--page-container-side-margin-x-large);
  }

  &__column {
    background-color: fuchsia;
    @media #{$mq-1} {
      width: grid-spacing(1, 1);
      margin-left: grid-spacing(1, 0, 1);
    }
    @media #{$mq-2} {
      width: grid-spacing(2, 1);
      margin-left: grid-spacing(2, 0, 1);
    }
    @media #{$mq-3} {
      width: grid-spacing(3, 1);
      margin-left: grid-spacing(3, 0, 1);
    }
    @media #{$mq-4} {
      width: grid-spacing(4, 1);
      margin-left: grid-spacing(4, 0, 1);
    }
    @media #{$mq-5} {
      width: grid-spacing(5, 1);
      margin-left: grid-spacing(5, 0, 1);
    }

    &:first-of-type {
      margin-left: 0;
    }
  }

  &--small-s {
    @media #{$mq-1} {
      display: flex;
    }
  }

  &--small-m {
    @media #{$mq-2} {
      display: flex;
    }
  }

  &--medium-l {
    @media #{$mq-3} {
      display: flex;
    }
  }

  &--large-xl {
    @media #{$mq-4} {
      display: flex;
    }
  }

  &--x-large {
    @media #{$mq-5} {
      display: flex;
    }
  }
}
