.black-theme {
  //  Countdown campaign - Thank you and Form pages - Text component
  --countdown-date-time-color: var(--primary-frk-white);
  --countdown-headlines-color: var(--primary-frk-white);
  --countdown-teaser-text-color: var(--primary-frk-white);
  --countdown-teaser-button-text-color: var(--primary-frk-white);
  --countdown-teaser-button-background-color: var(--primary-frk-vibrant-red);
  --countdown-teaser-button-border-color: var(--primary-frk-vibrant-red);
  --countdown-teaser-button-text-hover-color: var(--primary-frk-white);
  --countdown-teaser-button-background-hover-color: var(--gradations-frk-vibrant-red-20);
  --countdown-teaser-button-border-hover-color: var(--gradations-frk-vibrant-red-20);
  --countdown-thank-you-page-color: var(--primary-frk-white);
  --countdown-thank-you-page-link-color: var(--primary-frk-white);
  --countdown-thank-you-page-download-button-background-color: var(--primary-frk-vibrant-red);
  --countdown-thank-you-page-download-button-border-color: var(--primary-frk-vibrant-red);
  --countdown-thank-you-page-download-button-text-color: var(--primary-frk-white);
  --countdown-thank-you-page-download-button-hover-background-color: var(--gradations-frk-vibrant-red-20);
  --countdown-thank-you-page-download-button-hover-border-color: var(--gradations-frk-vibrant-red-20);
  --countdown-thank-you-page-download-button-hover-text-color: var(--primary-frk-white);

  // Card teaser
  --card-teaser-background-color: transparent;
  --card-teaser-button-background-color: var(--primary-frk-vibrant-red);
  --card-teaser-button-border-color: var(--primary-frk-vibrant-red);
  --card-teaser-button-font-color: var(--primary-frk-white);
  --card-teaser-button-on-hover-focus-background-color: var(--gradations-frk-vibrant-red-20);
  --card-teaser-button-on-hover-focus-border-color: var(--gradations-frk-vibrant-red-20);
  --card-teaser-button-on-hover-focus-font-color: var(--primary-frk-white);
  --card-teaser-description-font-color: var(--primary-frk-white);
  --card-teaser-pretitle-font-color: var(--primary-frk-white);
  --card-teaser-title-font-color: var(--primary-frk-white);

  // header
  --header-background-color-on-open: var(--primary-frk-black);
  --header-background-color-on-scroll: transparent;
  --header-font-color: var(--primary-frk-white);
  --header-font-color-on-hover: var(--primary-frk-white);
  --header-font-color-opened: var(--primary-frk-white);

  // Navigation
  --navigation-background-color: var(--primary-frk-black);
  --navigation-text-color: var(--primary-frk-white);
  --language-switcher-active-color: var(--transparency-frk-white-15-trans);

  // Footer
  --footer-background-color: var(--primary-frk-black);
  --footer-text-color: var(--primary-frk-white);
  --footer-scroll-to-top-button-background-color: var(--primary-frk-black);
  --footer-scroll-to-top-button-text-color: var(--primary-frk-white);
  --footer-scroll-to-top-button-border-color: var(--primary-frk-black);
  --footer-scroll-to-top-button-hover-background-color: var(--gradations-frk-vibrant-red-20);
  --footer-scroll-to-top-button-hover-text-color: var(--primary-frk-white);
  --footer-scroll-to-top-button-hover-border-color: var(--gradations-frk-vibrant-red-20);
  --footer-social-icons-title: var(--gradations-frk-blueberry-50);
  --footer-social-icons-color: var(--primary-frk-white);
  --footer-navigation-title-text-color: var(--gradations-frk-blueberry-50);
  --footer-navigation-link-color: var(--primary-frk-white);
  --footer-navigation-accordion-button-color: var(--primary-frk-white);
  --footer-navigation-accordion-title-color: var(--primary-frk-white);
  --footer-navigation-accordion-icon-color: var(--primary-frk-white);
  --footer-navigation-accordion-item-border-color: var(--transparency-frk-white-15-trans);
  --footer-navigation-accordion-list-item-link-color: var(--primary-frk-white);
  --footer-newsletter-title-text-color: var(--gradations-frk-blueberry-50);
  --footer-newsletter-list-title-text-color: var(--primary-frk-white);
  --footer-newsletter-list-item-link-hover-text-color: var(--primary-frk-white);
  --footer-copyright-text-color: var(--primary-frk-white);
  --footer-legal-links-item-text-color: var(--primary-frk-white);
  --footer-legal-links-item-link-hover-text-color: var(--primary-frk-white);

  // Forms
  --form-error-background-color: var(--gradations-frk-vibrant-red-10);
  --form-item-error-text-color: var(--system-frk-error);
  --form-text-label-color: var(--primary-frk-white);
  --form-text-color: var(--primary-frk-white);
  --form-text-background-color: var(--transparency-frk-white-15-trans);
  --form-text-placeholder-text-color: var(--grayscale-frk-cool-grey-8);
  --form-text-textarea-color: var(--primary-frk-white);
  --form-textarea-placeholder-text-color: var(--grayscale-frk-cool-grey-8);
  --form-text-textarea-border-color: var(--primary-frk-white);
  --form-text-textarea-background-color: var(--transparency-frk-white-15-trans);
  --form-text-textarea-focus-border-color: var(--primary-frk-white);
  --form-text-is-invalid-text-color: var(--system-frk-error);
  --form-text-is-invalid-border-color: var(--system-frk-error);
  --form-button-background-color: var(--primary-frk-vibrant-red);
  --form-button-text-color: var(--primary-frk-white);
  --form-button-border-color: var(--primary-frk-vibrant-red);
  --form-button-background-hover-color: var(--gradations-frk-vibrant-red-20);
  --form-button-text-hover-color: var(--primary-frk-white);
  --form-button-disabled-background-color: var(--grayscale-frk-cool-grey-5);
  --form-button-disabled-text-color: var(--primary-frk-white);
  --form-button-disabled-border-color: var(--grayscale-frk-cool-grey-5);
  --form-button-border-hover-color: var(--gradations-frk-vibrant-red-20);
  --form-options-drop-down-text-color: var(--grayscale-frk-cool-grey-8);
  --form-options-is-invalid-text-color: var(--system-frk-error);
  --form-options-is-invalid-border-color: var(--system-frk-error);
  --form-checkbox-border-color: var(--primary-frk-white);
  --form-checkbox-checked-background-color: var(--primary-frk-vibrant-red);
  --form-checkbox-checked-text-color: var(--primary-frk-white);
  --form-radio-border-color: var(--primary-frk-white);
  --form-radio-checked-background-color: var(--primary-frk-vibrant-red);
  --form-radio-checked-text-color: var(--primary-frk-white);
  --form-dropdown-border-color: var(--primary-frk-white);
  --form-dropdown-text-color: var(--primary-frk-white);
  --form-dropdown-option-text-color: var(--primary-frk-black);
  --form-dropdown-background-color: var(--transparency-frk-white-15-trans);
  --form-dropdown-invalid-text-color: var(--grayscale-frk-cool-grey-8);
  --form-description-link-color: var(--primary-frk-vibrant-red);
  --form-description-hover-link-color: var(--gradations-frk-vibrant-red-20);
  --form-description-invalid-text-color: var(--system-frk-error);

  // HTML & Body
  --html-body-background-color: var(--primary-frk-black);
}
