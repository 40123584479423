.cmp-selection {
  grid-row: 9/12;

  @media #{$mq-12} {
    grid-column: #{column-index(1)} / #{column-index(12, 1)};
  }
  @media #{$mq-345} {
    grid-column: #{column-index(2)} / #{column-index(11, 1)};
  }

  &__divider {
    grid-row: 9/10;

    @media #{$mq-12} {
      margin: spacing(6) 0;
    }
    @media #{$mq-345} {
      margin: spacing(12) 0 spacing(6);
    }
  }

  &__content {
    grid-row: 10/12;

    .cmp-teaser__content {
      padding: 0;
    }
  }
}
