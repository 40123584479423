.cmp-navigation-product-wrapper {
  // without this the navigation product teaser grows outside of the viewport
  overflow: hidden;

  @media #{$mq-4} {
    width: grid-spacing(4, 6, 5);
  }
  @media #{$mq-5} {
    width: grid-spacing(5, 6, 5);
  }

  &__back-button {
    display: flex;
    align-items: center;
    padding: spacing(6) spacing(8);
    cursor: pointer;
    @media #{$mq-45} {
      display: none;
    }

    &-text {
      @include pre-title-s;
      margin-left: spacing(3);
      text-transform: uppercase;
    }
  }

  &__icon {
    font-size: spacing(5);
  }
}
