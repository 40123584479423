.cmp-navigation-product-gradient {
  position: relative;

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background: linear-gradient(180deg, var(--transparency-frk-white-100-trans) 0%, var(--primary-frk-white) 100%);
    transform: matrix(1, 0, 0, -1, 0, 0);

    &--is-reverse {
      top: spacing(8, true);
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }
    @media #{$mq-123} {
      height: spacing(8);
    }
    @media #{$mq-45} {
      height: spacing(10);
    }
  }
}
