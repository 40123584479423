.cmp-date-time-display {
  @include fade-in(800ms);
  width: 25%;
  color: var(--countdown-date-time-color);
  text-align: center;

  &__item {
    &-value {
      @include countdown-page-date-time-item-value;
    }

    &-type {
      @include paragraph-lead-xl;
      @media #{$mq-3} {
        @include paragraph-lead-m;
      }
      @media #{$mq-2} {
        @include paragraph-s;
      }
      @media #{$mq-1} {
        @include paragraph-countdown-page-type;
      }
    }
  }
}
