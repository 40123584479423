.cmp-file-uploader-drag-and-drop {
  position: relative;
  width: 100%;
  text-align: center;

  &__input {
    display: none;
  }

  &__button {
    padding: spacing(3) spacing(4);
    margin-top: spacing(4);
    cursor: pointer;
    @include heading-s-extra-bold;
    @include button(
      transparent,
      var(--primary-frk-black),
      var(--primary-frk-black),
      var(--primary-frk-black),
      var(--primary-frk-white),
      var(--primary-frk-black)
    );
  }

  &__label {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: spacing(6);
    border: 1px dashed var(--gradations-frk-lavender-50);
    background-color: var(--primary-frk-white);

    &--is-drag-active {
      background-color: var(--gradations-frk-champagne-50);
    }
  }

  &__title {
    margin-top: spacing(3);
    margin-bottom: spacing(2);
    @include heading-base-extra-bold;
  }

  &__icon {
    font-size: spacing(8);
  }

  &__file-info {
    display: flex;
  }

  &__file-icon {
    display: flex;
    align-items: center;
    margin-right: spacing(2);
    font-size: spacing(6);
  }

  &__file-size {
    color: var(--grayscale-frk-cool-gray-11);
    text-transform: uppercase;
    @include caption;
  }

  &__file-name {
    @include heading-s-extra-bold;
  }

  &__file-actions {
    display: flex;
    align-items: center;

    &--remove {
      display: flex;
      align-items: center;
      justify-content: center;
      border: transparent;
      background-color: transparent;
      cursor: pointer;
    }
  }

  &__list {
    padding: 0;
    margin: spacing(9) 0;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: spacing(4) spacing(6);
      border: 1px solid var(--primary-frk-black);
      margin-bottom: spacing(2);

      &--invalid {
        border: 1px solid var(--system-frk-error);
        color: var(--system-frk-error);

        .cmp-file-upload__file-size,
        .cmp-file-upload__file-actions--remove {
          color: var(--system-frk-error);
        }
      }
    }
  }
}

#drag-file-element {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cmp-file-upload-instruction {
  @include paragraph-lead-m;
  margin-bottom: spacing(14);

  p {
    margin-bottom: spacing(2);
  }

  ul {
    padding-left: spacing(2);
    margin: 0;

    li {
      position: relative;
      padding-left: spacing(4);

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        width: spacing(2);
        height: spacing(2);
        background: var(--primary-frk-vibrant-red);
        content: '';
        transform: translateY(-50%);
      }

      &:not(:last-of-type) {
        margin-bottom: spacing(2);
      }
    }
  }
}
