:root {
  --page-container-side-margin-s: 32px;
  --page-container-side-margin-m: 44px;
  --page-container-side-margin-l: 44px;
  --page-container-side-margin-xl: 48px;
  --page-container-side-margin-x-large: 48px;
  --page-max-width: 1920px;
}

// logo is composed by 3 square elements
$logo-height-desktop: 50px;
$logo-width-desktop: calc($logo-height-desktop * 3);
$logo-height-mobile: 42px;
$logo-width-mobile: calc($logo-height-mobile * 3);

// 53px space bottom and top
$header-height-desktop: calc($logo-height-desktop + (2 * 53px));
$header-open-height-desktop: calc(100vh - $header-height-desktop);

// 47px space bottom and top
$header-height-mobile: calc($logo-height-mobile + (2 * 47px));
$header-open-height-mobile: calc(100vh - $header-height-mobile);
$icon-width-large: 36px;
$icon-height-large: 36px;
$icon-width-medium: 24px;
$icon-height-medium: 24px;
$icon-width-small: 32px;
$icon-height-small: 32px;
$form-border-width: 1px;

// product hero
$product-hero-overlap-height: 64px;

// product wishlist error banner
$error-banner-height: 72px;
